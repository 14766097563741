import EditProductForm from "../views/Products/EditProductForm";
import EditSiteForm from "../views/Site/EditSiteForm";
import EditPrinterSettingsForm from "../views/PrinterSettings/EditPrinterSettingsForm";
import EditSenderForm from "../views/Senders/EditSenderForm";
import EditSynchronizationJobForm from "../views/SynchronizationJobs/EditSynchronizationJobForm";
import EditShippingLabelForm from "../views/ShippingLabels/EditShippingLabelForm";
import EditShippingServiceMappingForm from "../views/ShippingServiceMappings/EditShippingServiceMappingForm";
import NotificationPage from "../views/Notifications/NotificationPage/NotificationPage";
import OrderPage from "../views/Orders/OrderPage/OrderPage";
import PickList from "../views/PickList/PickList";
import DefaultPage from "../views/DefaultPage/DefaultPage";
import EditShipmentForm from "../views/Shipments/EditShipmentForm";
import Notifications from "../views/Notifications/Notifications";
import Products from "../views/Products/Products";
import ShippingServices from "../views/ShippingServices/ShippingServices";
import SynchronizationJobs from "../views/SynchronizationJobs/SynchronizationJobs";
import SyncView from "../views/SyncView/SyncView";
import Orders from "../views/Orders/Orders";
import UserCarriers from "../views/UserCarriers/UserCarriers";
import Senders from "../views/Senders/Senders";
import ShippingLabels from "../views/ShippingLabels/ShippingLabels";
import Shipments from "../views/Shipments/Shipments";
import ShippingServiceMappings from "../views/ShippingServiceMappings/ShippingServiceMappings";
import EditDefaultCustomsSettingsForm from "../views/DefaultCustomsSettings/EditDefaultCustomsSettingsForm"
import EditPostnordAccountForm from "../views/PostnordAccount/EditPostnordAccountForm"
import EditDHLAccountForm from "../views/DHLAccount/EditDHLAccountForm"
import EditDBSchenkerAccountForm from "../views/DBSchenkerAccount/EditDBSchenkerAccountForm"
import EditWooCommerceSettingsForm from "../views/WooCommerce/EditWooCommerceSettingsForm"
import EditBudbeeAccountForm from "../views/BudbeeAccount/EditBudbeeAccountForm"
import EditBestAccountForm from "../views/BestAccount/EditBestAccountForm"
import EditOngoingAccountForm from "../views/OngoingAccount/EditOngoingAccountForm"

export const paths = [
    {
        type: 'senders',
        title: 'Avsändare',
        page: Senders,
        editForm: EditSenderForm
    },
    {
        type: 'sync',
        title: 'Sync',
        page: SyncView
    },
    {
        type: 'notifications',
        title: 'Notiser',
        page: Notifications,
        editForm: NotificationPage
    },
    {
        type: 'products',
        title: 'Produkter',
        page: Products,
        editForm: EditProductForm
    },
    {
        type: 'orders',
        title: 'Ordrar',
        page: Orders,
        editForm: OrderPage
    },
    {
        type: 'user_carriers',
        title: 'Transportörer',
        page: UserCarriers,
        editForm: null
    },
    {
        type: 'ongoing_account',
        title: 'Ongoing Account',
        page: EditOngoingAccountForm,
        editForm: null,
    },
    {
        type: 'shipping_services',
        title: 'Frakttjänster',
        page: ShippingServices,
        editForm: null
    },
    {
        type: 'shipments',
        title: 'Försändelser',
        page: Shipments,
        editForm: EditShipmentForm
    },
    {
        type: 'shipping_service_mappings',
        title: 'Shipping Service Mappings',
        page: ShippingServiceMappings,
        editForm: EditShippingServiceMappingForm
    },
    {
        type: 'shipping_labels',
        title: 'Shipping Labels',
        page: ShippingLabels,
        editForm: EditShippingLabelForm
    },
    {
        type: 'woocommerce_settings',
        title: 'WooCommerce',
        page: EditWooCommerceSettingsForm,
        editForm: null
    },
    {
        type: 'pick_lists',
        title: 'Plocklista',
        page: PickList,
        editForm: null,
    },
    {
        type: 'sites',
        title: 'Siter',
        page: EditSiteForm,
        editForm: null,
    },
    {
        type: 'postnord_account',
        title: 'Postnord Account',
        page: EditPostnordAccountForm,
        editForm: null,
    },
    {
        type: 'dhl_account',
        title: 'DHL Konto',
        page: EditDHLAccountForm,
        editForm: null,
    },
    {
        type: 'dbschenker_account',
        title: 'DB Schenker Konto',
        page: EditDBSchenkerAccountForm,
        editForm: null,
    },
    {
        type: 'budbee_account',
        title: 'Budbee Konto',
        page: EditBudbeeAccountForm,
        editForm: null,
    },
    {
        type: 'best_account',
        title: 'Best Konto',
        page: EditBestAccountForm,
        editForm: null,
    },
    {
        type: 'printer_settings',
        title: 'Printer Settings',
        page: EditPrinterSettingsForm,
        editForm: null,
    },
    {
        type: 'default_customs_settings',
        title: 'Default Customs Settings',
        page: EditDefaultCustomsSettingsForm,
        editForm: null,
    },
    {
        type: 'user',
        title: 'Användare',
        page: DefaultPage,
        editForm: null
    },
    {
        type: 'shipments',
        title: 'Användare',
        page: ShippingLabels,
    },
    {
        type: 'synchronization_jobs',
        title: 'Synkronisationsjobb',
        page: SynchronizationJobs,
        editForm: EditSynchronizationJobForm
    }
]
