import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, Collapse, Row, Select, Spin, Checkbox, Col, message, Input, InputNumber} from 'antd';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';

import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import "./EditShippingServiceMappingForm.scss";
import SenderSelect from "../../components/SenderSelect";
import ShippingServiceSelect from "../../components/ShippingServiceSelect";
import CollapsePanel from "../../components/Misc/CollapsePanel";
import ShippingMethodIdentifierSelect from "../../components/ShippingMethodIdentifierSelect";
import {
    SET_SHIPPING_SERVICE_MAPPING, UPDATE_SHIPPING_SERVICE_MAPPING
} from "../../state/reducers/shippingServiceMappingsReducer";
import axios from "axios";
import {getApiUrl, typeOptions, paymentTermsOptions, customsInvoiceTypeOptions} from "../../helpers/helpers";
import ParcelTypeSelect from "../../components/ParcelTypeSelect";
import CountrySelect from "../../components/CountrySelect/CountrySelect";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditShippingServiceMappingForm = ({id}) => {
    const {updateItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const [isLoadingAdditionalServices, setIsLoadingAdditionalServices] = useState(false);
    const [additionalServicesLoaded, setAdditionalServicesLoaded] = useState(false);
    const {shippingServiceMapping, loading} = useSelector(state => state?.shippingServiceMappings);
    const formRef = createRef();
    const navigate = useNavigate();
    const { Panel } = Collapse;
    const [simpleAdditionalServices, setSimpleAdditionalServices] = useState([]);
    const [advancedAdditionalServices, setAdvancedAdditionalServices] = useState([]);
    const [selectedPaymentTerms, setSelectedPaymentTerms] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedCustomsInvoiceType, setSelectedCustomsInvoiceType] = useState(null);
    const [selectedPackageType, setSelectedPackageType] = useState(null);
    const [shippingService, setShippingService] = useState(null);

    useEffect(() => {
        return () => {
            setItemNull(SET_SHIPPING_SERVICE_MAPPING);
        };
    }, []);

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const handleSelectPaymentTerms = (selectedOption) => {
        setSelectedPaymentTerms(selectedOption);
    };
    const handleSelectChangeCustomsInvoiceType = (selectedOption) => {
        setSelectedCustomsInvoiceType(selectedOption);
    };
    const handleSelectChangeType = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const finishHandler = (values) => {
        updateItem({
            values: values,
            id,
            ...getActionTypes('shipping_service_mappings'),
            UPDATE_SHIPPING_SERVICE_MAPPING
        });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/shipping_service_mappings');
    };

    const handleShippingServiceChange = (value) => {
        setIsLoadingAdditionalServices(true);
        setShippingService(value);
        axios.get(`${getApiUrl('additional_services')}additional_services/${value}/`, {
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            }
        ).then(res => {
            setSimpleAdditionalServices(res.data.simple);
            setAdvancedAdditionalServices(res.data.advanced);
            setAdditionalServicesLoaded(true);
        }).catch(err => {
            message.error(err?.data?.message)
        })

        setIsLoadingAdditionalServices(true);
        axios.get(`${getApiUrl('additional_services')}additional_services/${value}/`, {
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            }
        ).then(res => {
            setSimpleAdditionalServices(res.data.simple);
            setAdvancedAdditionalServices(res.data.advanced);
            setAdditionalServicesLoaded(true);
        }).catch(err => {
            message.error(err?.data?.message)
        })
    }

    const renderSimpleAdditionalServices = () => {
        return (
            simpleAdditionalServices &&
            simpleAdditionalServices.map((service) => {
                if ("additional_service_key" in service) {
                    const checked = Boolean(shippingServiceMapping.additional_services && shippingServiceMapping.additional_services[service.additional_service_key]);
                    return (
                        <Form.Item
                            key={service.additional_service_key}
                            name={'additional_service_' + service.additional_service_key}
                            valuePropName="checked"
                            noStyle
                            initialValue={checked}
                        >
                            <Checkbox>{service.additional_service_name}</Checkbox>
                        </Form.Item>
                    );
                } else {
                    const checked = Boolean(shippingServiceMapping.additional_services && shippingServiceMapping.additional_services[service.additional_service_code]);
                    return (
                        <Form.Item
                            key={service.additional_service_code}
                            name={'additional_service_' + service.additional_service_code}
                            noStyle
                            initialValue={checked}
                            valuePropName="checked">
                            <Checkbox>{service.name}</Checkbox>
                        </Form.Item>
                    );
                }
            })
        );
    };

    const renderAdvancedAdditionalServices = () => {
        return (
            advancedAdditionalServices &&
            advancedAdditionalServices.map((service) => {
                if ("additional_service_key" in service) {
                    const checked = Boolean(shippingServiceMapping.additional_services && shippingServiceMapping.additional_services[service.additional_service_key]);
                    return (
                        <Form.Item
                            key={service.additional_service_key}
                            name={'additional_service_' + service.additional_service_key}
                            valuePropName="checked"
                            noStyle
                            initialValue={checked}
                        >
                            <Checkbox>{service.additional_service_name}</Checkbox>
                        </Form.Item>
                    );
                } else {
                    const checked = Boolean(shippingServiceMapping.additional_services && shippingServiceMapping.additional_services[service.additional_service_code]);
                    return (
                        <Form.Item
                            key={service.additional_service_code}
                            name={'additional_service_' + service.additional_service_code}
                            noStyle
                            initialValue={checked}
                            valuePropName="checked">
                            <Checkbox>{service.name}</Checkbox>
                        </Form.Item>
                    );
                }
            })
        );
    };

    if (Object.keys(shippingServiceMapping).length === 0) {
        return <Row data-testid='spinner' justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    if ( ! additionalServicesLoaded && ! isLoadingAdditionalServices ) {
        handleShippingServiceChange(shippingServiceMapping.shipping_service_id);
    }

    if ( ! additionalServicesLoaded && ! isLoadingAdditionalServices ) {
        handleShippingServiceChange(shippingServiceMapping.shipping_service_id);
    }

    const numberRules = [
        {
            required: false,
            type: 'number',
        }
    ]

    return (
        <>
            <Col style={{marginRight: '20px'}}>
                <ul>
                    <li>
                        <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}>Skapad:</span>
                        <span style={{ color: '#8e8e8e'}}>{shippingServiceMapping.datetime_created}</span>
                    </li>
                    <li>
                        <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}>Uppdaterad:</span>
                        <span style={{ color: '#8e8e8e'}}>{shippingServiceMapping.datetime_last_updated}</span>
                    </li>
                </ul>
            </Col>
            <Form {...layout}
                  ref={formRef}
                  data-testid='edit-shipping-service-mapping-form'
                  name="edit-shipping-service-mapping"
                  onFinish={finishHandler}
                  initialValues={{shippingServiceMapping}}
                  style={{width: '100%'}}
                  onValuesChange={valuesChangeHandler}
                  validateMessages={validateMessages}
                >
                <Collapse defaultActiveKey="1" borderRadiusLG="5" colorBorder="#f7f7f7">
                    <Panel header={<CollapsePanel icon={<PlusCircleOutlined />} text="Allmänt" />} key="1">
                        <Form.Item label="Avsändare" rules={[{required: true}]}>
                            <SenderSelect name={'sender_id'} initialValue={shippingServiceMapping.sender_id}/>
                        </Form.Item>
                        <Form.Item label="Fraktmetod" rules={[{required: true}]}>
                            <ShippingServiceSelect
                                name={'shipping_service_id'}
                                initialValue={shippingServiceMapping.shipping_service_id}
                                shippingService={handleShippingServiceChange}
                                onChange={handleShippingServiceChange}
                            />
                        </Form.Item>
                        <Form.Item label="Orderidentifierare" rules={[{required: true}]}>
                            <ShippingMethodIdentifierSelect name={'shipping_method_identifier'} initialValue={shippingServiceMapping.shipping_method_identifier}/>
                        </Form.Item>
                        <Form.Item
                            label="Orderidentifierare ord"
                            rules={[{required: true}]}
                            initialValue={shippingServiceMapping.shipping_method_string_identifier}
                        >
                            <Input
                                name="shipping_method_string_identifier"
                            />
                        </Form.Item>
                        <Form.Item label="Pakettyp" name="parcel_type" initialValue={shippingServiceMapping.parcel_type} rules={[{required: true}]}>
                            <ParcelTypeSelect
                                value={selectedPackageType}
                                initialValue={shippingServiceMapping.parcel_type}
                                shippingService={shippingService}
                            />
                        </Form.Item>
                        <Form.Item name="should_print_return_labels" valuePropName="checked" noStyle>
                           <Checkbox>Skriv ut retursedlar</Checkbox>
                        </Form.Item>
                        <Form.Item label="Fraktmetod retur" rules={[{required: false}]}>
                            <ShippingServiceSelect
                                name={'return_shipping_service_id'}
                                initialValue={shippingServiceMapping.return_shipping_service_id}
                            />
                        </Form.Item>
                        <Form.Item name="countries" valuePropName="checked" noStyle>
                           <CountrySelect initialValues={shippingServiceMapping.countries}/>
                        </Form.Item>
                        <Form.Item name="use_min_dimensions" valuePropName="checked" noStyle>
                           <Checkbox>use_min_dimensions</Checkbox>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order_total_value_min} name='order_total_value_min' label="Ordertotal minimum värde" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order_total_value_max} name='order_total_value_max' label="Ordertotal maximum värde" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order_total_weight_min} name='order_total_weight_min' label="Ordervikt minimum värde" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order_total_weight_max} name='order_total_weight_max' label="Ordervikt maximum värde" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order_total_height_min} name='order_total_height_min' label="Order höjd minimum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order_total_height_max} name='order_total_height_max' label="Order höjd maximum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order_total_width_min} name='order_total_width_min' label="Order bredd minimum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order_total_width_max} name='order_total_width_max' label="Order bredd maximum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order_total_length_min} name='order_total_length_min' label="Order längd minimum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order_total_length_max} name='order_total_length_max' label="Order längd maximum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.max_range} name='max_range' label="Order omkrets max" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.max_length_width_height_sum} name='max_length_width_height_sum' label="Max Längd + bredd + höjd" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item initialValue={shippingServiceMapping.order} name='order' label="Viktning" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                    </Panel>
                    <Panel header={<CollapsePanel icon={<PlusCircleOutlined />} text="Tilläggstjänster" />} key="2">
                        { simpleAdditionalServices ?
                            <h3>Vanliga tilläggstjänster</h3> : null }
                        {renderSimpleAdditionalServices()}
                        { advancedAdditionalServices ?
                            <h3>Övriga tilläggstjänster</h3> : null }
                        {renderAdvancedAdditionalServices()}
                    </Panel>
                    <Panel header={<CollapsePanel icon={<PlusCircleOutlined />} text="Tull" />} key="3">
                        <Form.Item label="Betalvillkor" name="payment_terms" initialValue={shippingServiceMapping.payment_terms}>
                            <Select
                                value={selectedPaymentTerms}
                                defaultValue={shippingServiceMapping.payment_terms}
                                onChange={handleSelectPaymentTerms}
                                options={paymentTermsOptions}
                            />
                        </Form.Item>
                        <Form.Item label="Fakturatyp" name="customs_invoice_type" initialValue={shippingServiceMapping.customs_invoice_type}>
                            <Select
                                value={selectedCustomsInvoiceType}
                                onChange={handleSelectChangeCustomsInvoiceType}
                                options={customsInvoiceTypeOptions}
                            />
                        </Form.Item>
                        <Form.Item label="Typ" name="type">
                            <Select
                                value={selectedType}
                                onChange={handleSelectChangeType}
                                options={typeOptions}
                            />
                        </Form.Item>
                    </Panel>
                    <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                    <Form.Item>
                        <Row>
                            {
                                isChanged ?
                                    <Button style={{marginRight: '20px'}} type="primary" loading={loading}
                                            htmlType="submit">
                                        Spara
                                    </Button> :
                                    <Button type="primary" ghost onClick={cancelHandler}>
                                        Gå tillbaka
                                    </Button>
                            }
                            {
                                isChanged ?
                                    <Button type="default" danger onClick={cancelHandler}>
                                        Avbryt
                                    </Button> :
                                    null
                            }
                        </Row>
                    </Form.Item>
                </Collapse>
            </Form>
        </>
    );
};

export default EditShippingServiceMappingForm;
