import React from 'react';

const DefaultPage = ({title}) => {
    return (
        <>
            <h2>{title}</h2>
        </>

    );
};

export default DefaultPage;
