import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, InputNumber, Row, Collapse, Panel, Select, Input, Checkbox, message} from 'antd';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import SenderSelect from "../../components/SenderSelect";
import ShippingServiceSelect from "../../components/ShippingServiceSelect";
import ShippingMethodIdentifierSelect from "../../components/ShippingMethodIdentifierSelect";
import CountrySelect from "../../components/CountrySelect/CountrySelect";
import ParcelTypeSelect from "../../components/ParcelTypeSelect";

import {
    SET_SHIPPING_SERVICE_MAPPING, UPDATE_SHIPPING_SERVICE_MAPPING
} from "../../state/reducers/shippingServiceMappingsReducer";
import {PlusCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import axios from "axios";
import {
    customsInvoiceTypeOptions,
    getApiUrl,
    paymentTermsOptions,
    typeOptions,
} from "../../helpers/helpers";
import CollapsePanel from "../../components/Misc/CollapsePanel";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const AddShippingServiceMappingForm = ({id}) => {
    const {addItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const [showText, setShowText] = useState(false);
    const {loading} = useSelector(state => state?.shippingServiceMappings);
    const formRef = createRef();
    const navigate = useNavigate();
    const { Panel } = Collapse;
    const [simpleAdditionalServices, setSimpleAdditionalServices] = useState([]);
    const [advancedAdditionalServices, setAdvancedAdditionalServices] = useState([]);
    const [selectedPaymentTerms, setSelectedPaymentTerms] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedCustomsInvoiceType, setSelectedCustomsInvoiceType] = useState(null);
    const [showCustomsOptions, setShowCustomsOptions] = useState(false);
    const [selectedPackageType, setSelectedPackageType] = useState(null);
    const [shippingService, setShippingService] = useState(null);

    useEffect(() => {
        return () => {
            setItemNull(SET_SHIPPING_SERVICE_MAPPING);
        };
    }, []);

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const handleClick = () => {
        setShowText(!showText);
    };

    const handleSelectPaymentTerms = (selectedOption) => {
        setSelectedPaymentTerms(selectedOption);
    };
    const handleSelectChangeCustomsInvoiceType = (selectedOption) => {
        setSelectedCustomsInvoiceType(selectedOption);
    };
    const handleSelectChangeType = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const handleSelectPackageType = (selectedOption) => {
        setSelectedPackageType(selectedOption);
    };

    const handleShippingServiceChange = (value) => {
        setShippingService(value)
        axios.get(`${getApiUrl('additional_services')}additional_services/${value}/`, {
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            }
        ).then(res => {
            setSimpleAdditionalServices(res.data.simple);
            setAdvancedAdditionalServices(res.data.advanced);
        }).catch(err => {
            message.error(err?.data?.message)
        })
    }
    const finishHandler = (values) => {

        addItem({
            values: values,
            id,
            ...getActionTypes('shipping_service_mappings'),
            UPDATE_SHIPPING_SERVICE_MAPPING
        });

        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/shipping_service_mappings');
    };

    const renderSimpleAdditionalServices = () => {
        return (
            simpleAdditionalServices &&
            simpleAdditionalServices.map((service) => {
                console.log(service)
                if ("additional_service_key" in service) {
                    return (
                        <Form.Item key={service.additional_service_key} name={'additional_service_' + service.additional_service_key} valuePropName="checked" noStyle>
                            <Checkbox>{service.additional_service_name}</Checkbox>
                        </Form.Item>
                    );
                } else {
                    return (
                        <Form.Item key={service.additional_service_code} name={'additional_service_' + service.additional_service_code} valuePropName="checked" noStyle>
                            <Checkbox>{service.name}</Checkbox>
                        </Form.Item>
                    );
                }
            })
        );
    };

    const renderAdvancedAdditionalServices = () => {
        return (
            advancedAdditionalServices &&
            advancedAdditionalServices.map((service) => {
                console.log(service)
                if ("additional_service_key" in service) {
                    return (
                        <Form.Item key={service.additional_service_key} name={'additional_service_' + service.additional_service_key} valuePropName="checked" noStyle>
                            <Checkbox>{service.additional_service_name}</Checkbox>
                        </Form.Item>
                    );
                } else {
                    return (
                        <Form.Item key={service.additional_service_code} name={'additional_service_' + service.additional_service_code} valuePropName="checked" noStyle>
                            <Checkbox>{service.name}</Checkbox>
                        </Form.Item>
                    );
                }
            })
        );
    };

    const handleShowCustomsOptionsChange = (e) => {
        setShowCustomsOptions(e.target.checked);
    };

    const numberRules = [
        {
            required: false,
            type: 'number',
        }
    ]

    return (
        <>
            <Form {...layout}
                ref={formRef}
                data-testid='add-shipping-service-mapping-form'
                name="add-shipping-service-mapping"
                onFinish={finishHandler}
                initialValues={{}}
                style={{width: '100%'}}
                onValuesChange={valuesChangeHandler}
                validateMessages={validateMessages}>
                <Collapse defaultActiveKey="1" borderRadiusLG="5" colorBorder="#f7f7f7">
                    <Panel header={<CollapsePanel icon={<PlusCircleOutlined />} text="Allmänt" />} key="1">
                        <Form.Item label="Avsändare" rules={[{required: true}]}>
                            <SenderSelect name={'sender_id'}/>
                        </Form.Item>
                        <Form.Item label="Fraktmetod" rules={[{required: true}]}>
                            <ShippingServiceSelect name={'shipping_service_id'} onChange={handleShippingServiceChange}/>
                        </Form.Item>
                        <Form.Item label="Orderidentifierare" rules={[{required: true}]}>
                            <ShippingMethodIdentifierSelect name={'shipping_method_identifier'}/>
                        </Form.Item>
                        <Form.Item label="Pakettyp" name="parcel_type" rules={[{required: true}]}>
                            <ParcelTypeSelect
                                value={selectedPackageType}
                                shippingService={shippingService}
                            />
                        </Form.Item>
                        <Form.Item name="should_print_return_labels" valuePropName="checked" noStyle>
                           <Checkbox>Skriv ut retursedlar</Checkbox>
                        </Form.Item>
                        <Form.Item label="Fraktmetod retur" rules={[{required: false}]}>
                            <ShippingServiceSelect name={'return_shipping_service_id'}/>
                        </Form.Item>
                        <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                        <Form.Item name="countries" valuePropName="checked" noStyle>
                           <CountrySelect/>
                        </Form.Item>
                        <Form.Item name="use_min_dimensions" valuePropName="checked" noStyle>
                           <Checkbox>use_min_dimensions</Checkbox>
                        </Form.Item>
                        <Form.Item name='order_total_value_min' label="Ordertotal minimum värde" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='order_total_value_max' label="Ordertotal maximum värde" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='order_total_weight_min' label="Ordervikt minimum värde" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='order_total_weight_max' label="Ordervikt maximum värde" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='order_total_height_min' label="Order höjd minimum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='order_total_height_max' label="Order höjd maximum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='order_total_width_min' label="Order bredd minimum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='order_total_width_max' label="Order bredd maximum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='order_total_length_min' label="Order längd minimum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='order_total_length_max' label="Order längd maximum" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='max_range' label="Order omkrets" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='max_length_width_height_sum' label="Max Längd + bredd + höjd" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item name='order' label="Viktning" rules={numberRules}>
                            <InputNumber/>
                        </Form.Item>
                    </Panel>
                    <Panel header={<CollapsePanel icon={<PlusCircleOutlined />} text="Tilläggstjänster" />} key="2">
                        { simpleAdditionalServices ?
                            <h3>Vanliga tilläggstjänster</h3> : null }
                        {renderSimpleAdditionalServices()}
                        { advancedAdditionalServices ?
                            <h3>Övriga tilläggstjänster</h3> : null }
                        {renderAdvancedAdditionalServices()}
                    </Panel>
                    <Panel header={<CollapsePanel icon={<PlusCircleOutlined />} text="Tull" />} key="3">
                        <Form.Item label="Betalvillkor" name="payment_terms">
                            <Select
                                value={selectedPaymentTerms}
                                onChange={handleSelectPaymentTerms}
                                options={paymentTermsOptions}
                            />
                        </Form.Item>
                        <Form.Item label="Fakturatyp" name="customs_invoice_type">
                            <Select
                                value={selectedCustomsInvoiceType}
                                onChange={handleSelectChangeCustomsInvoiceType}
                                options={customsInvoiceTypeOptions}
                            />
                        </Form.Item>
                        <Form.Item label="Typ" name="type">
                            <Select
                                value={selectedType}
                                onChange={handleSelectChangeType}
                                options={typeOptions}
                            />
                        </Form.Item>
                    </Panel>
                    <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                    <Form.Item>
                    <Row>
                        {
                            isChanged ?
                                <Button style={{marginRight: '20px'}} type="primary" loading={loading}
                                        htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                    </Form.Item>
                </Collapse>
            </Form>
        </>
    );
};

export default AddShippingServiceMappingForm;
