import {
    SET_SHIPPING_SERVICES_TABLE_FILTERS, SET_SHIPPING_SERVICES_TABLE_PAGINATION,
    SET_SHIPPING_SERVICES_TABLE_SORTING
} from "../reducers/shippingServicesReducer";

export const shippingServicesActions = {
    setShippingMethodsFilters: (payload) => (
        {
            type: SET_SHIPPING_SERVICES_TABLE_FILTERS,
            payload
        }
    ),
    setShippingMethodsSorting: (payload) => (
        {
            type: SET_SHIPPING_SERVICES_TABLE_SORTING,
            payload
        }
    ),
    setShippingMethodsPagination: (payload) => (
        {
            type: SET_SHIPPING_SERVICES_TABLE_PAGINATION,
            payload
        }
    )
}
