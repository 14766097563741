import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import useActions from "../../hooks/useActions";
import {dashboardBulkFormElements} from "../ant-table/dashboardBulkFormElements";
import BulkEditBlock from "./BulkEditBlock/BulkEditBlock";

const BulkOperations = ({type, dashboardBulkFormElementsType, actionsTypes, itemType}) => {
    const { selected, bulkFormRef } = useSelector(state => state?.bulkOperations);
    const {filters} = useSelector(state => state?.picklist)
    const {updateItem, batchUpdate, setIsBulkModalVisible, setSelectedRows, setBulkOperationsAvailable, setSelectedRowKeys, setBulkFormRefFound, updateOrderStatus, updatePackingStatus} = useActions();

    const bulkFinishHandler = () => {
        if (bulkFormRef && selected.length > 0) {
            const values = bulkFormRef.getFieldsValue();

            if (type === 'products') {
                batchUpdate({
                    values: selected.map(sel => {
                        return {
                            id: sel.id,
                            ...values
                        }
                    }),
                    ...actionsTypes,
                    contentText: 'Produkter har uppdaterats'
                })
            } else {
                selected.forEach(sel => {
                    if (type === 'orders') {
                        updateOrderStatus({
                            id: sel.id,
                            values,
                            status: values.status.toLowerCase(),
                            contentText: `Order id ${sel.id} har uppdaterats`
                        })
                    } else if (type === 'picklist') {
                        updatePackingStatus({
                            id: sel.id,
                            values,
                            filters,
                            status: values.packing_status.toLowerCase(),
                            contentText: `Order id ${sel.id} har uppdaterats`
                        })
                    } else {
                        updateItem({
                            values,
                            id: sel.id,
                            ...actionsTypes,
                            shouldUpdateItems: true,
                            updateActionType: `UPDATE_${itemType}`
                        })
                    }
                })
            }

            setIsBulkModalVisible( false );
            setSelectedRows([])
            setBulkOperationsAvailable(false)
            setSelectedRowKeys([])
            setBulkFormRefFound(false)
        }
    }



    return (
        dashboardBulkFormElements[dashboardBulkFormElementsType] && Object.keys(dashboardBulkFormElements[dashboardBulkFormElementsType]).length > 0 ?
        <BulkEditBlock formElements={dashboardBulkFormElements[dashboardBulkFormElementsType]} finishHandler={bulkFinishHandler}/> : ''
    );
};

export default BulkOperations;
