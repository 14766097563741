import {
    SET_AVAILABLE, SET_FORM_REF,
    SET_MODAL_VISIBLE, SET_REF_FOUND,
    SET_SELECTED,
    SET_SELECTED_ROW_KEYS
} from "../reducers/bulkReducer";

export const bulkOperationsAction = {
    setSelectedRows: (payload) => {
        return {
            type: SET_SELECTED,
            payload
        }
    },
    setBulkOperationsAvailable: (payload) => {
        return {
            type: SET_AVAILABLE,
            payload
        }
    },
    setSelectedRowKeys: (payload) => {
        return {
            type: SET_SELECTED_ROW_KEYS,
            payload
        }
    },
    setBulkFormRef: (payload) => {
        return {
            type: SET_FORM_REF,
            payload
        }
    },
    setBulkFormRefFound: (payload) => {
        return {
            type: SET_REF_FOUND,
            payload
        }
    }

}

export const setSelectedRows = (payload) => {
    return {
        type: SET_SELECTED,
        payload
    }
}

export const setBulkOperationsAvailable = (payload) => {
    return {
        type: SET_AVAILABLE,
        payload
    }
}

export const setSelectedRowKeys = (payload) => {
    return {
        type: SET_SELECTED_ROW_KEYS,
        payload
    }
}

export const setBulkFormRef = (payload) => {
    return {
        type: SET_FORM_REF,
        payload
    }
}

export const setBulkFormRefFound = (payload) => {
    return {
        type: SET_REF_FOUND,
        payload
    }
}



