const initialState = {
    printerSettings: {},
    loading: false,
}

export const SET_PRINTER_SETTINGS = 'SET_PRINTER_SETTINGS';
export const SET_PRINTER_SETTINGS_LOADING = 'SET_PRINTER_SETTINGS_LOADING';
export const SET_PRINTER_SETTINGS_PAGINATION = 'SET_PAGINATION';
export const UPDATE_PRINTER_SETTINGS = 'UPDATE_PRINTER_SETTINGS';
export const DELETE_PRINTER_SETTINGS = 'DELETE_PRINTER_SETTINGS';

const printerSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRINTER_SETTINGS_LOADING:
            return {...state, loading: action.payload};
        case SET_PRINTER_SETTINGS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_PRINTER_SETTINGS:
            return {...state, printerSettings: action.payload, loading: false};
        case UPDATE_PRINTER_SETTINGS:
            return {...state, printerSettings: {...action.payload}, loading: false};
        case DELETE_PRINTER_SETTINGS:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default printerSettingsReducer;
