import React from 'react';
import {Input, InputNumber, Form} from "antd";

const EditableOrderCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                      }) => {
    const inputNode = inputType === 'number' ? <InputNumber
        {...(dataIndex !== 'total' && dataIndex !== 'total_tax' && {max: record.quantity})}
        min={0}/> :
        <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex + '_id_' + record.id}
                    style={{margin: 0}}
                    initialValue={0}
                    rules={[
                        {
                            required: true,
                            message: `Var god ange ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableOrderCell;
