const initialState = {
    dhlAccount: {},
    loading: false,
}

export const SET_DBSCHENKER_ACCOUNT = 'SET_DBSCHENKER_ACCOUNT';
export const SET_DBSCHENKER_ACCOUNTS = 'SET_DBSCHENKER_ACCOUNTS';
export const SET_DBSCHENKER_ACCOUNTS_LOADING = 'SET_DBSCHENKER_ACCOUNTS_LOADING';
export const SET_DBSCHENKER_ACCOUNTS_PAGINATION = 'SET_PAGINATION';
export const UPDATE_DBSCHENKER_ACCOUNT = 'UPDATE_DBSCHENKER_ACCOUNT';
export const DELETE_DBSCHENKER_ACCOUNT = 'DELETE_DBSCHENKER_ACCOUNT';

const dbschenkerAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DBSCHENKER_ACCOUNTS_LOADING:
            return {...state, loading: action.payload};
        case SET_DBSCHENKER_ACCOUNTS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_DBSCHENKER_ACCOUNTS:
            return {...state, sites: action.payload, loading: false};
        case SET_DBSCHENKER_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case UPDATE_DBSCHENKER_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case DELETE_DBSCHENKER_ACCOUNT:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default dbschenkerAccountReducer;
