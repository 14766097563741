const initialState = {
    dhlAccount: {},
    loading: false,
}

export const SET_BUDBEE_ACCOUNT = 'SET_BUDBEE_ACCOUNT';
export const SET_BUDBEE_ACCOUNTS = 'SET_BUDBEE_ACCOUNTS';
export const SET_BUDBEE_ACCOUNTS_LOADING = 'SET_BUDBEE_ACCOUNTS_LOADING';
export const SET_BUDBEE_ACCOUNTS_PAGINATION = 'SET_PAGINATION';
export const UPDATE_BUDBEE_ACCOUNT = 'UPDATE_BUDBEE_ACCOUNT';
export const DELETE_BUDBEE_ACCOUNT = 'DELETE_BUDBEE_ACCOUNT';

const budbeeAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BUDBEE_ACCOUNTS_LOADING:
            return {...state, loading: action.payload};
        case SET_BUDBEE_ACCOUNTS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_BUDBEE_ACCOUNTS:
            return {...state, sites: action.payload, loading: false};
        case SET_BUDBEE_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case UPDATE_BUDBEE_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case DELETE_BUDBEE_ACCOUNT:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default budbeeAccountReducer;
