import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, Row, Select, Spin, Checkbox} from 'antd';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {SET_PRODUCT} from "../../state/reducers/productsReducer";

import useActions from "../../hooks/useActions";
import QuillEditor from "../../components/Misc/QuillEditor/QuillEditor";
import {getActionTypes} from "../../helpers/getActionTypes";

import "./EditProductForm.scss";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditProductForm = ({id}) => {
    const {updateItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const {product, loading} = useSelector(state => state?.products);
    const formRef = createRef();
    const {Option} = Select;
    const navigate = useNavigate();
    const acfFields = [
        {name: '_drinking_suggestion', label: 'Dryckesförslag'},
        {name: '_recipe', label: 'Recept'},
        {name: '_ingredients', label: 'Innehåll'},
        {name: '_more_info', label: 'Mer Info'},
    ];


    useEffect(() => {
        return () => {
            setItemNull(SET_PRODUCT);
        };
    }, []);


    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        updateItem({
            values: values,
            id,
            ...getActionTypes('products'),
        });

        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/products');
    };

    if (Object.keys(product).length === 0) {
        return <Row data-testid='spinner' justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <>
            <Form {...layout}
                  ref={formRef}
                  data-testid='edit-product-form'
                  name="edit-product"
                  layout="vertical"
                  onFinish={finishHandler}
                  initialValues={{}}
                  style={{width: '100%'}}
                  onValuesChange={valuesChangeHandler}
                  validateMessages={validateMessages}>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item
                            name='title'
                            label="Namn"
                            initialValue={product?.title}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='sku'
                            label="SKU"
                            initialValue={product?.sku}
                            rules={[{required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={6}>
                        <QuillEditor isAcf={true} field={{label: "Beskrivning", name: 'short_description'}}
                                 initialValue={product?.short_description}/>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item
                            name='price'
                            label="Pris (kr)"
                            initialValue={product?.price}
                            rules={[
                                {
                                    required: true,
                                    type: 'number',
                                },
                                {
                                    pattern: /^\d*[1-9]\d*$/,
                                    message: 'Number can\'t be negative'
                                }
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='weight'
                            label="Vikt (kg)"
                            initialValue={product?.weight}
                            rules={[
                                {
                                    required: false,
                                    type: 'number',
                                },
                                {
                                    pattern: /^\d+(\.\d{1,2})?$/,
                                    message: 'Number can\'t be negative'
                                }
                            ]}
                        >
                            <InputNumber decimalSeparator='.'/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item
                            name='length'
                            label="Längd (cm)"
                            initialValue={product?.length}
                            rules={[
                                {
                                    required: false,
                                    type: 'number',
                                },
                                {
                                    pattern: /^\d*[1-9]\d*$/,
                                    message: 'Number can\'t be negative'
                                }
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='width'
                            label="Bredd (cm)"
                            initialValue={product?.width}
                            rules={[
                                {
                                    required: false,
                                    type: 'number',
                                },
                                {
                                    pattern: /^\d*[1-9]\d*$/,
                                    message: 'Number can\'t be negative'
                                }
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='height'
                            label="Höjd (cm)"
                            initialValue={product?.height}
                            rules={[
                                {
                                    required: false,
                                    type: 'number',
                                },
                                {
                                    pattern: /^\d*[1-9]\d*$/,
                                    message: 'Number can\'t be negative'
                                }
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item
                            name='taric_number'
                            label="Taric nummer"
                            initialValue={product?.taric_number}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item>
                            <Row>
                                {
                                    isChanged ?
                                        <Button style={{marginRight: '20px'}} type="primary" loading={loading}
                                                htmlType="submit">
                                            Spara
                                        </Button> :
                                        <Button type="primary" ghost onClick={cancelHandler}>
                                            Gå tillbaka
                                        </Button>
                                }
                                {
                                    isChanged ?
                                        <Button type="default" danger onClick={cancelHandler}>
                                            Avbryt
                                        </Button> :
                                        null
                                }
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default EditProductForm;
