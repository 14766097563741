import {
    SET_PRODUCTS_TABLE_FILTERS,
    SET_PRODUCTS_TABLE_PAGINATION,
    SET_PRODUCTS_TABLE_SORTING
} from "../reducers/productsReducer";

export const productsActions = {
    setProductsFilters: (payload) => (
        {
            type: SET_PRODUCTS_TABLE_FILTERS,
            payload
        }
    ),
    setProductsSorting: (payload) => (
        {
            type: SET_PRODUCTS_TABLE_SORTING,
            payload
        }
    ),
    setProductsPagination: (payload) => (
        {
            type: SET_PRODUCTS_TABLE_PAGINATION,
            payload
        }
    )
}
