import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, Row, Select, Spin, Checkbox, Col} from 'antd';

import {useSelector} from 'react-redux';

import useActions from "../../hooks/useActions";
import "./EditShippingLabelForm.scss";
import PdfViewer from "./pdfViewer";
import {getOrderLink, getSenderLink} from "../../helpers/helpers";


const EditShippingLabelForm = ({id}) => {
    const {setItemNull} = useActions();
    const {shippingLabel} = useSelector(state => state?.shippingLabels);

    useEffect(() => {
        return () => {
            console.log(shippingLabel);
        };
    }, []);
    if (Object.keys(shippingLabel).length === 0) {
        return <Row data-testid='spinner' justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }
    return (
        <>
            <h2>Shipping Label</h2>
            <div style={{marginBottom: '20px'}}>
                <div style={{marginBottom: '20px'}}>
                <Row>
                <Col style={{marginRight: '20px'}}>
                    <h3>Mottagare</h3>
                    <ul>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Namn:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shippingLabel.shipment.receiver_first_name + ' ' + shippingLabel.shipment.receiver_last_name}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Adress:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shippingLabel.shipment.receiver_address_1}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Postnummer:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shippingLabel.shipment.receiver_zipcode}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Ort:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shippingLabel.shipment.receiver_city}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Land:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shippingLabel.shipment.receiver_country}</span>
                        </li>
                    </ul>
                </Col>
            </Row>
            <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
            <Row>
                 <Col style={{marginRight: '20px'}}>
                    <h3>Information</h3>
                    <ul>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Skapad datum:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shippingLabel.shipment.datetime_created}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Uppdaterad datum:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shippingLabel.shipment.datetime_last_updated}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Leveransdatum:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shippingLabel.shipment.shipment_date}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Order:</b></span>
                            <span style={{ color: '#8e8e8e'}}><a href={getOrderLink(shippingLabel.shipment.order_id)}>Order {shippingLabel.shipment.order_id}</a></span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Avsändare:</b></span>
                            <span style={{ color: '#8e8e8e'}}><a href={getSenderLink(shippingLabel.shipment.sender_id)}>Avsändare {shippingLabel.shipment.sender_id}</a></span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Frakttjänst:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shippingLabel.shipment.shipping_service_id}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Status:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shippingLabel.shipment.status}</span>
                        </li>
                    </ul>
                </Col>
            </Row>
            </div>
                <Row>
                    <Col style={{marginRight: '20px'}}>
                        <PdfViewer shippingLabel={shippingLabel} />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default EditShippingLabelForm;
