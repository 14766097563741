import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import axios from "axios";
import {getApiUrl, getParams} from "../../helpers/helpers";
import {message} from "antd";
import {Button} from 'antd';

function PdfViewer(props) {
  const iframeRef = useRef(null);

  function handlePrintClick() {
      console.log(iframeRef.current);
    if (iframeRef.current) {
        try {
            iframeRef.current.contentWindow.print();
        } catch (e) {
            console.log(e);
        }
    }
  }
  const [pdfUrl, setPdfUrl] = useState(null);

  const renderPdfFromLabel = (shippingLabelId) => {
    axios.get(
  `${getApiUrl('shipping_labels')}shipping_labels/pdf/${shippingLabelId}/`,
  {
      responseType: "arraybuffer",
      headers: {'AccessToken': localStorage.getItem('accessToken')}}
    ).then(res => {
        const blob = new Blob([res.data], {type: 'application/pdf'});
        console.log(blob)
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
    })
    .catch(err => {
        message.error(err?.data?.message)
    })
  }

  const renderPdfFromShipment = (shipmentId) => {
    axios.get(
  `${getApiUrl('shipments')}shipments/pdf/${shipmentId}/`,
  {
      responseType: "arraybuffer",
      headers: {'AccessToken': localStorage.getItem('accessToken')}}
    ).then(res => {
        const blob = new Blob([res.data], {type: 'application/pdf'});
        console.log(blob)
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
    })
    .catch(err => {
        message.error(err?.data?.message)
    })
  }


  useEffect(() => {
      if (props.shippingLabel){
          console.log(props)
          renderPdfFromLabel(props.shippingLabel.id);
      }
      else if (props.shipment){
          renderPdfFromShipment(props.shipment.id);
      }


  }, [props]);

  return (
    <div>
      {pdfUrl ? (
        <iframe ref={iframeRef} src={pdfUrl} width="100%" height="500px" />

      ) : (
        <div>Loading PDF...</div>
      )}
        <Button type="primary" onClick={handlePrintClick}>Print</Button>
    </div>
  );
}

export default PdfViewer;
