import React, {useEffect, useState} from 'react';
import {Form, message, Select} from "antd";
import {getApiUrl} from "../helpers/helpers";
import useActions from "../hooks/useActions";

const ShippingMethodIdentifierSelect = ({name, initialValue = null}) => {
    const [options, setOptions] = useState([]);
    const {customApiGet} = useActions()
    useEffect(() => {
        customApiGet({
            url: `${getApiUrl('shipping_method_id')}user_shipping_methods/`,//TODO remove and fix hardcoding
            successCallback: function (response) {
                setOptions(response.data.objects)
            },
            errorCallback: function (error) {
                message.error(error?.data?.message)
            },
        })
    }, [])

    const renderSelectOptions = () => (
        options &&
        options.length > 0 &&
        options.map(option => {
            return (
                <Select.Option key={option.shipping_method_id} value={option.shipping_method_id}>{option.name}</Select.Option>
            )
        })
    )

    return (
        <Form.Item labelCol={4} wrapperCol={20} name={name} initialValue={initialValue}>
            <Select style={{width: '100%'}} initialValue={initialValue}>
                {renderSelectOptions()}
            </Select>
        </Form.Item>
    );
};

export default ShippingMethodIdentifierSelect;
