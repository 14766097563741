
import {
    SET_NOTIFICATIONS_TABLE_FILTERS,
    SET_NOTIFICATIONS_TABLE_SORTING
} from "../reducers/notificationsReducer";


export const notificationActions = {

    setNotificationsFiltering: (payload) => (
        {
            type: SET_NOTIFICATIONS_TABLE_FILTERS,
            payload
        }
    ),
    setNotificationsSorting: (payload) => (
        {
            type: SET_NOTIFICATIONS_TABLE_SORTING,
            payload
        }
    ),
}
