import {Link} from 'react-router-dom';
import {CloseCircleOutlined, EditOutlined, PrinterOutlined} from '@ant-design/icons';
import {Popconfirm} from 'antd';
import React from 'react';
import {getTextFromType} from "./helpers";

export const getColumns = ({removing, type = 'product', cols = [], isEditable = true, isRemovable = true, isPrintable = false}) => {
    const columns = [];

    if (isEditable) {
        columns.push({
            title: 'Ändra',
            dataIndex: 'Edit',
            render: (_, record) => {
                return <Link to={`${record.id}`}>
                    <EditOutlined style={{color: 'blue'}}/>
                </Link>
            },
            width: '30px',
        })
    }

    if (isPrintable) {
        columns.push({
            title: 'Skriv ut',
            dataIndex: 'Print',
            render: (_, record) => {
                return <Link to={`${record.id}`}>
                    <PrinterOutlined style={{color: 'blue'}}/>
                </Link>
            },
            width: '30px',
        })
    }

    if (cols.length > 0) {
        columns.push(...cols)
    }

    if (isRemovable) {
        columns.push({
                title: 'Funktion',
                dataIndex: 'action',
                render: (_, record) => {
                    return (
                        <Popconfirm title={`Är du säker på att du vill ta bort denna ${getTextFromType({string: type})}?`}
                                    okText="Ja"
                                    onConfirm={() => removing(record.id)}
                                    placement="topRight"
                                    cancelText="Nej"
                        >
                            <CloseCircleOutlined style={{color: 'red'}}/>
                        </Popconfirm>
                    )
                },
                width: '30px',
            },
        )
    }

    return columns;
}
