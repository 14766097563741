import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import BulkEditForm from './BulkEditForm/BulkEditForm';
import {useSelector} from 'react-redux';
import useActions from "../../../hooks/useActions";
import {useLocation} from "react-router-dom";


const BulkEditBlock = ({formElements, finishHandler}) => {
    const {setIsBulkModalVisible, setSelectedRows, setBulkOperationsAvailable, setSelectedRowKeys} = useActions();
    const {selected, isAvailable, isModalVisible, loading} = useSelector(state => state?.bulkOperations);
    const [changed, setIsChanged] = useState(false);
    const [disabled, setIsDisabled] = useState(true);
    const location = useLocation();

    const showModalClickHandler = () => {
        setIsBulkModalVisible(true);
    };

    const handleCancel = () => {
        setIsBulkModalVisible(false);
    };

    const formChangeHandler = () => {
        setIsChanged(true)
    }

    useEffect(() => {
        return () => {
            setSelectedRows([])
            setBulkOperationsAvailable(false)
            setSelectedRowKeys([])
        };
    }, [location]);

    useEffect(() => {
        if (changed) {
            setIsDisabled(false);
        }
    }, [changed]);


    return (
        isAvailable && selected.length > 0 &&
        <>
            <Button type="primary"
                    onClick={showModalClickHandler}
                    icon={<EditOutlined/>}>Bulkändra</Button>
            <Modal title="Bulkändra"
                   visible={isModalVisible}
                   width={300}
                   okText='Ändra'
                   okButtonProps={{
                       disabled,
                       loading,
                   }}
                   onOk={finishHandler}
                   onCancel={handleCancel}>
                <BulkEditForm formElements={formElements} formChangeHandler={formChangeHandler}
                              setIsChanged={setIsChanged}/>
            </Modal>
        </>
    );
};

export default BulkEditBlock;
