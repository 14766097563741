import React from 'react';
import Space from 'antd';

const CollapsePanel = ({ icon, text }) => {
  return (
    <div className="custom-panel-header">
      {icon}
      <span>{text}</span>
    </div>
  );
};
export default CollapsePanel;