import React, {useEffect, useState} from 'react';
import {Form, Select} from "antd";

const ShippingDeliveryType = ({isHome = false, formValues = null, isAddingForm = false}) => {
    const [isShippingDeliveryHome, setIsShippingDeliveryHome] = useState(false);
    const options = {
        'best_bicycle': 'BEST - Cykelbud',
        'best_car': 'BEST - Budbil',
        'cake': 'Cake',
        'gordon': 'Gordon',
        'smart': 'Smart',
    }

    useEffect(() => {
        if (isAddingForm && formValues && Object.keys(formValues).length > 0 && formValues.shipping_type) {
            setIsShippingDeliveryHome(formValues.shipping_type === 'home')
        }
    }, [formValues])

    return (
        (isAddingForm ? isShippingDeliveryHome : isHome) ?
        <Form.Item name="home_delivery_type" label='Hemleveranstyp'>
            <Select>
                {
                    Object.keys(options).map(option => (
                        <Select.Option key={option} value={option}>{options[option]}</Select.Option>
                    ))
                }
            </Select>
        </Form.Item> : <span style={{display: 'none'}}/>
    );
};

export default ShippingDeliveryType;
