const initialState = {
    notifications: {
        objects: [],
        total: 0,
        page: 1,
    },
    notification: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    notificationsTableFilters: {},
    notificationsTableSorting: {},
    notificationsTablePagination: {},
    loading: false,
}

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_NOTIFICATIONS_LOADING = 'SET_NOTIFICATIONS_LOADING';
export const SET_NOTIFICATIONS_PAGINATION = 'SET_NOTIFICATIONS_PAGINATION';
export const SET_NOTIFICATIONS_TABLE_FILTERS = 'SET_NOTIFICATIONS_TABLE_FILTERS';
export const SET_NOTIFICATIONS_TABLE_SORTING = 'SET_NOTIFICATIONS_TABLE_SORTING';
export const SET_NOTIFICATIONS_TABLE_PAGINATION = 'SET_NOTIFICATIONS_TABLE_PAGINATION';

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS_LOADING:
            return {...state, loading: action.payload};
        case SET_NOTIFICATIONS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_NOTIFICATIONS:
            return {...state, notifications: action.payload, loading: false};
        case SET_NOTIFICATION:
            return {...state, notification: {...action.payload}, loading: false};
        case SET_NOTIFICATIONS_TABLE_FILTERS:
            return {...state, notificationsTableFilters: action.payload};
        case SET_NOTIFICATIONS_TABLE_SORTING:
            return {...state, notificationsTableSorting: action.payload};
        case SET_NOTIFICATIONS_TABLE_PAGINATION:
            return {...state, notificationsTablePagination: action.payload};
        default:
            return state;
    }
}

export default notificationsReducer
