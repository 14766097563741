import React, {useState, useEffect} from 'react';
import {Button, Form, Input, Row, Col} from 'antd';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import {getApiUrl} from "../../helpers/helpers";
import "./EditWooCommerceSettingsForm.scss";
import {ADD_WOOCOMMERCE_SETTINGS} from "../../state/reducers/woocommerceSettingsReducer";

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};



const EditWooCommerceSettingsForm = ({id}) => {
    const {updateItem} = useActions()
    const {addItem, setItemNull} = useActions();
    const [woocommerceSettings, setWoocommerceSettings] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const {customApiGet} = useActions();
    const navigate = useNavigate();
    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    useEffect(() => {
        getWooCommerceSettings();
    }, []);

    const getWooCommerceSettings = () => {
        customApiGet({
            url: `${getApiUrl('woocommerce_settings')}woocommerce_settings/`,
            successCallback: function (response) {
                console.log(response.data)
                setWoocommerceSettings(response.data)
            },
            errorCallback: function (error) {
            },
        })
    }

    const updateHandler = (values) => {
        values.site_id  = parseInt(localStorage.getItem('siteId'));
        updateItem({
            values,
            ...getActionTypes('woocommerce_settings'),
            contentText: `Du har uppdaterat woocommerce inställningarna`
        });
        setIsChanged(false);
    };

    const saveHandler = (values) => {
        console.log("FINISH2");
        values.site_id  = parseInt(localStorage.getItem('siteId'));
        addItem({
            values: values,
            ...getActionTypes('woocommerce_settings'),
            ADD_WOOCOMMERCE_SETTINGS,
            contentText: `Du har uppdaterat woocommerce inställningarna`
        });
    };

    const cancelHandler = () => {
        navigate('/');
    };

    const renderEditWoocommerceSettings = () => {
        if (!woocommerceSettings) {
            return (
                <Form {...layout}
                      name="edit-woocommerce_settings"
                      onFinish={saveHandler}
                      layout={'vertical'}
                      validateMessages={validateMessages}

                >
                    <h2>WooCommerce Inställningar</h2>
                    <Row gutter={12}>
                        <Col span={6}>
                            <Form.Item
                                name='site_url'
                                label="URL"
                                rules={[{required: true,},]}
                            >
                                <Input bordered={false}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={6}>
                            <Form.Item
                                name='consumer_key'
                                label="Consumer Key"
                                rules={[{required: true,},]}
                            >
                                <Input bordered={false}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name='consumer_secret'
                                label="Consumer secret"
                                rules={[{required: true,},]}
                            >
                                <Input bordered={false}/>
                            </Form.Item>
                         </Col>
                    </Row>
                    <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                        <Row justify="space-between">
                            <Button type="primary" htmlType="submit">
                                Spara
                            </Button>
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            );
        }
    return (
        <Form {...layout}
              name="edit-woocommerce_settings"
              onFinish={updateHandler}
              layout={'vertical'}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}
              initialValues={{ // Set initial values here
                site_url: woocommerceSettings.site_url,
                consumer_key: woocommerceSettings.consumer_key,
                consumer_secret: woocommerceSettings.consumer_secret,
                }}
        >
            <h2>WooCommerce Inställningar</h2>
            <Row gutter={12}>
                <Col span={6}>
                    <Form.Item
                        name='id'
                        label="id"
                        hidden={true}
                        initialValue={woocommerceSettings.id}
                        rules={[{required: false,},]}
                    >
                    </Form.Item>
                    <Form.Item
                        name='site_url'
                        label="URL"
                        initialValue={woocommerceSettings.site_url}
                        rules={[{required: true,},]}
                    >
                        <Input bordered={false}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={6}>
                    <Form.Item
                        name='consumer_key'
                        label="Consumer Key"
                        initialValue={woocommerceSettings.consumer_key}
                        rules={[{required: true,},]}
                    >
                        <Input bordered={false}/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='consumer_secret'
                        label="Consumer secret"
                        initialValue={woocommerceSettings.consumer_secret}
                        rules={[{required: true,},]}
                    >
                        <Input bordered={false}/>
                    </Form.Item>
                </Col>
            </Row>
            <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
            <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                <Row justify="space-between">
                    {
                        isChanged ?
                            <Button type="primary" htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    );
}

    return <>{renderEditWoocommerceSettings()}</>;
};

export default EditWooCommerceSettingsForm;
