import React from 'react';

const DefaultTableForPrint = ({order}) => {
    const headRows = ['Ordernummer','Produkt','Leverans','Antal'];
    return (
        <>
            <table className='default-picklist-table' style={{marginTop: '5px'}}>
                <thead>
                <tr>
                    {headRows.map(row => <th key={row}>{row}</th>)}
                </tr>
                </thead>
                <tbody>
                {
                    order.order_items && order.order_items.length > 0 &&
                    order.order_items.map(orderItem => {
                        return (
                            <tr key={orderItem.external_order_id}>
                                <td>{orderItem.external_order_id}</td>
                                <td>{orderItem.name}</td>
                                <td>{order.shipping_method_name}</td>
                                <td>{orderItem.quantity}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            <div className='not-visible' style={{textAlign: 'right', margin: '20px 0'}}><b>Totalt:</b> {order.total} {order.currency} ({order.order_items?.reduce((prev, item) => prev + item.quantity , 0)} varor)</div>
        </>

    );
};

export default DefaultTableForPrint;
