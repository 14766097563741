

export const validateVatNumber = (vatNumber) =>{
    var regex;
    switch (vatNumber.substring(0, 2)) {
        case 'AT': // Austria
            regex = /^ATU\d{8}$/;
            break;
        case 'BE': // Belgium
            regex = /^BE0?\d{9}$/;
            break;
        case 'BG': // Bulgaria
            regex = /^BG\d{9,10}$/;
            break;
        case 'HR': // Croatia
            regex = /^HR\d{11}$/;
            break;
        case 'CY': // Cyprus
            regex = /^CY\d{8}[A-Z]$/;
            break;
        case 'CZ': // Czech Republic
            regex = /^CZ\d{8,10}$/;
            break;
        case 'DK': // Denmark
            regex = /^DK\d{8}$/;
            break;
        case 'EE': // Estonia
            regex = /^EE\d{9}$/;
            break;
        case 'FI': // Finland
            regex = /^FI\d{8}$/;
            break;
        case 'FR': // France
            regex = /^FR[A-HJ-NP-Z0-9]{2}\d{9}$/;
            break;
        case 'DE': // Germany
            regex = /^DE\d{9}$/;
            break;
        case 'GR': // Greece
            regex = /^EL\d{9}$/;
            break;
        case 'HU': // Hungary
            regex = /^HU\d{8}$/;
            break;
        case 'IE': // Ireland
            regex = /^IE\d[A-Z\*\+]{1}\d{5}[A-Z]{1,2}$/;
            break;
        case 'IT': // Italy
            regex = /^IT\d{11}$/;
            break;
        case 'LV': // Latvia
            regex = /^LV\d{11}$/;
            break;
        case 'LT': // Lithuania
            regex = /^LT(\d{9}|\d{12})$/;
            break;
        case 'LU': // Luxembourg
            regex = /^LU\d{8}$/;
            break;
        case 'MT': // Malta
            regex = /^MT\d{8}$/;
            break;
        case 'NL': // Netherlands
            regex = /^NL\d{9}B\d{2}$/;
            break;
        case 'PL': // Poland
            regex = /^PL\d{10}$/;
            break;
        case 'PT': // Portugal
            regex = /^PT\d{9}$/;
            break;
        case 'RO': // Romania
            regex = /^RO\d{2,10}$/;
            break;
        case 'SK': // Slovakia
            regex = /^SK\d{10}$/;
            break;
        case 'SI': // Slovenia
            regex = /^SI\d{8}$/;
            break;
        case 'ES': // Spain
            regex = /^ES[A-Z0-9]\d{7}[A-Z0-9]$/;
            break;
        case 'SE': // Sweden
            regex = /^SE\d{10}01$/;
            break;
        default:
            return false; // unsupported country
    }
    return regex.test(vatNumber);
}

export const validateUrl = (url) =>{
  // Regular expression for a valid URL
  const urlRegex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;

  // Test if the URL matches the regex
  return urlRegex.test(url);
}

export const  validateZipCode = (countryCode, zipCode) => {
    console.log(countryCode, zipCode);
    if(!countryCode ) return true;
    switch (countryCode) {
        case "AT":
          // Austria: 4 digits
          return /^\d{4}$/.test(zipCode);
        case "BE":
          // Belgium: 4 digits
          return /^\d{4}$/.test(zipCode);
        case "CH":
          // Switzerland: 4 digits
          return /^\d{4}$/.test(zipCode);
        case "CZ":
          // Czech Republic: 5 digits
          return /^\d{5}$/.test(zipCode);
        case "DE":
          // Germany: 5 digits
          return /^\d{5}$/.test(zipCode);
        case "DK":
          // Denmark: 4 digits
          return /^\d{4}$/.test(zipCode);
        case "ES":
          // Spain: 5 digits
          return /^\d{5}$/.test(zipCode);
        case "FI":
          // Finland: 5 digits
          return /^\d{5}$/.test(zipCode);
        case "FR":
          // France: 5 digits
          return /^\d{5}$/.test(zipCode);
        case "GB":
          // United Kingdom: 5-7 characters (depends on the region)
          return /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/.test(zipCode);
        case "GR":
          // Greece: 5 digits
          return /^\d{5}$/.test(zipCode);
        case "HU":
          // Hungary: 4 digits
          return /^\d{4}$/.test(zipCode);
        case "IE":
          // Ireland: 7 characters (A99 AAA format)
          return /^[A-Z]{1,2}\d{1,2} [A-Z]{4}$/.test(zipCode);
        case "IT":
          // Italy: 5 digits
          return /^\d{5}$/.test(zipCode);
        case "LU":
          // Luxembourg: 4 digits
          return /^\d{4}$/.test(zipCode);
        case "NL":
          // Netherlands: 4 digits (1234 AB format)
          return /^\d{4} [A-Z]{2}$/.test(zipCode);
        case "NO":
          // Norway: 4 digits
          return /^\d{4}$/.test(zipCode);
        case "PL":
          // Poland: 5 digits
          return /^\d{5}$/.test(zipCode);
        case "PT":
          // Portugal: 7 digits (NNNN-NNN format)
          return /^\d{4}-\d{3}$/.test(zipCode);
        case "SE":
          // Sweden: 5 digits (NNN NN format)
          return /^\d{3}\d{2}$/.test(zipCode);
        case "SI":
          // Slovenia: 4 digits
          return /^\d{4}$/.test(zipCode);
        case "SK":
          // Slovakia: 5 digits
          return /^\d{5}$/.test(zipCode);
        default:
          // Unsupported country
          return false;
  }
}
