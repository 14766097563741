import React, {createRef} from 'react';
import {Button, Form, Row} from "antd";
import setClasses from "../../helpers/setClasses";
import getComponentByType from "../../helpers/getComponentByType";
import useActions from "../../hooks/useActions";
import CustomSelect from "../CustomSelect/CustomSelect";

const AddItemForm = ({
                            formElements = [],
                            type = 'products',
                            formName = 'add-product',
                            formTitle,
                            formSubtitle,
                            validationMsg,
                            addItemActionData,
                            formClasses,
                            buttonText = 'Lägg till produkt',
                            loading}) => {
    const {addItem, setFeaturedId} = useActions()
    const formRef = createRef();
    const [form] = Form.useForm();

    const finishHandler = (values) => {
        let reworkedValues = {...values}
        if (reworkedValues.values && reworkedValues.values.length > 0) {
            const itemValues = reworkedValues.values;
            delete reworkedValues.values;

            reworkedValues.values = itemValues.map(val => {
                return {
                    value: val
                }
            })
        }

        addItem({values: reworkedValues, ...addItemActionData, product: {isProduct: true}});
        form.resetFields()

        if (type === 'products') {
            setFeaturedId(null)
        }
    }

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 18,
        },
    };

    return (
        <div className='container'>
            {
                formTitle &&
                <h2 style={{textAlign: 'center', maxWidth: '430px', width: '100%', fontSize: '20px'}}>{formTitle}</h2>
            }

            {
                formSubtitle &&
                <p style={{textAlign: 'center', maxWidth: '430px', width: '100%', fontSize: '14px', color: '#898989'}}>{formSubtitle}</p>
            }

            <Form {...layout}
                  ref={formRef}
                  form={form}
                  className={setClasses(formClasses)}
                  name={formName}
                  scrollToFirstError={true}
                  onFinish={finishHandler}
                  validateMessages={validationMsg}>

                {
                    formElements.length > 0
                    && formElements.map(value => {
                        return (
                            value.name === 'tags' ?
                                <div key={value.name + Math.random()}>{getComponentByType({value, formRef})}</div>
                                :
                                <React.Fragment key={value.name + Math.random()}>
                                    {
                                       value.type === 'multiple' ||
                                       value.type === 'single_select' ?
                                           getComponentByType({value, formRef, setIsChanged: () => {}, isAddingForm: true})
                                        :
                                           <Form.Item
                                               name={value.name}
                                               {...( value.type === 'checkbox' && {valuePropName: 'checked'})}
                                               label={value.label}
                                               style={{...{...value.hidden && {display: 'none'}}}}
                                               rules={[
                                                   {
                                                       required: value.required,
                                                   },
                                               ]}
                                           >
                                               { getComponentByType({value, formRef, setIsChanged: () => {}}) }
                                           </Form.Item>
                                    }
                                </React.Fragment>

                        )
                    })
                }

                <Form.Item >
                    <Row justify='space-between' align='middle'>
                        <Button loading={loading} type="primary" htmlType="submit">
                            {buttonText}
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddItemForm;
