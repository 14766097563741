import React from 'react';
import './pickListCustomerOrderDetails.scss'
import {Col, Row} from "antd";
import {useSelector} from "react-redux";
import {getDateAndTime} from "../../../../helpers/getDate";
import {firstLetterToUppercase} from "../../../../helpers/helpers";

const PickListCustomerOrderDetails = ({order}) => {
    const {picklists} = useSelector(state => state?.picklist)

    const getCurrentNote = (orders) => {
        const currentOrder = orders.find(item => item.id === order.id)

        if (currentOrder) {
            return currentOrder.customer_note ?? '-'
        }
    }

    return (
        <div className='customer-details'>
            <h2>Kundinformation</h2>
            <Row justify='space-between'>
                <Col  style={{ width: '70%'}}>
                    <span className='customer-details__item font-size-big'><b>Namn:</b> {order.customer?.billing_first_name} {order.customer?.billing_last_name}</span>
                    <span className='customer-details__item'><b>Epost:</b> {order.customer?.billing_email}</span>
                    <span className='customer-details__item'><b>Telefon:</b> {order.customer?.billing_phone}</span>
                    <span className='customer-details__item'><b>Adress:</b> {order.customer?.billing_address_1}, {order.customer?.billing_zipcode} {firstLetterToUppercase(order.customer?.billing_city)}</span>
                    <span className='customer-details__item'><b>Leveransdatum/upphämtningsdatum:</b> {order.delivery_date ? getDateAndTime(order.delivery_date).split(' ')[0] : '-'}</span>
                    <span className='customer-details__item'><b>Tid:</b> {order.delivery_time_interval ?? '-'}</span>
                </Col>
                <Col style={{width: '30%'}}>
                    <span className='customer-details__item font-weight-bold'>Kundmeddelande:</span>
                    <span className='customer-details__item'>{picklists.objects.length > 0 && getCurrentNote(picklists.objects)}</span>
                </Col>
            </Row>
        </div>
    );
};

export default PickListCustomerOrderDetails;
