const initialState = {
    dhlAccount: {},
    loading: false,
}

export const SET_BEST_ACCOUNT = 'SET_BEST_ACCOUNT';
export const SET_BEST_ACCOUNTS = 'SET_BEST_ACCOUNTS';
export const SET_BEST_ACCOUNTS_LOADING = 'SET_BEST_ACCOUNTS_LOADING';
export const SET_BEST_ACCOUNTS_PAGINATION = 'SET_PAGINATION';
export const UPDATE_BEST_ACCOUNT = 'UPDATE_BEST_ACCOUNT';
export const DELETE_BEST_ACCOUNT = 'DELETE_BEST_ACCOUNT';

const bestAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BEST_ACCOUNTS_LOADING:
            return {...state, loading: action.payload};
        case SET_BEST_ACCOUNTS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_BEST_ACCOUNTS:
            return {...state, sites: action.payload, loading: false};
        case SET_BEST_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case UPDATE_BEST_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case DELETE_BEST_ACCOUNT:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default bestAccountReducer;
