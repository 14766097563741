const initialState = {
    defaultCustomsSettings: {},
    loading: false,
}
export const ADD_DEFAULT_CUSTOMS_SETTINGS = 'ADD_DEFAULT_CUSTOMS_SETTINGS';
export const SET_DEFAULT_CUSTOMS_SETTINGS = 'SET_DEFAULT_CUSTOMS_SETTINGS';
export const SET_DEFAULT_CUSTOMS_SETTINGS_LOADING = 'SET_DEFAULT_CUSTOMS_SETTINGS_LOADING';
export const SET_DEFAULT_CUSTOMS_SETTINGS_PAGINATION = 'SET_PAGINATION';
export const UPDATE_DEFAULT_CUSTOMS_SETTINGS = 'UPDATE_DEFAULT_CUSTOMS_SETTINGS';
export const DELETE_DEFAULT_CUSTOMS_SETTINGS = 'DELETE_DEFAULT_CUSTOMS_SETTINGS';

const defaultCustomsSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEFAULT_CUSTOMS_SETTINGS_LOADING:
            return {...state, loading: action.payload};
        case SET_DEFAULT_CUSTOMS_SETTINGS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_DEFAULT_CUSTOMS_SETTINGS:
            return {...state, defaultCustomsSettings: action.payload, loading: false};
        case UPDATE_DEFAULT_CUSTOMS_SETTINGS:
            return {...state, defaultCustomsSettings: {...action.payload}, loading: false};
        case DELETE_DEFAULT_CUSTOMS_SETTINGS:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default defaultCustomsSettingsReducer;
