const initialState = {
    isFiltered: false,
    tableFilters: {},
    tableSorting: {},
}

export const SET_FILTERS = 'SET_FILTERS';
export const SET_SORTING = 'SET_SORTING';
export const SET_IS_FILTERED = 'SET_IS_FILTERED';

const tableFiltersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS:
            return {...state, tableFilters: action.payload};
        case SET_SORTING:
            return {...state, tableSorting: action.payload};
        case SET_IS_FILTERED:
            return {...state, isFiltered: action.payload};
        default:
            return state;
    }
}

export default tableFiltersReducer;
