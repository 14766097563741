import React, {useEffect, useState} from 'react';
import MenuListItem from "./MenuListItem/MenuListItem";
import {useLocation} from "react-router-dom";

const MenuList = ({menu}) => {
    const [currentMenuName, setCurrentMenuName] = useState('')
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '/user') {
            setCurrentMenuName('');
        }

        let neededPath = menu.find(menuItem => location.pathname.includes(menuItem.name))

        if (!neededPath) {
            neededPath = menu.find(menuItem => {
                const neededChildPath = menuItem.children?.find(child => location.pathname.includes(child.name))
                if (neededChildPath) {
                    return menuItem;
                }
            })
        }

        if (neededPath) {
            setCurrentMenuName(neededPath.name);
        }

    }, [location, menu])

    return (
        <ul className='menu__list'>
            {
                menu.map(menuItem => <MenuListItem key={menuItem.name} menuItem={menuItem}
                                                   currentMenuName={currentMenuName}
                                                   setCurrentMenuName={setCurrentMenuName}/>)
            }
        </ul>
    )
};

export default MenuList;
