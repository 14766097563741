const initialState = {
    senders: {
        objects: [],
        total: 0,
        page: 1,
    },
    sender: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    sendersTableFilters: {},
    sendersTableSorting: {},
    sendersTablePagination: {},
    loading: false,
}
export const ADD_SENDER = 'ADD_SENDER';
export const SET_SENDERS = 'SET_SENDERS';
export const SET_SENDER = 'SET_SENDER';
export const SET_SENDERS_LOADING = 'SET_SENDERS_LOADING';
export const SET_SENDERS_PAGINATION = 'SET_SENDERS_PAGINATION';
export const UPDATE_SENDER = 'UPDATE_SENDER';
export const SET_SENDERS_TABLE_FILTERS = 'SET_SENDERS_TABLE_FILTERS';
export const SET_SENDERS_TABLE_SORTING = 'SET_SENDERS_TABLE_SORTING';
export const SET_SENDERS_TABLE_PAGINATION = 'SET_SENDERS_TABLE_PAGINATION';

const sendersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SENDERS_LOADING:
            return {...state, loading: action.payload};
        case SET_SENDERS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_SENDERS:
            return {...state, senders: action.payload, loading: false};
        case SET_SENDER:
            return {...state, sender: {...action.payload}, loading: false};
        case UPDATE_SENDER:
            return {...state, sender: {...action.payload}, loading: false};
        case SET_SENDERS_TABLE_FILTERS:
            return {...state, sendersTableFilters: action.payload};
        case SET_SENDERS_TABLE_SORTING:
            return {...state, sendersTableSorting: action.payload};
        case SET_SENDERS_TABLE_PAGINATION:
            return {...state, sendersTablePagination: action.payload};
        default:
            return state;
    }
}

export default sendersReducer
