import {getTextFromType} from "./helpers";

export const getActionTypes = (type) => {
    const itemType = getTextFromType({
        string: type,
        isRemoveSymbols: false,
        isRemoveLastAndAllToUppercase: true});
    const uppercaseType = type.toUpperCase();

    return {
        type: type,
        setItemActionType: `SET_${itemType}`,
        itemDeleteActionType: `DELETE_${itemType}`,
        updateActionType: `UPDATE_${itemType}`,
        addItemActionType: `ADD_${itemType}`,
        setPaginationActionType: `SET_${uppercaseType}_PAGINATION`,
        setLoadingActionType: `SET_${uppercaseType}_LOADING`,
        setItemsActionType: `SET_${uppercaseType}`
    }
}
