import React from 'react';
import {useSelector} from "react-redux";
import AddItemForm from "../../../components/AddItemForm/AddItemForm";
import AddSynchronizationJobForm from "../../SynchronizationJobs/AddSynchronizationJobForm";
import AddSenderForm from "../../Senders/AddSenderForm";
import AddUserCarrierForm from "../../UserCarriers/AddUserCarrierForm";
import AddShippingServiceMappingForm from "../../ShippingServiceMappings/AddShippingServiceMappingForm";
import {addFormElements} from "./addFormElements";
import {getTextFromType} from "../../../helpers/helpers";
import {getUseSelectorFromType} from "../../../helpers/getUseSelectorFromType";
import {getActionTypes} from "../../../helpers/getActionTypes";

const ItemAdd = ({type}) => {
    const useSelectorType = getUseSelectorFromType(type);
    const {loading} = useSelector(state => state[useSelectorType]);
    const formElements = addFormElements[useSelectorType + 'AddFormElements'];
    const typeTextTranslations = {
        'products': 'produkt',
        'attributes': 'attribut',
        'shipping-base-addresses': 'avsändarensadress',
        'shipping_services': 'frakttjänster',
        'shipping_service_mappings': 'Shipping service mappings',
        'sites': 'webbplats',
        'images': 'bild',
    }

    const buttonText = `Lägg till ${typeTextTranslations[type]}`
    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const actionItemData = {
        ...getActionTypes(type),
        contentText: `${getTextFromType({string: type})} har skapats`
    }
    console.log("AddItemForm" + type);


    if( type === 'shipping_service_mappings'){
        return <AddShippingServiceMappingForm type={type} addItemActionData={actionItemData} loading={loading} formElements={formElements}
                     layout={layout} buttonText={buttonText}/>
    } else if( type === 'senders'){
        return <AddSenderForm type={type} addItemActionData={actionItemData} loading={loading} formElements={formElements}
                                          layout={layout} buttonText={buttonText}/>
    } else if( type === 'user_carriers'){
        return <AddUserCarrierForm type={type} addItemActionData={actionItemData} loading={loading} formElements={formElements}
                                          layout={layout} buttonText={buttonText}/>
    } else if( type === 'synchronization_jobs'){
        return <AddSynchronizationJobForm type={type} addItemActionData={actionItemData} loading={loading} formElements={formElements}
                                          layout={layout} buttonText={buttonText}/>
    }
    return (
        <AddItemForm type={type} addItemActionData={actionItemData} loading={loading} formElements={formElements}
                     layout={layout} buttonText={buttonText}/>
    );
};

export default ItemAdd;
