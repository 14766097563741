import React, {useEffect, useState} from 'react';
import {Form, message, Select} from "antd";
import {getApiUrl, getParams} from "../helpers/helpers";
import useActions from "../hooks/useActions";

const ParcelTypeSelect = ({name, initialValue = null, shippingService = null, onChange}) => {
    const [options, setOptions] = useState([]);
    const {customApiGet} = useActions()
    const params = {}

    useEffect(() => {
        if (shippingService !== null) {
            customApiGet({
                url: `${getApiUrl('parcel_types')}parcel_types/${shippingService}/${getParams(params)}`,
                successCallback: function (response) {
                    setOptions(response.data)
                },
                errorCallback: function (error) {
                    setOptions(error?.data?.message)
                },
            })
        }
    }, [shippingService]);

    const renderSelectOptions = () => (
        options &&
        options.length > 0 &&
        options.map(option => {
            return (
                <Select.Option key={option.id + Math.random()} value={option.value} >{option.name}</Select.Option>
            )
        })
    )

    return (
        <Form.Item labelCol={4} wrapperCol={20} name={name}>
            <Select style={{width: '100%'}} initialValue={initialValue} onChange={onChange}>
                { options && options.length > 0 ?  renderSelectOptions() : <Select.Option key={0} value={0} >Ingen</Select.Option> }
            </Select>
        </Form.Item>
    );
};

export default ParcelTypeSelect;
