import React from 'react';
import { Image, Tag } from 'antd';
import getSearchFilterByName from '../../helpers/getSearchFilterByName';
import { getDateAndTime } from '../../helpers/getDate';
import getFiltersDropdown from '../../helpers/getFiltersDropdown';
import getRangeDateDropdown from '../../helpers/getRangeDateDropdown';
import { useSelector } from 'react-redux';
import { insert} from '../../helpers/helpers';
import { getUseSelectorFromType } from '../../helpers/getUseSelectorFromType';
import {ReadFilled, DragOutlined } from '@ant-design/icons';

export const dashboardColumns = ({type, orderRender}) => {
	let useSelectorType, sortingFiltersType, tableFiltersType, sortingFilters, tableFilters;

	if (type !== 'pick_lists') {
		useSelectorType = getUseSelectorFromType(type);
		sortingFiltersType = useSelectorType + 'TableSorting';
		tableFiltersType = useSelectorType + 'TableFilters';
		sortingFilters = useSelector(state => state[useSelectorType][sortingFiltersType])
		tableFilters = useSelector(state => state[useSelectorType][tableFiltersType])
	} else {
		sortingFiltersType = 'pickListsTableSorting';
		sortingFilters = useSelector(state => state?.picklist[sortingFiltersType]);
	}

	const columns = {
		attributesColumns: [
			{
				title: 'Namn',
				dataIndex: 'name',
				...getSearchFilterByName({type: 'attributes', tableFilters, dataIndex: 'name', placeholder: 'Search for name'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'name' ? sortingFilters?.order: null
			},
			{
				title: 'Värden',
				dataIndex: 'values',
				render: (_, record) => {
					return record.values.map(val => val).join(', ')
				},
			},
		],
		notificationsColumns: [
			{
				title: 'Datum',
				dataIndex: 'datetime_created',
				...getSearchFilterByName({type: 'notifications', tableFilters, dataIndex: 'datetime_created', placeholder: 'Search for date'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'datetime_created' ? sortingFilters?.order: null
			},
			{
				title: 'Meddelande',
				dataIndex: 'message',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'message' ? sortingFilters?.order: null
			},
			{
				title: 'Läst',
				dataIndex: 'is_read',
				sorter: () => false,
				render: (_, record) => {
					if (record.is_read) {
						return <ReadOutlined style={{color: 'blue'}}/>
					}
					else
						return <ReadFilled style={{color: 'blue'}}/>
				},
				sortOrder: sortingFilters?.column?.dataIndex === 'is_read' ? sortingFilters?.order: null
			},
		],
		sendersColumns: [
			{
				title: 'Namn',
				dataIndex: 'name',
				...getSearchFilterByName({type: 'senders', tableFilters, dataIndex: 'name', placeholder: 'Search for date'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'name' ? sortingFilters?.order: null
			},
			{
				title: 'Företag',
				dataIndex: 'company_name',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'company_name' ? sortingFilters?.order: null
			},
			{
				title: 'Företag',
				dataIndex: 'address_1',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'address_1' ? sortingFilters?.order: null
			},
			{
				title: 'Postnummer',
				dataIndex: 'zipcode',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'zipcode' ? sortingFilters?.order: null
			},
			{
				title: 'Land',
				dataIndex: 'country',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'country' ? sortingFilters?.order: null
			},
		],
		userCarriersColumns: [
			{
				title: 'Namn',
				dataIndex: 'carrier_id',
				...getSearchFilterByName({type: 'user_carriers', tableFilters, dataIndex: 'carrier_id', placeholder: 'Search'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'carrier_id' ? sortingFilters?.order: null
			}
		],
		synchronizationJobsColumns: [
			{
				title: 'Site Id',
				dataIndex: 'site_id',
				...getSearchFilterByName({type: 'synchronization_jobs', tableFilters, dataIndex: 'name', placeholder: 'Search for date'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'site_id' ? sortingFilters?.order: null
			},
			{
				title: 'Slave System ID',
				dataIndex: 'slave_system_id',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'slave_system_id' ? sortingFilters?.order: null
			},
			{
				title: 'Typ',
				dataIndex: 'synchronization_type',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'synchronization_type' ? sortingFilters?.order: null
			},
			{
				title: 'Sync frekvens',
				dataIndex: 'sync_frequency',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'sync_frequency' ? sortingFilters?.order: null
			}
		],
		productsColumns: [
			{
				title: 'SKU',
				dataIndex: 'sku',
				editable: true,
				responsive: ['md'],
				...getSearchFilterByName({dataIndex: 'sku', type: 'products', tableFilters, placeholder: 'Search for sku'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'sku' ? sortingFilters?.order: null
			},
			{
				title: 'Benämning',
				dataIndex: 'title',
				editable: true,
				...getSearchFilterByName({dataIndex: 'title', type: 'products', tableFilters, placeholderText:'Type product name'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'title' ? sortingFilters?.order: null
			},
			{
				title: 'Pris',
				dataIndex: 'price',
				editable: true,
				type: 'number',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'price' ? sortingFilters?.order: null
			},
			{
				title: 'Skapad',
				dataIndex: 'datetime_created',
				render: (_, product) => {
					return getDateAndTime(product.datetime_created)
				},
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'datetime_created' ? sortingFilters?.order: null
			},
			{
				title: 'Uppdaterad',
				dataIndex: 'datetime_last_updated',
				render: (_, product) => {
					return getDateAndTime(product.datetime_last_updated)
				},
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'datetime_last_updated' ? sortingFilters?.order: null
			}

		],
		shippingLabelsColumns: [
			{
				title: 'Order Id',
				dataIndex: 'order_id',
				editable: true,
				render: (_, shipping_label) => {
					return shipping_label.shipment.order_id
				},
				...getSearchFilterByName({dataIndex: 'name', type: 'shipping_services', tableFilters, placeholderText:'Type orderId'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'name' ? sortingFilters?.order: null
			},
			{
				title: 'Mottagare',
				dataIndex: 'receiver',
				editable: true,
				render: (_, shipping_label) => {
					return shipping_label.shipment.receiver_first_name + ' ' + shipping_label.shipment.receiver_last_name
				},
				...getSearchFilterByName({dataIndex: 'name', type: 'shipping_services', tableFilters, placeholderText:'Type name'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'name' ? sortingFilters?.order: null
			},
			{
				title: 'Adress',
				dataIndex: 'receiver_address',
				editable: true,
				render: (_, shipping_label) => {
					return shipping_label.shipment.receiver_address_1
				},
				...getSearchFilterByName({dataIndex: 'name', type: 'shipping_services', tableFilters, placeholderText:'Type address'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'name' ? sortingFilters?.order: null
			},
			{
				title: 'Postort',
				dataIndex: 'receiver_zipcode',
				editable: true,
				render: (_, shipping_label) => {
					return shipping_label.shipment.receiver_zipcode
				},
				...getSearchFilterByName({dataIndex: 'name', type: 'shipping_services', tableFilters, placeholderText:'Type zipcode'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'name' ? sortingFilters?.order: null
			},
			{
				title: 'Ort',
				dataIndex: 'receiver_city',
				editable: true,
				render: (_, shipping_label) => {
					return shipping_label.shipment.receiver_city
				},
				...getSearchFilterByName({dataIndex: 'name', type: 'shipping_services', tableFilters, placeholderText:'Type city name'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'name' ? sortingFilters?.order: null
			}
		],
		shippingServiceMappingsColumns: [
			{
				title: 'Avsändare',
				dataIndex: 'sender_id',
				editable: true,
				...getSearchFilterByName({dataIndex: 'name', type: 'shipping_services', tableFilters, placeholderText:'Type product name'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'name' ? sortingFilters?.order: null
			},

			{
				title: 'Fraktjänst',
				dataIndex: 'shipping_service_id',
				editable: true,
				...getSearchFilterByName({dataIndex: 'shipping_type', type: 'shipping_services', tableFilters, placeholderText:'Type product name'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'shipping_type' ? sortingFilters?.order: null
			},
		],
		shippingServicesColumns: [
			{
				title: 'Service ID',
				dataIndex: 'service_id',
				editable: true,
				responsive: ['md'],
				...getSearchFilterByName({dataIndex: 'service_id', type: 'shipping_services', tableFilters, placeholder: 'Search for Service'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'service_id' ? sortingFilters?.order: null
			},
			{
				title: 'Name',
				dataIndex: 'name',
				editable: true,
				...getSearchFilterByName({dataIndex: 'name', type: 'shipping_services', tableFilters, placeholderText:'Type product name'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'name' ? sortingFilters?.order: null
			},
			{
				title: 'Typ',
				dataIndex: 'shipping_type',
				editable: true,
				...getSearchFilterByName({dataIndex: 'shipping_type', type: 'shipping_services', tableFilters, placeholderText:'Type product name'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'shipping_type' ? sortingFilters?.order: null
			},
		],
		shipmentsColumns: [
			{
				title: 'Skapad',
				dataIndex: 'datetime_created',
				editable: true,
				render: (_, shipment) => {
					return getDateAndTime(shipment.datetime_created)
				},
				responsive: ['md'],
				...getSearchFilterByName({dataIndex: 'datetime_created', type: 'shipments', tableFilters, placeholder: 'Search for sku'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'datetime_created' ? sortingFilters?.order: null
			},
			{
				title: 'Senast ändrad',
				dataIndex: 'datetime_last_updated',
				render: (_, shipment) => {
					return getDateAndTime(shipment.datetime_last_updated)
				},
				editable: true,
				...getSearchFilterByName({dataIndex: 'name', type: 'shipments', tableFilters, placeholderText:''}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'datetime_last_updated' ? sortingFilters?.order: null
			},
			{
				title: 'OrderId',
				dataIndex: 'order_id',
				editable: true,
				...getSearchFilterByName({dataIndex: 'order_id', type: 'shipments', tableFilters, placeholderText:'Type order id'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'order_id' ? sortingFilters?.order: null
			},
			{
				title: 'Adress',
				dataIndex: 'receiver_address_1',
				editable: true,
				...getSearchFilterByName({dataIndex: 'receiver_address_1', type: 'shipments', tableFilters, placeholderText:'Type address'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'receiver_address_1' ? sortingFilters?.order: null
			},
			{
				title: 'Postnummer',
				dataIndex: 'receiver_zipcode',
				editable: true,
				...getSearchFilterByName({dataIndex: 'receiver_zipcode', type: 'shipments', tableFilters, placeholderText:'Type zipcode'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'receiver_zipcode' ? sortingFilters?.order: null
			},
			{
				title: 'Ort',
				dataIndex: 'receiver_city',
				editable: true,
				...getSearchFilterByName({dataIndex: 'receiver_city', type: 'shipments', tableFilters, placeholderText:'Type city'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'receiver_city' ? sortingFilters?.order: null
			},
			{
				title: 'Land',
				dataIndex: 'receiver_country',
				editable: true,
				...getSearchFilterByName({dataIndex: 'receiver_country', type: 'shipments', tableFilters, placeholderText:'Type country'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'receiver_country' ? sortingFilters?.order: null
			},
			{
				title: 'Avsändare',
				dataIndex: 'sender_id',
				editable: true,
				...getSearchFilterByName({dataIndex: 'sender_id', type: 'shipments', tableFilters, placeholderText:''}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'sender_id' ? sortingFilters?.order: null
			},
			{
				title: 'Skickad datum',
				dataIndex: 'shipment_date',
				editable: true,
				...getSearchFilterByName({dataIndex: 'shipment_date', type: 'shipments', tableFilters, placeholderText:'Type product name'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'shipment_date' ? sortingFilters?.order: null
			},
		],
		ordersColumns: [
			{
				title: 'Ordernummer',
				dataIndex: 'order_number',
				...getSearchFilterByName({dataIndex: 'order_number', type: 'orders', tableFilters, placeholderText: 'Sök efter ordernummer', isNumber: true}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'order_number' ? sortingFilters?.order: null,
			},
			{
				title: 'Datum',
				dataIndex: 'datetime_created',
				render: (_, order) => getDateAndTime(order.datetime_created),
				...getRangeDateDropdown({dataIndex: 'datetime_created', tableFilters}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'datetime_created' ? sortingFilters?.order: null,
			},
			{
				title: 'Kundens namn',
				dataIndex: 'customer_first_name',
				render: (_, order) => `${order?.customer?.billing_first_name}`,
				...getSearchFilterByName({dataIndex: 'customer_first_name', type: 'orders', tableFilters, placeholderText: 'Sök efter kundens namn'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'customer_first_name' ? sortingFilters?.order: null,
			},
			{
				title: 'Kundens efternamn',
				dataIndex: 'customer_last_name',
				render: (_, order) => `${order?.customer?.billing_last_name}`,
				...getSearchFilterByName({dataIndex: 'customer_last_name', type: 'orders', tableFilters, placeholderText: 'Sök efter kundens efternamn'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'customer_last_name' ? sortingFilters?.order: null,
			},
			{
				title: 'Leveransdag',
				dataIndex: 'delivery_date',
				render: (_, order) => order.delivery_date ? getDateAndTime(order.delivery_date) : '',
				...getRangeDateDropdown({dataIndex: 'delivery_date', tableFilters, from: 'delivery_date_from', to: 'delivery_date_to' }),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'delivery_date' ? sortingFilters?.order: null,
			},
			{
				title: 'Status',
				dataIndex: 'status',
				...getFiltersDropdown({values:  [
						{text: 'Behandlas', value: 'processing'},
						{text: 'Avbruten', value: 'cancelled'},
						{text: 'Färdigbehandlad', value: 'completed'},
				], dataIndex: 'status', type: 'orders'}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'status' ? sortingFilters?.order: null,
			},
			{
				title: 'Site',
				dataIndex: 'site_id',
				render: (_, order) => order.site && order.site.name,
				...getFiltersDropdown({
					dataIndex: 'site_id',
					type: 'orders',
					isCustomSelect: true,
					customSelectMode: 'single',
					optionValue: 'id',
					fetchType: 'sites'
				})
			},
			{
				title: 'Total',
				dataIndex: 'total',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'total' ? sortingFilters?.order: null,
			},
			{
				title: 'Betalmetod',
				dataIndex: 'payment_method_name',
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'payment_method_name' ? sortingFilters?.order: null,
			},
			{
				title: 'Fraktmetod',
				dataIndex: 'shipping_method_machine_name',
                render: (_, order) => {
                  return order?.shipping_method_name
                },
				...getFiltersDropdown({
					type: 'orders',
					isCustomSelect: true,
					customSelectMode: 'single',
					dataIndex: 'shipping_method_machine_name',
					optionValue: 'machine_name',
					optionName: 'name',
					fetchType: 'shipping_services',
					filterText: 'shipping method'
				}),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'shipping_method_machine_name' ? sortingFilters?.order: null,
			}
		],
		shippingBaseAddressesColumns: [
			{
				title: 'Namn',
				dataIndex: 'name'
			},
			{
				title: 'Adress',
				dataIndex: 'address'
			},
			{
				title: 'Ort',
				dataIndex: 'city'
			},
			{
				title: 'Land',
				dataIndex: 'country'
			},
			{
				title: 'Epost',
				dataIndex: 'email'
			},
			{
				title: 'Telefon',
				dataIndex: 'phone'
			},
		],
		shippingMethodsColumns: [
			{
				title: 'Namn',
				dataIndex: 'name'
			},
			{
				title: 'Aktiv',
				dataIndex: 'enabled',
				render: (_, record) => {
					if (record.enabled) {
						return <div>Ja</div>
					}
					else{
						return <div>Nej</div>
					}
				},
			},
			{
				title: 'Försäljningsställen',
				dataIndex: 'site_id',
				render: (_, site) => {
					const currentSite = sites?.objects?.find(_site => _site.id === site.site_id);
					return currentSite && currentSite.name
				}
			},
		],
		picklistColumns: [
			{
				title: 'Leveransdag',
				dataIndex: 'delivery_date',
				render: (_, order) => getDateAndTime(order.delivery_date),
				sorter: () => false,
				sortOrder: sortingFilters?.column?.dataIndex === 'delivery_date' ? sortingFilters?.order: null,
			},
			{
				title: 'Datum',
				dataIndex: 'datetime_created',
				render: (_, order) => getDateAndTime(order.datetime_created)
			},
			{
				title: 'Ordernummer',
				dataIndex: 'order_number',
				render: (_, order) => orderRender(order)
			},
			{
				title: 'Kundens namn',
				dataIndex: 'customer_first_name',
				render: (_, order) => `${order?.customer?.billing_first_name}`
			},
			{
				title: 'Kundens efternamn',
				dataIndex: 'customer_last_name',
				render: (_, order) => `${order?.customer?.billing_last_name}`
			},
			{
				title: 'Packstatus',
				dataIndex: 'packing_status',
				render: (_, order) => {
					if (order.packing_status) {
						const statusTitle = order.packing_status.replaceAll('_', ' ');

						if (order.packing_status === 'not_packed') {
							return <Tag color="volcano">{statusTitle}</Tag>
						} else {
							return <Tag color="green">{statusTitle}</Tag>
						}
					} else {
						return '';
					}
				}
			},
			{
				title: 'Antal produkter',
				dataIndex: 'total_items',
				render: (_, order) => {
					if (order.order_items && order.order_items.length > 0) {
						return order.order_items.reduce((prev, item) => +prev + +item.quantity, 0)
					}
					return order.total_items;
				}
			},
			{
				title: 'Pris',
				dataIndex: 'total'
			},
			{
				title: 'Fraktsätt',
				dataIndex: 'shipping_method_name'
			},
		],
		picklistTotalProductsColumns: [
			{
				title: 'Produkt',
				dataIndex: 'name'
			},
			{
				title: 'Antal',
				dataIndex: 'quantity'
			},
		],
		picklistTotalDeliveryColumns: [
			{
				title: 'Leveranssätt',
				dataIndex: 'name'
			},
			{
				title: 'Antal',
				dataIndex: 'count'
			},
		]
	}

	if (sortingFilters?.order &&
		sortingFilters?.field &&
		sortingFilters?.field === 'order_id') {
		columns.productsColumns = insert(columns.productsColumns, 0, {
			title: 'Sort',
			dataIndex: 'sort',
			width: 30,
			render: () => <DragOutlined className="draggable" style={{cursor: 'grab'}} type="swap" />,
		})

		columns.productsColumns?.map(col => {
			if (col.sorter) {
				col.sorter = false
			}

			return col;
		})
	}

	return columns;
}


