import React, {useEffect} from 'react';
import {getColumns} from "../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../components/ant-table/dashboardColumns";
import AntTable from "../../components/AntTable/AntTable";
import {getActionTypes} from "../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../helpers/getUseSelectorFromType";
import {PlusCircleOutlined} from "@ant-design/icons";
import {Row} from "antd";

const SynchronizationJobs = ({type = 'synchronization_jobs', title = 'Synkronisationsjobb'}) => {
    const {getItems, removeItem, setSynchronizationJobsFiltering, setSynchronizationJobsSorting, setSynchronizationJobsPagination, setItemsNull} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const {id} = useParams();
    const location = useLocation();
    const {synchronizationJobs, loading, pagination, synchronizationJobsTableSorting, synchronizationJobsTableFilters} = useSelector(state => state?.synchronizationJobs);

    useEffect(() => {
        if (!location.pathname.includes('new') && !id) {
            getItems({
                params: {
                    ...(synchronizationJobsTableSorting && Object.keys(synchronizationJobsTableSorting).length > 0 && synchronizationJobsTableSorting?.order && {
                        order_by: synchronizationJobsTableSorting.field,
                        order_type: synchronizationJobsTableSorting.order.replace('end', '')
                    }),
                    ...{...(synchronizationJobsTableFilters && Object.keys(synchronizationJobsTableFilters).length > 0 && synchronizationJobsTableFilters)}
                },
                ...getActionTypes(type)
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        isRemovable:true,
    })

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <Row>
                            <Link to='new'>
                                <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                            </Link>
                        </Row>
                        <AntTable initialData={synchronizationJobs} total={synchronizationJobs?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setSynchronizationJobsFiltering}
                                  setSorting={setSynchronizationJobsSorting}
                                  setPagination={setSynchronizationJobsPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                        />
                    </>
                }
            </>
    );
};

export default SynchronizationJobs;
