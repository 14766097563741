import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import useActions from "../../../hooks/useActions";
import {useLocation} from "react-router-dom";


const PrintBlock = ({finishHandler}) => {
    const {setSelectedRows, setSelectedRowKeys} = useActions();
    const {selected, isAvailable, loading} = useSelector(state => state?.bulkOperations);
    const [changed] = useState(false);
    const [setIsDisabled] = useState(true);
    const location = useLocation();

    useEffect(() => {
        return () => {
            setSelectedRows([])
            setSelectedRowKeys([])
        };
    }, [location]);

    useEffect(() => {
        if (changed) {
            setIsDisabled(false);
        }
    }, [changed]);


    return (
        isAvailable && selected.length > 0 &&
        <>
            <Button type="primary" icon={<EditOutlined/>} onClick={finishHandler}>Printa</Button>
        </>
    );
};

export default PrintBlock;
