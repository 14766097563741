import React, {useEffect} from 'react';
import {getColumns} from "../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../hooks/useActions";
import PrintOperations from "../../components/PrintOperations/PrintOperations";
import EditShipmentForm from "../../views/Shipments/EditShipmentForm";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../components/ant-table/dashboardColumns";
import AntTable from "../../components/AntTable/AntTable";
import {getActionTypes} from "../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../helpers/getUseSelectorFromType";
import {Row} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import AddShipmentForm from "./AddShipmentForm";

const Shipments = ({type = 'shipments', title = 'Försändelser'}) => {
    const {getItems, removeItem, setShipmentsFiltering, setShipmentsSorting, setShipmentsPagination, setItemsNull} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const dashboardPrintFormElementsType = useSelectorType + 'PrintFormElements';
    const {id} = useParams();
    const location = useLocation();
    const {shipments, loading, pagination, shipmentsTableSorting, shipmentsTableFilters} = useSelector(state => state?.shipments);

    useEffect(() => {
        console.log(shipments);
        if (!location.pathname.includes('new') && !id) {
            getItems({
                params: {
                    ...(shipmentsTableSorting && Object.keys(shipmentsTableSorting).length > 0 && shipmentsTableSorting?.order && {
                        order_by: shipmentsTableSorting.field,
                        order_type: shipmentsTableSorting.order.replace('end', '')
                    }),
                    ...{...(shipmentsTableFilters && Object.keys(shipmentsTableFilters).length > 0 && shipmentsTableFilters)}
                },
                ...getActionTypes(type)
            });

        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        isRemovable:false,
        isPrintable:true
    })

    return (
        id ? <EditShipmentForm
            id={id}
        /> : location.pathname.includes('/new') ? <AddShipmentForm/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                <Row className='filter-block' justify='space-between' align='middle'>
                    <Row>
                        <PrintOperations type={type} dashboardPrintFormElementsType={dashboardPrintFormElementsType} actionsTypes={getActionTypes(type)} itemType='orders'/>
                    </Row>
                </Row>
                <Row>
                    <Link to='new'>
                        <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                    </Link>
                </Row>
                <AntTable initialData={shipments} total={shipments?.total} loading={loading}
                          pagination={pagination} columns={columns} type={type}
                          setFilters={setShipmentsFiltering}
                          setSorting={setShipmentsSorting}
                          setPagination={setShipmentsPagination}
                          getItemsDynamicData={getActionTypes(type)}
                />
            </>
    );
};

export default Shipments;
