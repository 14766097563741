
import {
    SET_SHIPMENTS_TABLE_FILTERS,
    SET_SHIPMENTS_TABLE_SORTING
} from "../reducers/shipmentsReducer";

export const shipmentActions = {

    setShipmentsFiltering: (payload) => (
        {
            type: SET_SHIPMENTS_TABLE_FILTERS,
            payload
        }
    ),
    setShipmentsSorting: (payload) => (
        {
            type: SET_SHIPMENTS_TABLE_SORTING,
            payload
        }
    ),
}
