import React from 'react';
import {Modal} from "antd";
import {useSelector} from "react-redux";
import useActions from "../../../hooks/useActions";
import './picklistOrderPopup.scss'

const PickListOrderPopup = ({children}) => {
    const {setPickListOrderPopup} = useActions()
    const {picklistOrderPopup} = useSelector(state => state?.picklist)

    const onCancelPopup = () => {
        setPickListOrderPopup({visible: false, orderId: null, order: {}})
    }

    return (
        <Modal className='picklist-order-popup' width='700px' visible={picklistOrderPopup.visible} onCancel={onCancelPopup} footer={false}>
            {children}
        </Modal>
    );
};

export default PickListOrderPopup;
