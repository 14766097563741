import React, {useEffect} from 'react';
import {getColumns} from "../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../components/ant-table/dashboardColumns";
import AntTable from "../../components/AntTable/AntTable";
import {getActionTypes} from "../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../helpers/getUseSelectorFromType";
import {PlusCircleOutlined} from "@ant-design/icons";
import {Row} from "antd";

const UserCarriers = ({type = 'user_carriers', title = 'Transportör'}) => {
    const {getItems, removeItem, setUserCarriersFiltering, setUserCarriersSorting, setUserCarriersPagination, setItemsNull} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const {id} = useParams();
    const location = useLocation();
    const {userCarriers, loading, pagination, userCarriersTableSorting, userCarriersTableFilters} = useSelector(state => state?.userCarriers);

    useEffect(() => {
        if (!location.pathname.includes('new') && !id) {
            getItems({
                params: {
                    ...(userCarriersTableSorting && Object.keys(userCarriersTableSorting).length > 0 && userCarriersTableSorting?.order && {
                        order_by: userCarriersTableSorting.field,
                        order_type: userCarriersTableSorting.order.replace('end', '')
                    }),
                    ...{...(userCarriersTableFilters && Object.keys(userCarriersTableFilters).length > 0 && userCarriersTableFilters)}
                },
                ...getActionTypes(type)
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        isRemovable:true,
    })

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <Row>
                            <Link to='new'>
                                <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                            </Link>
                        </Row>
                        <AntTable initialData={userCarriers} total={userCarriers?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setUserCarriersFiltering}
                                  setSorting={setUserCarriersSorting}
                                  setPagination={setUserCarriersPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                        />
                    </>

                }
            </>
    );
};

export default UserCarriers;
