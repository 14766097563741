import React from 'react';

const LostPassword = () => {
    return (
        <div>
            Glömt lösenord (kommer snart)
        </div>
    );
};

export default LostPassword;
