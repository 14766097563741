import moment from "moment";

const initialState = {
    rangeDate: [ moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')],
    isRangeChanged: false
}

export const SET_RANGE_DATE = 'SET_RANGE_DATE';
export const SET_RANGE_CHANGED = 'SET_RANGE_CHANGED';
export const CLEAR_RANGE_DATE = 'CLEAR_RANGE_DATE';

const rangeDateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RANGE_DATE:
            console.log(action.payload)
            return {...state, rangeDate: action.payload};
        case SET_RANGE_CHANGED:
            return {...state, isRangeChanged: action.payload};
        case CLEAR_RANGE_DATE:
            return {...state, rangeDate: action.payload};
        default:
            return state;
    }
}

export default rangeDateReducer;
