import {
    SET_REGISTER_LOADING, SET_REGISTER_VALUES
} from "../reducers/registerReducer";
import {registerUser} from "../../helpers/auth";
import {message} from "antd";

export const registerActionCreator = {
    register: (values) => (dispatch, useState) => {

        console.log(values);
        dispatch({type: SET_REGISTER_LOADING, payload: true})
        dispatch({type: SET_REGISTER_VALUES, payload: values})

        registerUser(values).then(response => {
                console.log(response);
        })
        .catch(error => {
            message.error(error.toString());
            dispatch({type: SET_REGISTER_LOADING, payload: false})
        })
    }
}
