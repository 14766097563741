import React, {useState} from 'react';
import {Button, Input, InputNumber, Row} from "antd";
import {SearchOutlined} from "@ant-design/icons";

const getSearchFilterByName = ({dataIndex, type, tableFilters, placeholderText, isNumber = false}) => {
    const [value, setValue] = useState(tableFilters ? (tableFilters[dataIndex] ?? '') : '');

    return {
        filterDropdown: ({setSelectedKeys, confirm}) => {
            let inputNode = null;
            let val;

            const initialProps = {
                ref: node => {
                    inputNode = node
                },
                autoFocus: true,
                width: '60%',
                placeholder: placeholderText,
                value,
                onChange: e => {
                    setSelectedKeys(isNumber ? [e] : (e.target?.value ? [e.target.value] : []))
                    setValue(isNumber ? e : e.target.value)
                },
                onPressEnter: () => {
                    if (inputNode) {
                        val = inputNode?.input ? [inputNode?.input?.value] : [];
                        setSelectedKeys(val)
                        setValue(inputNode?.input ? inputNode?.input?.value : '')
                    }
                    confirm({closeDropdown: true});
                }
            }

            return (
                <Row id={dataIndex + '_search_dropdown'} style={{
                    padding: '20px',
                    maxWidth: '300px',
                }}>
                    {
                        isNumber ? <InputNumber style={{width: '100%'}} {...initialProps}/> : <Input {...initialProps}/>
                    }
                    <Row style={{marginTop: '15px'}}>
                        <Button onClick={() => {
                            if (inputNode) {
                                setSelectedKeys([isNumber ? inputNode.value : inputNode.input.value])
                                setValue(isNumber ? inputNode.value : inputNode.input.value)
                                confirm({closeDropdown: true});
                            }
                        }} type='primary' ghost style={{marginRight: '10px'}}>Sök</Button>
                        <Button
                            onClick={() => {
                                setSelectedKeys([]);
                                setValue('');
                                confirm({closeDropdown: true});
                            }}
                            type="default" danger
                        >
                            Återställ
                        </Button>
                    </Row>
                </Row>
            )
        },
        filteredValue: value,
        filterIcon: () => {
            return <SearchOutlined data-type={isNumber ? 'number': 'text'} id={dataIndex}/>
        },
    }
}

export default getSearchFilterByName;
