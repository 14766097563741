export const getDateAndTime = (date) => {
    if (date){
        return date.split('.')[0];
    }
    return '';
}

export const getDateFormat = () => {
    return 'YYYY-MM-DD';
}

export const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const padL = (nr, length = 2, chr = `0`) => `${nr}`.padStart(2, chr);

// yyyy-mm-dd
export const getFormatDate = (date) => {
    return `${date.getFullYear()}-${padL(date.getMonth()+1)}-${padL(date.getDate())}`;
}

// h:m:s
export const getFormatTime = (date) => {
    const padL = (nr, length = 2, chr = `0`) => `${nr}`.padStart(2, chr);
    return `${padL(date.getHours())}:${padL(date.getMinutes())}:${padL(date.getSeconds())}`;
}

export const formatDateToPayloadFormat = (date) => {
    return `${getFormatDate(date)} ${getFormatTime(date)}`;
}