import React, {createRef, useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Row, Select, Spin} from 'antd';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {SET_SENDER} from "../../state/reducers/sendersReducer";

import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import "./EditSenderForm.scss";
import {validateVatNumber} from "../../helpers/validators";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditSenderForm = ({id}) => {
    const {updateItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const {sender, loading} = useSelector(state => state?.senders);
    const formRef = createRef();
    const {Option} = Select;
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            setItemNull(SET_SENDER);
        };
    }, []);


    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        updateItem({
            values: values,
            id,
            ...getActionTypes('senders'),
        });

        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/senders');
    };

    if (Object.keys(sender).length === 0) {
        return <Row data-testid='spinner' justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <>
            <Form {...layout}
              ref={formRef}
              layout="vertical"
              data-testid='edit-sender-form'
              name="edit-sender"
              onFinish={finishHandler}
              initialValues={{}}
              style={{width: '100%'}}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}
            >
                <h2>Avsändare {sender.id}</h2>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item
                            name='name'
                            label="Namn"
                            initialValue={sender?.name}
                            rules={[{required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <h3>Adress</h3>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item
                            name='address_1'
                            style={{ textC: "red" }}
                            label="Adress"
                            initialValue={sender?.address_1}
                            rules={[{required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='address_2'
                            label="Annan adress"
                            initialValue={sender?.address_2}
                            rules={[{required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='zipcode'
                            label="Postnummer"
                            initialValue={sender?.zipcode}
                            rules={[{required: true,},]}
                        >
                            <InputNumber/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='city'
                            label="Ort"
                            initialValue={sender?.city}
                            rules={[{required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='country'
                            label="Land"
                            initialValue={sender?.country}
                            rules={[{required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='state'
                            label="Stat"
                            initialValue={sender?.state}
                            rules={[{required: false,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <h3>Företag</h3>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item
                            name='company_name'
                            label="Företagsnamn"
                            initialValue={sender?.company_name}
                            rules={[{required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='contact_person'
                            label="Kontaktperson"
                            initialValue={sender?.contact_person}
                            rules={[{required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='phone_number'
                            label="Telefonnummer"
                            initialValue={sender?.phone_number}
                            rules={[{required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='email'
                            label="Email"
                            initialValue={sender?.email}
                            rules={[{required: true,},]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='vat_number'
                            label="VAT nummer"
                            initialValue={sender?.vat_number}
                            rules={[
                                { required: true, message: 'Please enter a VAT number' },
                                {
                                  validator: (_, value) =>
                                    validateVatNumber(value)
                                      ? Promise.resolve()
                                      : Promise.reject('Invalid VAT number')
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                <Form.Item>
                    <Row>
                        {
                            isChanged ?
                                <Button style={{marginRight: '20px'}} type="primary" loading={loading}
                                        htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
};

export default EditSenderForm;
