export const addFormElements = {
    productsAddFormElements: [
        {
            name: 'title',
            required: true,
            label: 'Titel',
            type: 'text',
        },
        {
            name: 'price',
            required: true,
            label: 'Pris (kr)',
            type: 'number',
        },
        {
            name: 'purchase_price',
            required: false,
            label: 'Inköpspris (kr)',
            type: 'number',
        },
        {
            name: 'description',
            required: false,
            label: 'Beskrivning',
            type: 'quill'
        },
        {
            name: 'short_description',
            required: false,
            label: 'Kort beskrivning',
            type: 'quill'
        },
        {
            name: 'attributes',
            required: false,
            label: 'Attribut',
            type: 'attribute_select',
        },
        {
            name: 'weight',
            required: false,
            label: 'Vikt (kg)',
            type: 'number',
        },
        {
            name: 'length',
            label: 'Längd (cm)',
            required: false,
            type: 'number',
        },
        {
            name: 'width',
            label: 'Bredd (cm)',
            required: false,
            type: 'number',
        },
        {
            name: 'height',
            required: false,
            label: 'Höjd (cm)',
            type: 'number',
        }
    ],
    shippingServiceMappingsAddFormElements: [
        {
            name: 'shipping_service_id',
            required: true,
            label: 'Fraktjänst',
            type: 'text',
        },
        {
            name: 'sender_id',
            required: true,
            label: 'Avsändare',
        },
        {
            name: 'shipping_method_identifier',
            required: true,
            label: 'Shipping method identifier',
        },
        {
            name: 'should_print_return_labels',
            required: true,
            label: 'Printa retursedlar',
            type: 'checkbox',
        },
        {
            name: 'should_send_sms',
            required: true,
            label: 'Skicka sms',
            type: 'checkbox',
        },
        {
            name: 'should_send_email',
            required: true,
            label: 'Skicka mail',
            type: 'checkbox',
        },
    ],
    attributesAddFormElements: [
        {
            name: 'name',
            required: true,
            label: 'Attributets namn',
            type: 'text',
        },
        {
            name: 'values',
            required: true,
            label: 'Attributets värde',
        },
    ],
    shippingMethodsAddFormElements: [
        {
            name: 'name',
            required: true,
            label: "Namn",
        },
        {
            name: 'description',
            label: "Beskrivning",
        },
        {
            name: 'pickup_address',
            label: "Upphämtningsadress",
        },
        {
            required: true,
            name: 'shipping_type',
            type: 'select',
            label: 'Leveranstyp',
            options: [
                {
                    value: 'Hämta i butik',
                    key: 'pickup'
                },
                {
                    value: 'Hemleverans',
                    key: 'home'
                }
            ]
        },
        {
            type: 'home_delivery_type'
        },
        {
            required: true,
            name: 'price',
            type: 'number',
            label: 'Pris'
        },
        {
            name: 'free_shipping_threshold',
            type: 'number',
            label: 'Fri frakt, gräns'
        },
        {
            name: 'postcodes',
            type: 'textarea',
            label: 'Postnummer'
        }
    ],
    shippingBaseAddressesAddFormElements: [
        {
            name: 'name',
            required: true,
            label: "Namn",
        },
        {
            name: 'address',
            required: true,
            label: "Adress",
        },
        {
            name: 'city',
            required: true,
            label: "Ort",
        },
        {
            name: 'country',
            required: true,
            label: "Land",
        },
        {
            name: 'email',
            required: true,
            label: "E-post",
            type: 'email',
            rules: {
                type: 'email',
                message: 'Detta är ej en giltig e-postadress!',
            }
        },
        {
            name: 'phone',
            required: true,
            label: "Telefon",
            type: 'phone'
        }
    ],
    shippingClassesAddFormElements: [
        {
            name: 'name',
            required: true,
            label: 'Namn'
        },
        {
            name: 'description',
            required: true,
            label: 'Beskrivning',
            type: 'textarea'
        },
        {
            name: 'woocommerce_id',
            required: true,
            label: 'WooCommerce-ID',
            type: 'number'
        },
    ],
    stockObjectsAddFormElements: [],
    ordersAddFormElements: [],
    sitesAddFormElements: [],
}
