import React, {useState, useEffect} from 'react';
import {Button, Form, Input, message, Row, InputNumber, Select, Col} from 'antd';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import axios from "axios";
import {getApiUrl} from "../../helpers/helpers";

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};



const EditPrinterSettingsForm = ({id}) => {
    const {updateItem} = useActions()
    const [printerSettings, SetPrinterSettings] = useState(null);
    const [isChanged, setIsChanged] = useState(false);

    const navigate = useNavigate();
    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const options = [
      { value: 'A4', label: 'A4' },
      { value: 'A5', label: 'A5' },
      { value: 'A6', label: 'A6' },
      { value: 'LETTER', label: 'LETTER' },
      { value: 'LABEL', label: 'LABEL' }
    ];

    useEffect(() => {
        getPrinterSettings();
    }, []);

    const getPrinterSettings = () => {
        axios.get(`${getApiUrl('printer_settings')}printer_settings/`,  {
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            }
        ).then(res => {
            const printerSettings = res.data;
            SetPrinterSettings(printerSettings);
        }).catch(err => {
            message.error(err?.data?.message)
        })
    }

    const [selectedOption, setSelectedOption] = useState(null);

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const finishHandler = (values) => {
        updateItem({
            values,
            ...getActionTypes('printer_settings'),
            contentText: `Du har uppdaterat printer inställningarna`
        });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/');
    };

    const renderEditPrinterSettings = () => {
        if (!printerSettings) {
            return (
                <Form {...layout}
                      name="edit-printer_settings"
                      onFinish={finishHandler}
                      layout={'vertical'}
                      validateMessages={validateMessages}>
                    <h2>Printer inställningar</h2>
                    <Row gutter={12}>
                        <Col span={6}>
                            <Form.Item
                                label="Format"
                                name="dimensions"
                                rules={[{required: true}]}>
                                <Select
                                    value={selectedOption}
                                    onChange={handleSelectChange}
                                    options={options}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Rotation"
                                name="rotate"
                                rules={[]}>
                                <InputNumber/>
                            </Form.Item>
                            <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                                <Row justify="space-between">
                                    <Button type="primary" htmlType="submit">
                                        Spara
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            );
        }

    return (
        <Form {...layout}
              name="edit-printer_settings"
              onFinish={finishHandler}
              layout={'vertical'}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}>
            <h2>Printer inställningar</h2>
                <Row gutter={13}>
                    <Col span={6}>
                        <Form.Item
                            name='id'
                            label="ID"
                            hidden={true}
                            initialValue={printerSettings.id}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input bordered={false} readOnly={true}/>
                        </Form.Item>
                        <Form.Item
                            name='dimensions'
                            label="Format"
                            initialValue={printerSettings.dimensions}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                             <Select
                              value={selectedOption}
                              onChange={handleSelectChange}
                              options={options}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='rotate'
                            label="Rotation"
                            initialValue={printerSettings.rotate}
                            rules={[]}
                        >
                            <InputNumber/>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                    <Row justify="space-between">
                        {
                            isChanged ?
                                <Button type="primary" htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
        </Form>
    );
}

    return (
        renderEditPrinterSettings()
    );
};

export default EditPrinterSettingsForm;
