import React, {useEffect, useState} from 'react';
import {Button, Row} from "antd";
import {FilterFilled} from "@ant-design/icons";
import useActions from "../hooks/useActions";
import RangeDatePicker from "../components/Misc/RangeDatePicker/RangeDatePicker";
import {renderDateYMD} from "./helpers";
import moment from "moment";
import {getDateFormat} from "./getDate";

const getRangeDateDropdown = ({dataIndex, tableFilters, from = 'date_from', to = 'date_to'}) => {
    const dateFormat = getDateFormat();
    const {setIsTableFiltered} = useActions()
    const [iconColor, setIconColor] = useState(null)
    const todayDate = [moment(new Date(), dateFormat), moment(new Date(), dateFormat)];
    const [rangeDate, setRangeDate] = useState(todayDate)
    const [isRangeChanged, setIsRangeChanged] = useState(false);

    const filterHandler = (setSelectedKeys, selectedKeys, confirm) => {
        if (isRangeChanged) {
            setSelectedKeys([{
                [from]: renderDateYMD(rangeDate[0]._d),
                [to]: renderDateYMD(rangeDate[1]._d)
            }])
            setIsTableFiltered(false);
            setIconColor('#1890ff')
            confirm({closeDropdown: true});
        }
    }

    useEffect(() => {
        if (tableFilters && tableFilters[from] && tableFilters[to]) {
            setRangeDate([moment(new Date(tableFilters[from]), dateFormat), moment(new Date(tableFilters[to]), dateFormat)]);
            setIconColor('#1890ff')
            setIsRangeChanged(true)
        }
    }, [tableFilters]);

    const clearFiltersHandler = (setSelectedKeys, confirm) => {
        setIsRangeChanged(false);
        setIconColor(null)
        setSelectedKeys([])
        setRangeDate([moment(new Date(), dateFormat), moment(new Date(), dateFormat)]);
        confirm({closeDropdown: true});
    }

    return {
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => {
            return (
                <Row id={dataIndex + '_range_date_dropdown'} style={{maxWidth: '300px', width: '100%', padding: '10px'}}>
                    <span style={{display: 'block', marginBottom: '10px'}}>Filtrera på datum</span>
                    <RangeDatePicker custom={{
                            customRange: rangeDate,
                            customIsRangeChanged: isRangeChanged,
                            isCustom: true,
                            customSetIsRangeChanged: setIsRangeChanged,
                            customSetRange: setRangeDate,
                        }}/>
                    <Row style={{marginTop: '10px'}}>
                        <Button style={{marginRight: '10px'}} type='primary'
                                onClick={() => filterHandler(setSelectedKeys, selectedKeys, confirm)}>Filtrera</Button>
                        <Button type='primary' ghost onClick={() => clearFiltersHandler(setSelectedKeys, confirm)}>Rensa
                            filter</Button>
                    </Row>
                </Row>
            )
        },
        filteredValue: isRangeChanged ? {
            [from]: renderDateYMD(rangeDate[0]._d),
            [to]: renderDateYMD(rangeDate[1]._d)
        } : null,
        filterIcon: () => {
            return <FilterFilled style={{...iconColor && {color: iconColor}}}/>
        },
    }
}

export default getRangeDateDropdown;
