import React, {createRef, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {SET_ORDER} from "../../../state/reducers/ordersReducer";
import useActions from "../../../hooks/useActions";
import {Button, Col, message, Popconfirm, Row, Spin} from "antd";
import {getColumns} from "./columns";
import {getActionTypes} from "../../../helpers/getActionTypes";
import OrderInfo from "../../../components/Order/OrderInfo/OrderInfo";
import OrderTable from "../../../components/Order/OrderTable";
import OrderCustomerDetails from "../../../components/Order/OrderCustomerDetails/OrderCustomerDetails";
import OrderNotesList from "../../../components/OrderNotes/OrderNotesList";

import './order-page.scss';

const OrderPage = () => {
    const {id} = useParams();
    const formRef = createRef();
    const [isEditing, setIsEditing] = useState(false);
    const {order, loading} = useSelector(state => state?.orders)
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [userRefundSum, setUserRefundSum] = useState(0);
    const [isCanRefund, setIsCanRefund] = useState(false);
    const {getItem, refundOrder, setItemNull} = useActions();

    useEffect(() => {
        getItem({
            id,
            ...getActionTypes('orders'),
        });

        return () => {
            setItemNull(SET_ORDER)
        }

    }, []);

    useEffect(() => {
        if (!isEditing || !isFormChanged || !formRef.current) return;
        const values = formRef.current.getFieldsValue();
        const reWorkedValues = []
        Object.keys(values).forEach((key, i) => {
            if (key.includes('total') && !key.includes('total_tax')) {
                const qty = values[Object.keys(values)[i - 1]];
                const totalTax = values[Object.keys(values)[i + 1]];
                reWorkedValues.push((values[key] * (qty === 0 ? 1 : qty)) + totalTax)
            }
        })

        const sum = reWorkedValues.reduce((prev, item) => prev + item, 0);

        if (sum === 0) {
            setIsCanRefund(false)
            message.warning('Summan kan ej vara noll')
            setUserRefundSum(+sum)
        }

        setIsFormChanged(false)
    }, [isEditing, formRef, isFormChanged, setIsFormChanged, setUserRefundSum])

    const onRefundClickHandler = () => {
        if (isCanRefund) {
            const formValues = formRef.current.getFieldsValue();
            const arrayOfValues = [];

            Object.keys(formValues).forEach((item, index) => {
                if (index % 2) {
                    arrayOfValues.push({
                        [item]: formValues[item],
                        [Object.keys(formValues)[index - 1]]: formValues[Object.keys(formValues)[index - 1]],
                        [Object.keys(formValues)[index + 1]]: formValues[Object.keys(formValues)[index + 1]],
                    })
                }
            })

            const values = {
                order_id: order.id,
                amount: userRefundSum,
                refund_order_items: []
            }

            arrayOfValues.forEach(value => {
                const id = Object.keys(value)[0].split('_id_')[1]
                const foundItem = order.order_items.find(item => +item.id === +id)
                if (foundItem) {
                    const quantity = +value[Object.keys(value)[1]] === 0 ? 1 : +value[Object.keys(value)[1]];
                    const total = +value[Object.keys(value)[0]] === 0 ? foundItem.total : +value[Object.keys(value)[0]];
                    const total_tax = +value[Object.keys(value)[2]] === 0 ? foundItem.total_tax : +value[Object.keys(value)[2]];

                    values.refund_order_items.push({
                        sku: foundItem.sku,
                        quantity,
                        total,
                        total_tax
                    })
                }
            })

            refundOrder({
                id,
                values,
                contentText: 'Ordern har återbetalats'
            })

            setIsCanRefund(false)
            formRef.current.resetFields();
            setIsEditing(false)
        } else {

        }
    }

    if (Object.keys(order).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <>
            <Row justify='space-between' align='center'>
                <h2>Order #{order.external_order_id}</h2>
            </Row>

            <br/>
            <Row justify='space-between'>
                <Col style={{flexGrow: '1', marginRight: '20px'}}>
                    {
                        <OrderCustomerDetails order={order}/>
                    }
                    {
                        order.site &&
                        <span style={{display: 'block', marginBottom: '20px'}}>Försäljningsställe: {order.site.name}</span>
                    }
                    <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                     <Row justify='space-between' align='center' style={{marginTop: '10px'}}>
                        <h2>Orderdetaljer</h2>
                    </Row>
                    <OrderTable ref={formRef} order={order} loading={loading} setIsFormChanged={setIsFormChanged}
                                columns={getColumns} isEditing={isEditing}/>
                    {
                        <>
                            <>
                                <OrderInfo order={order} isEditing={isEditing}/>
                                <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>

                            </>
                            {
                                order.status === 'completed' &&
                                <>
                                    {
                                        isEditing ?
                                            <>
                                                <Button onClick={() => setIsEditing(false)} type="primary" ghost
                                                        style={{marginRight: '20px'}}>Avbryt</Button>
                                                <Popconfirm title='Är du säker på att du vill återbetala detta belopp?'
                                                            placement='topRight' onConfirm={onRefundClickHandler}>
                                                    <Button type="primary">Återbetala</Button>
                                                </Popconfirm>
                                            </>
                                            : <Button onClick={() => setIsEditing(true)} type="primary"
                                                      ghost>Återbetala</Button>

                                    }
                                </>
                            }
                        </>
                    }
                </Col>
                <Col style={{minWidth: '300px', maxWidth: '300px'}}>
                    <OrderNotesList/>
                </Col>
            </Row>
        </>
    );
};

export default OrderPage;
