const initialState = {
    products: {
        objects: [],
        total: 0,
        page: 1,
    },
    product: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    productsTableFilters: {},
    productsTableSorting: {},
    productsTablePagination: {},
    loading: false,
}

export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCTS_LOADING = 'SET_PRODUCTS_LOADING';
export const SET_PRODUCTS_TABLE_FILTERS = 'SET_PRODUCTS_TABLE_FILTERS';
export const SET_PRODUCTS_TABLE_SORTING = 'SET_PRODUCTS_TABLE_SORTING';
export const SET_PRODUCTS_TABLE_PAGINATION = 'SET_PRODUCTS_TABLE_PAGINATION';
export const SET_PRODUCTS_PAGINATION = 'SET_PRODUCTS_PAGINATION';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCTS_LOADING:
            return {...state, loading: action.payload};
        case SET_PRODUCTS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_PRODUCTS:
            return {...state, products: action.payload, loading: false};
        case SET_PRODUCT:
            return {...state, product: {...action.payload}, loading: false};
        case UPDATE_PRODUCT:
            return {...state, product: {...action.payload}, loading: false};
        case DELETE_PRODUCT:
            return {...state, loading: false};
        case SET_PRODUCTS_TABLE_FILTERS:
            return {...state, productsTableFilters: action.payload};
        case SET_PRODUCTS_TABLE_SORTING:
            return {...state, productsTableSorting: action.payload};
        case SET_PRODUCTS_TABLE_PAGINATION:
            return {...state, productsTablePagination: action.payload};
        default:
            return state;
    }
}

export default productsReducer;
