import React, {useEffect} from 'react';
import {getColumns} from "../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../components/ant-table/dashboardColumns";
import AntTable from "../../components/AntTable/AntTable";
import {getActionTypes} from "../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../helpers/getUseSelectorFromType";
import {PlusCircleOutlined} from "@ant-design/icons";
import {Row} from "antd";

const ShippingServiceMappings = ({type = 'shipping_service_mappings', title = 'Frakttjänster'}) => {
    const {getItems, setshippingServiceMappingsFiltering, setshippingServiceMappingsSorting, setshippingServiceMappingsPagination} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const {id} = useParams();
    const location = useLocation();
    const {shippingServiceMappings, loading, pagination, shippingServiceMappingsTableSorting, shippingServiceMappingsTableFilters} = useSelector(state => state?.shippingServiceMappings);

    useEffect(() => {
        if (!location.pathname.includes('new') && !id) {
            getItems({
                params: {
                    ...(shippingServiceMappingsTableSorting && Object.keys(shippingServiceMappingsTableSorting).length > 0 && shippingServiceMappingsTableSorting?.order && {
                        order_by: shippingServiceMappingsTableSorting.field,
                        order_type: shippingServiceMappingsTableSorting.order.replace('end', '')
                    }),
                    ...{...(shippingServiceMappingsTableFilters && Object.keys(shippingServiceMappingsTableFilters).length > 0 && shippingServiceMappingsTableFilters)}
                },
                ...getActionTypes(type)
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        isRemovable:false,
    })

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <Row>
                            <Link to='new'>
                                <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                            </Link>
                        </Row>
                        <AntTable initialData={shippingServiceMappings} total={shippingServiceMappings?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setshippingServiceMappingsFiltering}
                                  setSorting={setshippingServiceMappingsSorting}
                                  setPagination={setshippingServiceMappingsPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                        />
                    </>
                }
            </>
    );
};

export default ShippingServiceMappings;
