import React, {useEffect, useState} from 'react';
import {getColumns} from "../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../components/ant-table/dashboardColumns";
import {Row, Button} from "antd";
import {PlusCircleOutlined, SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import AntTable from "../../components/AntTable/AntTable";
import BulkOperations from "../../components/BulkOperations/BulkOperations";
import {cleanPayload, getTextFromType} from "../../helpers/helpers";
import EditableRow from "../../components/Editable/EditableRow/EditableRow";
import EditableCell from "../../components/Editable/EditableCell/EditableCell";
import {getActionTypes} from "../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../helpers/getUseSelectorFromType";
import "./Products.scss";

const Products = ({type = 'products', title = 'Produkter'}) => {
    const {getItems, removeItem, updateItem, setProductsFilters,
        setProductsSorting, setProductsPagination} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const dashboardBulkFormElementsType = useSelectorType + 'BulkFormElements';
    const itemsType = type.includes('-') ? type.replaceAll('-', '_') : type;
    const {id} = useParams();
    const location = useLocation();
    const items = useSelector(state => state[useSelectorType] && state[useSelectorType][useSelectorType]);
    const {
        loading,
        pagination
    } = useSelector(state => state[useSelectorType] && state[useSelectorType]);
    const tableFiltersType = useSelectorType + 'TableFilters';
    const tableSortingType = useSelectorType + 'TableSorting';
    const tableFilters = useSelector(state => state[useSelectorType][tableFiltersType])
    const tableSorting = useSelector(state => state[useSelectorType][tableSortingType])
    const itemType = getTextFromType({string: itemsType, isRemoveSymbols: false, isRemoveLastAndAllToUppercase: true});
    const order = ['asc', 'desc', ''];
    const [sort, setSort] = useState('');
    const [sortingNumber, setSortingNumber] = useState(0);
    const [isFirstRender, setIsFirstRender] = useState(true);

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const clearSorting = () => {
        setSortingNumber(0)
        setSort('');
        setProductsSorting({})
    }

    useEffect(() => {
        if (!location.pathname.includes('new')) {
            getItems({
                params: {
                    ...{...((tableFilters && Object.keys(tableFilters).length > 0))},
                    ...(tableSorting && Object.keys(tableSorting).length > 0 && tableSorting?.order && {
                        order_by: tableSorting.field,
                        order_type: tableSorting.order.replace('end', '')
                    })
                },
                ...getActionTypes(type)
            });

        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        removing: (id) => {
            removeItem({
                id,
                ...getActionTypes(type),
                filters: tableFilters,
                contentText: `${getTextFromType({string: type})} med ID ${id} har raderats`
            })
        }
    })

    const handleSave = (values, isQuantity = false) => {
        const newValues = {...values};

        if (!newValues.sales_price) {
            newValues.sales_price = "";
        }

        // get stock object and update after
        if (isQuantity) {
            const {id, disposable_quantity} = values;
            getItems({
                params: {product_id: id},
                ...getActionTypes('stock_objects'),
                disposable_quantity,
                filters: tableFilters,
                sorting: tableSorting
            })
        } else {
            // update product
            delete newValues.disposable_quantity

            // removing site_ keys from values
            const siteIdsObj = Object.keys(newValues).filter(k => k.includes('site_'));
            siteIdsObj.forEach(item => {
                delete newValues[item];
            })

            console.log('newValues', newValues)

            updateItem({
                values: newValues,
                id: values.id,
                shouldUpdateItems: true,
                currentPage: pagination.current,
                params: {
                    page: pagination.current,
                    ...tableFilters
                },
                ...getActionTypes('products')
            })
        }
    }

    /**
     * For columns editing in table view
     */

    columns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        const {title, editable, dataIndex, options, type, site_id} = col;

        return {
            ...col,
            onCell: record => ({
                title,
                record,
                editable,
                dataIndex,
                options: options ?? [],
                inputType: type ?? 'text',
                handleSave,
                site_id
            }),
        };
    });

    const sortingHandler = () => {
        if (sortingNumber === 2) {
            setSortingNumber(-1)
        }
        setSort(order[sortingNumber])
        setSortingNumber(prev => prev + 1);
    }

    useEffect(() => {
        if (!isFirstRender) {
            setProductsSorting( sort ? {
                field: 'order_id',
                order: sort
            } : {})

            getItems({
                params: {
                    ...{...((tableFilters && Object.keys(tableFilters).length > 0) && {...tableFilters})},
                    ...(sort && {
                        order_by: 'order_id',
                        order_type: sort
                    })
                },
                ...getActionTypes(type)
            });
        }

        setIsFirstRender(false)

    }, [sort])

    return (
        <>
            {
                id || location.pathname.includes('/new') ? <Outlet/>
                    :
                    <>
                        <Row justify='space-between' style={{width: 'calc(100vw - 257px)'}}>
                            <h2 className='dashboard__content--title'>{title}</h2>
                        </Row>
                        {
                            <>
                                <Row className='filter-block' justify='space-between' align='middle'>
                                    <Row>
                                        <BulkOperations type={type} dashboardBulkFormElementsType={dashboardBulkFormElementsType} actionsTypes={getActionTypes(type)} itemType={itemType}/>
                                        <Row justify='space-between' align='middle'>
                                            <Button onClick={sortingHandler}>Sorting</Button>

                                            <span className='filter-sorting__item'>
                                                {
                                                    (sort === 'asc') || (sort === '') ?
                                                        <SortAscendingOutlined className={`filter-sorting__icon ${sort === 'asc' ? 'active' : ''}`}/>
                                                        : <SortDescendingOutlined className={`filter-sorting__icon ${sort === 'desc' ? 'active' : ''}`}/>
                                                }
                                            </span>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <Link to='new'>
                                            <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                                        </Link>
                                    </Row>
                                </Row>


                                <AntTable initialData={items} total={items?.total} loading={loading}
                                          pagination={pagination} columns={columns} type={itemsType}
                                          setFilters={setProductsFilters}
                                          setSorting={setProductsSorting}
                                          setPagination={setProductsPagination}
                                          getItemsDynamicData={getActionTypes(type)}
                                          components={components}
                                          clearSorting={clearSorting}
                                />
                            </>
                        }
                    </>
            }
        </>
    );
};

export default Products;
