import React, {useState, useEffect} from 'react';
import {Button, Form, Input, message, Row, Select, Spin} from 'antd';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import axios from "axios";
import {getApiUrl} from "../../helpers/helpers";

import {
    SET_Budbee_ACCOUNT, UPDATE_Budbee_ACCOUNT
} from "../../state/reducers/budbeeAccountReducer";


const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const EditBudbeeAccountForm = ({id}) => {
    const {updateItem, getItem} = useActions()
    const [budbeeAccount, setBudbeeAccount] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const navigate = useNavigate();
    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    useEffect(() => {
        getBudbeeAccount();
    }, []);

    const getBudbeeAccount = () => {
        getItem({
              type:'budbee_account',
              setItemActionType: 'SET_BUDBEE_ACCOUNT'
        });
    }

    const finishHandler = (values) => {
        updateItem({
            values,
            id,
            ...getActionTypes('budbee_account'),
            contentText: `Du har uppdaterat ditt Budbee konto`
        });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/');
    };
    console.log(budbeeAccount);

    const renderEditBudbeeAccount = () => {
        if (!budbeeAccount) {
            return (
                <Form {...layout}
                  name="edit-budbee_account"
                  layout="vertical"
                  onFinish={finishHandler}
                  onValuesChange={valuesChangeHandler}>
                <h2>Budbee Konto</h2>
                <Form.Item
                    name='api_key'
                    label="API Key"
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='api_secret'
                    label="API Key"
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='collection_id'
                    label="Collection ID"
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <div style={{borderBottom: '1px solid #d9d9d9', marginBottom: '10px', marginTop: '10px'}}></div>
                <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                    <Row justify="space-between">
                        {
                            isChanged ?
                                <Button type="primary" htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
            );
        }

        return (
            <Form {...layout}
                  name="edit-budbee_account"
                  layout="vertical"
                  onFinish={finishHandler}
                  onValuesChange={valuesChangeHandler}>
                <h2>Budbee Konto</h2>
                <Form.Item
                    name='api_key'
                    label="API Key"
                    initialValue={budbeeAccount.api_key}
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='api_secret'
                    label="API Secret"
                    initialValue={budbeeAccount.api_secret}
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='collection_id'
                    label="Collection ID"
                    initialValue={budbeeAccount.collection_id}
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <div style={{borderBottom: '1px solid #d9d9d9', marginBottom: '10px', marginTop: '10px'}}></div>
                <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                    <Row justify="space-between">
                        {
                            isChanged ?
                                <Button type="primary" htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
        )
    };
    return (
        renderEditBudbeeAccount()
    );
};

export default EditBudbeeAccountForm;
