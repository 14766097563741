import React, {createRef, useEffect} from 'react';
import {Form} from "antd";
import getComponentByType from "../../../../helpers/getComponentByType";
import {useSelector} from "react-redux";
import useActions from "../../../../hooks/useActions";

const BulkEditForm = ({formElements, formChangeHandler, setIsChanged}) => {
    const {setBulkFormRef, setBulkFormRefFound} = useActions();
    const formRef = createRef();
    const {refFound} = useSelector(state => state?.bulkOperations);

    useEffect(() => {
        if (!formRef.current || refFound) return;
        setBulkFormRef(formRef.current)
        setBulkFormRefFound(true)
    }, [formRef, refFound])

    return (
        <Form ref={formRef}
              name='bulk-edit'
              onValuesChange={formChangeHandler}>
            {
                formElements && formElements.length > 0
                && formElements.map(value => {
                    const isFormItem = false;
                    return (
                        <Form.Item
                            key={value.name + Math.random()}
                            name={value.name}
                            {...(value.type === 'checkbox' && {valuePropName: 'checked'})}
                            label={value.label}
                            rules={[
                                {
                                    required: value.required,
                                },
                            ]}
                        >
                            {getComponentByType({value, formRef, setIsChanged, isFormItem})}
                        </Form.Item>
                    )
                })
            }
        </Form>
    )
}

export default BulkEditForm;
