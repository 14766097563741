
const initialState = {
    loading: false,
    registerValues: {},
    isNewPassFormVisible: false,
}

export const SET_REGISTER_VALUES = 'SET_REGISTER_VALUES';
export const SET_REGISTER_LOADING = 'SET_REGISTER_LOADING';

const registerReducer = (state= initialState, action) => {
    switch (action.type) {
        case SET_REGISTER_VALUES:
            return {...state, registerValues: action.payload}
        case SET_REGISTER_LOADING:
            return {...state, loading: action.payload}
        default:
            return state
    }
}

export default registerReducer
