
import {callApi, getApiUrl} from "./helpers";
import {SET_AUTH} from "../state/reducers/authReducer";
import {SET_USER} from "../state/reducers/loginReducer";
import {message} from "antd";


export const loginUser = async ({email, password}) => {

    return new Promise(function (resolve, reject) {
        callApi({
            url:  `${getApiUrl('users/login/')}users/login/`,
            method: 'post',
            values: {email: email, password: password},
            successCallback: function (response) {
                resolve(response.data);
            },
            errorCallback: function (error) {
                reject(error);
            }
        })
    })
};

export const registerUser = async ({email, password}) => {

    return new Promise(function (resolve, reject) {
        callApi({
            url:  `${getApiUrl('users/register/')}users/register/`,
            method: 'post',
            values: {email: email, password: password},
            successCallback: function (response) {
                resolve(response.data);
            },
            errorCallback: function (error) {
                reject(error);
            }
        })
    })
};

export const confirmUser = async ({email, verification_code, password}) => {

    return new Promise(function (resolve, reject) {
        callApi({
            url:  `${getApiUrl('users/confirm_user/')}users/confirm_user/`,
            method: 'post',
            values: {email: email, password: password, verification_code: verification_code},
            successCallback: function (response) {
                resolve(response.data);
            },
            errorCallback: function (error) {
                reject(error);
            }
        })
    })
};

export const setSessionData = ({ accessToken, refreshToken, userId, tokenExpire, siteId, dispatch=null }) => {

    if (dispatch){
        dispatch({type: SET_AUTH, payload: true})
        dispatch({type: SET_USER, payload: userId ?? null})
    }

    localStorage.setItem('userId', JSON.stringify(userId));
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('tokenExpire', tokenExpire);
    localStorage.setItem('siteId', siteId);
    message.success('Inloggning lyckad');
};


export const refreshSession = async () => {
    return new Promise(function (resolve, reject) {
        const refreshToken = localStorage.getItem('refreshToken');
        callApi({
                url:  `${getApiUrl('users/refresh_token/')}users/refresh_token/`,
                method: 'post',
                values: {refresh_token: refreshToken},
                successCallback: function (response) {
                    setSessionData({
                        accessToken: response.data.access_token,
                        refreshToken: response.data.refresh_token,
                        tokenExpire: response.data.token_expire,
                        userId: response.data.user_id,
                        siteId: response.data.site_id
                    })
                    resolve(response.data);
                },
                errorCallback: function (error) {
                    reject(error);
                }
        })
    })
}
