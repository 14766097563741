import React, {useState} from 'react';
import {Button, Form, Input, Row} from "antd";
import {KeyOutlined, UserOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import NewPasswordForm from "../../components/NewPasswordForm/NewPasswordForm";
import useActions from "../../hooks/useActions";

const Register = () => {
    const {register} = useActions()
    const [isChanged, setIsChanged] = useState(false);
    const {loading, isNewPassFormVisible} = useSelector(state => state?.login)

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 18,
        },
    };

    const finishHandler = (values) => {
        if (!isChanged) return;
        register(values);
        setIsChanged(false);
    }

    const valuesChangeHandler = (setIsChanged) => {
        setIsChanged(true);
    }

    const validateMessages = {
        required: '${label} krävs!',
        types: {
            email: '${label} är inte en giltig e-post!',
            number: '${label} är inte ett giltigt nummer!',
        },
    };

    return (
        <div className='container login-page'>
            <h2 style={{textAlign: 'center', maxWidth: '430px', width: '100%', fontSize: '20px'}}>Välkommen till Wetail Shipping</h2>
            <p style={{textAlign: 'center', maxWidth: '430px', width: '100%', fontSize: '14px', color: '#898989'}}>Fyll i formuläret för att registrera en användare</p>

            <Form {...layout}
                  className='login-form'
                  name="login"
                  onFinish={finishHandler}
                  onChange={() => valuesChangeHandler(setIsChanged)}
                  validateMessages={validateMessages}>
                <Form.Item
                    name='email'
                    label="Användarnamn"
                    labelCol={{ span: 24 }}
                    wrapperCol={{span: 24}}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined />}/>
                </Form.Item>

                <Form.Item
                    name='password'
                    label="Lösenord"
                    autoComplete='off'
                    labelCol={{ span: 24 }}
                    wrapperCol={{span: 24}}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type='password' width='100%' prefix={<KeyOutlined />}/>
                </Form.Item>
                <Form.Item
                    wrapperCol={{span: 24}}
                >
                    <Row justify='space-between' align='middle'>
                        <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                            Registrera
                        </Button>
                    </Row>
                </Form.Item>
                <div className='stage_name' align='right'>
                    -{process.env.NAME}-
                </div>
            </Form>
        </div>
    );
};

export default Register;
