const initialState = {
    postnordAccount: {},
    loading: false,
}

export const SET_POSTNORD_ACCOUNT = 'SET_POSTNORD_ACCOUNT';
export const SET_POSTNORD_ACCOUNTS = 'SET_POSTNORD_ACCOUNTS';
export const SET_POSTNORD_ACCOUNTS_LOADING = 'SET_POSTNORD_ACCOUNTS_LOADING';
export const SET_POSTNORD_ACCOUNTS_PAGINATION = 'SET_PAGINATION';
export const UPDATE_POSTNORD_ACCOUNT = 'UPDATE_POSTNORD_ACCOUNT';
export const DELETE_POSTNORD_ACCOUNT = 'DELETE_POSTNORD_ACCOUNT';

const postnordAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POSTNORD_ACCOUNTS_LOADING:
            return {...state, loading: action.payload};
        case SET_POSTNORD_ACCOUNTS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_POSTNORD_ACCOUNTS:
            return {...state, sites: action.payload, loading: false};
        case SET_POSTNORD_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case UPDATE_POSTNORD_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case DELETE_POSTNORD_ACCOUNT:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default postnordAccountReducer;
