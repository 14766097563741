const initialState = {
    dhlAccount: {},
    loading: false,
}

export const SET_ONGOING_ACCOUNT = 'SET_ONGOING_ACCOUNT';
export const SET_ONGOING_ACCOUNTS = 'SET_ONGOING_ACCOUNTS';
export const SET_ONGOING_ACCOUNTS_LOADING = 'SET_ONGOING_ACCOUNTS_LOADING';
export const SET_ONGOING_ACCOUNTS_PAGINATION = 'SET_PAGINATION';
export const UPDATE_ONGOING_ACCOUNT = 'UPDATE_ONGOING_ACCOUNT';
export const DELETE_ONGOING_ACCOUNT = 'DELETE_ONGOING_ACCOUNT';

const ongoingAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ONGOING_ACCOUNTS_LOADING:
            return {...state, loading: action.payload};
        case SET_ONGOING_ACCOUNTS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_ONGOING_ACCOUNTS:
            return {...state, sites: action.payload, loading: false};
        case SET_ONGOING_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case UPDATE_ONGOING_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case DELETE_ONGOING_ACCOUNT:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default ongoingAccountReducer;
