const initialState = {
    userCarriers: {
        objects: [],
        total: 0,
        page: 1,
    },
    shippingService: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    userCarriersTableFilters: {},
    userCarriersTableSorting: {},
    userCarriersTablePagination: {},
    loading: false,
}

export const SET_USER_CARRIERS = 'SET_USER_CARRIERS';
export const SET_USER_CARRIER = 'SET_USER_CARRIER';
export const SET_USER_CARRIERS_LOADING = 'SET_USER_CARRIERS_LOADING';
export const SET_USER_CARRIERS_PAGINATION = 'SET_USER_CARRIERS_PAGINATION';
export const ADD_USER_CARRIER = 'ADD_USER_CARRIER';
export const SET_USER_CARRIERS_TABLE_FILTERS = 'SET_USER_CARRIERS_TABLE_FILTERS';
export const SET_USER_CARRIERS_TABLE_SORTING = 'SET_USER_CARRIERS_TABLE_SORTING';
export const SET_USER_CARRIERS_TABLE_PAGINATION = 'SET_USER_CARRIERS_TABLE_PAGINATION';

const userCarriersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_CARRIERS_LOADING:
            return {...state, loading: action.payload};
        case SET_USER_CARRIERS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_USER_CARRIERS:
            return {...state, userCarriers: action.payload, loading: false};
        case SET_USER_CARRIER:
            return {...state, shippingService: {...action.payload}, loading: false};
        case SET_USER_CARRIERS_TABLE_FILTERS:
            return {...state, userCarriersTableFilters: action.payload};
        case SET_USER_CARRIERS_TABLE_SORTING:
            return {...state, userCarriersTableSorting: action.payload};
        case SET_USER_CARRIERS_TABLE_PAGINATION:
            return {...state, userCarriersTablePagination: action.payload};
        default:
            return state;
    }
}

export default userCarriersReducer
