const initialState = {
    synchronizationJobs: {
        objects: [],
        total: 0,
        page: 1,
    },
    synchronizationJob: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    synchronizationJobsTableFilters: {},
    synchronizationJobsTableSorting: {},
    synchronizationJobsTablePagination: {},
    loading: false,
}
export const ADD_SYNCHRONIZATION_JOB = 'ADD_SYNCHRONIZATION_JOB';
export const SET_SYNCHRONIZATION_JOBS = 'SET_SYNCHRONIZATION_JOBS';
export const SET_SYNCHRONIZATION_JOB = 'SET_SYNCHRONIZATION_JOB';
export const SET_SYNCHRONIZATION_JOBS_LOADING = 'SET_SYNCHRONIZATION_JOBS_LOADING';
export const SET_SYNCHRONIZATION_JOBS_PAGINATION = 'SET_SYNCHRONIZATION_JOBS_PAGINATION';
export const UPDATE_SYNCHRONIZATION_JOB = 'UPDATE_SYNCHRONIZATION_JOB';
export const SET_SYNCHRONIZATION_JOBS_TABLE_FILTERS = 'SET_SYNCHRONIZATION_JOBS_TABLE_FILTERS';
export const SET_SYNCHRONIZATION_JOBS_TABLE_SORTING = 'SET_SYNCHRONIZATION_JOBS_TABLE_SORTING';
export const SET_SYNCHRONIZATION_JOBS_TABLE_PAGINATION = 'SET_SYNCHRONIZATION_JOBS_TABLE_PAGINATION';

const synchronizationJobsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SYNCHRONIZATION_JOBS_LOADING:
            return {...state, loading: action.payload};
        case SET_SYNCHRONIZATION_JOBS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_SYNCHRONIZATION_JOBS:
            return {...state, synchronizationJobs: action.payload, loading: false};
        case SET_SYNCHRONIZATION_JOB:
            return {...state, synchronizationJob: {...action.payload}, loading: false};
        case UPDATE_SYNCHRONIZATION_JOB:
            return {...state, synchronizationJob: {...action.payload}, loading: false};
        case SET_SYNCHRONIZATION_JOBS_TABLE_FILTERS:
            return {...state, synchronizationJobsTableFilters: action.payload};
        case SET_SYNCHRONIZATION_JOBS_TABLE_SORTING:
            return {...state, synchronizationJobsTableSorting: action.payload};
        case SET_SYNCHRONIZATION_JOBS_TABLE_PAGINATION:
            return {...state, synchronizationJobsTablePagination: action.payload};
        default:
            return state;
    }
}

export default synchronizationJobsReducer
