import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, Row, Select, Col} from 'antd';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {QuestionCircleOutlined} from "@ant-design/icons";
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import {validateVatNumber,validateZipCode} from "../../helpers/validators";
import "./EditSenderForm.scss";

import {
    SET_SENDER, ADD_SENDER
} from "../../state/reducers/sendersReducer";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const AddSenderForm = ({id}) => {
    const {addItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const [showText, setShowText] = useState(false);
    const {loading} = useSelector(state => state?.senders);
    const formRef = createRef();
    const {Option} = Select;
    const navigate = useNavigate();


    useEffect(() => {
        return () => {
            setItemNull(SET_SENDER);
        };
    }, []);

    const handleClick = () => {
        setShowText(!showText);
    };

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };



    const finishHandler = (values) => {
        const isValid = validateZipCode(values.country, values.zipcode.toString());
        addItem({
            values: values,
            ...getActionTypes('senders'),
            ADD_SENDER
        });

        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/senders');
    };

    return (
        <>
            <QuestionCircleOutlined  style={{fontSize: '25px', color: '#1890ff'}} onClick={handleClick}/>
            {showText && <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>}

            <Form {...layout}
                  ref={formRef}
                  data-testid='add-sender-form'
                  name="add-sender-mapping"
                  onFinish={finishHandler}
                  initialValues={{}}
                  style={{width: '100%'}}
                  onValuesChange={valuesChangeHandler}
                  validateMessages={validateMessages}>

                <h2>Ny Avsändare</h2>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item
                            name='name'
                            label="Namn"
                            wrapperCol={{ span: 4 }}
                            rules={[{required: true}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <h3>Adress</h3>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item
                            name='address_1'
                            label="Adress"
                            rules={[{required: true}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='address_2'
                            label="Annan adress"
                            rules={[{required: false}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            name='zipcode'
                            label="Postnummer"
                            rules={[{ required: true, message: 'Please enter a Zipcode' },]}
                        >
                            <InputNumber/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='city'
                            label="Ort"
                            rules={[{required: true}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='country'
                            label="Land"
                            rules={[{required: true}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='state'
                            label="Stat"
                            rules={[{required: false}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <h3>Företag</h3>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            name='company_name'
                            label="Företagsnamn"
                            rules={[{required: true}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='contact_person'
                            label="Kontaktperson"
                            rules={[{required: true}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='phone_number'
                            label="Telefonnummer"
                            rules={[{required: true}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='email'
                            label="Email"
                            rules={[{required: true}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='vat_number'
                            label="VAT nummer"
                            rules={[
                                { required: true, message: 'Please enter a VAT number' },
                                {
                                  validator: (_, value) =>
                                    validateVatNumber(value)
                                      ? Promise.resolve()
                                      : Promise.reject('Invalid VAT number')
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                <Form.Item>
                    <Row>
                        {
                            isChanged ?
                                <Button style={{marginRight: '20px'}} type="primary" loading={loading}
                                        htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
};

export default AddSenderForm;
