import React from 'react';
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();
    const backHomeClickHandler = () => {
        navigate('/');
    }

    return (
        <Result
            status="404"
            title="404"
            subTitle="Tyvärr, sidan du försöker nå finns inte."
            extra={<Button onClick={backHomeClickHandler} type="primary">Tillbaka hem</Button>}
        />
    );
};

export default NotFound;
