import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, Row, Select} from 'antd';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import "./EditSynchronizationJobForm.scss";

import {
    SET_SYNCHRONIZATION_JOB, ADD_SYNCHRONIZATION_JOB
} from "../../state/reducers/synchronizationJobsReducer";
import SiteSelect from "../../components/SiteSelect";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const AddSynchronizationJobForm = ({id}) => {
    const {addItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const [showText, setShowText] = useState(false);
    const [selectedSlaveSystem, setSelectedSlaveSystem] = useState(null);
    const [selectedSyncFrequency, setSelectedSyncFrequency] = useState(null);
    const [selectedJobType, setSelectedJobType] = useState(null);
    const {loading} = useSelector(state => state?.senders);
    const formRef = createRef();
    const navigate = useNavigate();

    const slaveSystemOptions = [
      { value: 1, label: 'WooCommerce' }
    ];

    const syncFrequencyOptions = [
      { value: 'every_2_minutes', label: 'Varannan minut' },
      { value: 'daily', label: 'Dagligen' }
    ];

    const jobTypeOptions = [
      { value: 'sync_products', label: 'Produkter' },
      { value: 'sync_orders', label: 'Ordrar' }
    ];

    const handleSelectSlaveSystem = (selectedOption) => {
        setSelectedSlaveSystem(selectedOption);
    };
    const handleSelectSyncFrequency = (selectedOption) => {
        setSelectedSyncFrequency(selectedOption);
    };
    const handleSelectJobType = (selectedOption) => {
        setSelectedJobType(selectedOption);
    };

    useEffect(() => {
        return () => {
            setItemNull(SET_SYNCHRONIZATION_JOB);
        };
    }, []);

    const handleClick = () => {
        setShowText(!showText);
    };

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };


    const finishHandler = (values) => {
        addItem({
            values: values,
            ...getActionTypes('synchronization_jobs'),
            ADD_SYNCHRONIZATION_JOB
        });

        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/synchronization_jobs');
    };

    return (
        <>
            <Form {...layout}
                  ref={formRef}
                  data-testid='add-synchronization-job-form'
                  name="add-synchronization-job"
                  onFinish={finishHandler}
                  initialValues={{}}
                  style={{width: '100%'}}
                  onValuesChange={valuesChangeHandler}
                  validateMessages={validateMessages}>

                <Form.Item label="Site" rules={[{required: true}]}>
                    <SiteSelect name={'site_id'}/>
                </Form.Item>
                <Form.Item
                    label="Slave System"
                    name="slave_system_id"
                    rules={[{required: true}]}>
                    <Select
                        onChange={handleSelectSlaveSystem}
                        options={slaveSystemOptions}
                    />
                </Form.Item>
                <Form.Item
                    label="Synkronisationstyp"
                    name="synchronization_type"
                    rules={[{required: true}]}>
                    <Select
                        onChange={handleSelectJobType}
                        options={jobTypeOptions}
                    />
                </Form.Item>
                <Form.Item
                    label="Synk frekvens"
                    name="sync_frequency"
                    rules={[{required: true}]}>
                    <Select
                        onChange={handleSelectSyncFrequency}
                        options={syncFrequencyOptions}
                    />
                </Form.Item>
                <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                <Form.Item>
                    <Row>
                        {
                            isChanged ?
                                <Button style={{marginRight: '20px'}} type="primary" loading={loading}
                                        htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
};

export default AddSynchronizationJobForm;
