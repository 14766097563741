import {bulkOperationsAction} from "./bulkOperationsAction";
import {printOperationsAction} from "./printOperationsAction";
import {loginActionCreator} from "./loginActions";
import {registerActionCreator} from "./registerActions";
import {logoutActionsCreator} from "./logoutActions";
import {apiActions} from "./apiActions";
import {productAttributesActionCreator} from "./productAttributesAction";
import {shipmentActions} from "./shipmentActions";
import {picklistActions} from "./picklistActions";
import {rangeDateActions} from "./rangeDateActions";
import {tableFiltersActions} from "./tableFiltersActions";
import {productsActions} from "./productsActions";
import {attributesActions} from "./attributesActions";
import {shippingServicesActions} from "./shippingServicesActions";
import {notificationActions} from "./notificationActions";
import {senderActions} from "./senderActions";
import {confirmUserActionCreator} from "./confirmUserActions";

export const allActionCreators = {
    ...productsActions,
    ...notificationActions,
    ...senderActions,
    ...attributesActions,
    ...shippingServicesActions,
    ...bulkOperationsAction,
    ...printOperationsAction,
    ...loginActionCreator,
    ...logoutActionsCreator,
    ...apiActions,
    ...shipmentActions,
    ...productAttributesActionCreator,
    ...picklistActions,
    ...rangeDateActions,
    ...tableFiltersActions,
    ...registerActionCreator,
    ...confirmUserActionCreator,
}
