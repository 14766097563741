const initialState = {
    shipments: {
        objects: [],
        total: 0,
        page: 1,
    },
    shipment: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    shipmentsTableFilters: {},
    shipmentsTableSorting: {},
    shipmentsTablePagination: {},
    loading: false,
}

export const SET_SHIPMENTS = 'SET_SHIPMENTS';
export const SET_SHIPMENT = 'SET_SHIPMENT';
export const SET_SHIPMENTS_LOADING = 'SET_SHIPMENTS_LOADING';
export const SET_SHIPMENTS_PAGINATION = 'SET_SHIPMENTS_PAGINATION';
export const UPDATE_SHIPMENT = 'UPDATE_SHIPPING_SERVICE';
export const SET_SHIPMENTS_TABLE_FILTERS = 'SET_SHIPMENTS_TABLE_FILTERS';
export const SET_SHIPMENTS_TABLE_SORTING = 'SET_SHIPMENTS_TABLE_SORTING';
export const SET_SHIPMENTS_TABLE_PAGINATION = 'SET_SHIPMENTS_TABLE_PAGINATION';

const shipmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIPMENTS_LOADING:
            return {...state, loading: action.payload};
        case SET_SHIPMENTS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_SHIPMENTS:
            return {...state, shipments: action.payload, loading: false};
        case SET_SHIPMENT:
            return {...state, shipment: {...action.payload}, loading: false};
        case UPDATE_SHIPMENT:
            return {...state, shipment: {...action.payload}, loading: false};
        case SET_SHIPMENTS_TABLE_FILTERS:
            return {...state, shipmentsTableFilters: action.payload};
        case SET_SHIPMENTS_TABLE_SORTING:
            return {...state, shipmentsTableSorting: action.payload};
        case SET_SHIPMENTS_TABLE_PAGINATION:
            return {...state, shipmentsTablePagination: action.payload};
        default:
            return state;
    }
}

export default shipmentsReducer
