const initialState = {
    shippingLabels: {
        objects: [],
        total: 0,
        page: 1,
    },
    shippingLabel: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    shippingLabelsTableFilters: {},
    shippingLabelsTableSorting: {},
    shippingLabelsTablePagination: {},
    loading: false,
}

export const SET_SHIPPING_LABELS = 'SET_SHIPPING_LABELS';
export const SET_SHIPPING_LABEL = 'SET_SHIPPING_LABEL';
export const SET_SHIPPING_LABELS_LOADING = 'SET_SHIPPING_LABELS_LOADING';
export const SET_SHIPPING_LABELS_PAGINATION = 'SET_SHIPPING_LABELS_PAGINATION';
export const SET_SHIPPING_LABELS_TABLE_FILTERS = 'SET_SHIPPING_LABELS_TABLE_FILTERS';
export const SET_SHIPPING_LABELS_TABLE_SORTING = 'SET_SHIPPING_LABELS_TABLE_SORTING';
export const SET_SHIPPING_LABELS_TABLE_PAGINATION = 'SET_SHIPPING_LABELS_TABLE_PAGINATION';

const shippingLabelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIPPING_LABELS_LOADING:
            return {...state, loading: action.payload};
        case SET_SHIPPING_LABELS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_SHIPPING_LABELS:
            return {...state, shippingLabels: action.payload, loading: false};
        case SET_SHIPPING_LABEL:
            console.log(SET_SHIPPING_LABEL)
            return {...state, shippingLabel: {...action.payload}, loading: false};
        case SET_SHIPPING_LABELS_TABLE_FILTERS:
            return {...state, shippingLabelsTableFilters: action.payload};
        case SET_SHIPPING_LABELS_TABLE_SORTING:
            return {...state, shippingLabelsTableSorting: action.payload};
        case SET_SHIPPING_LABELS_TABLE_PAGINATION:
            return {...state, shippingLabelsTablePagination: action.payload};
        default:
            return state;
    }
}

export default shippingLabelsReducer
