import React from 'react';
import {Link } from 'react-router-dom';
import {Button, message, Popconfirm, Modal} from 'antd';
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import useActions from "../../hooks/useActions";
import {useState, useEffect, useRef} from 'react';
import axios from "axios";
import {getApiUrl} from "../../helpers/helpers";

const Header = () => {
    const {logout, customApiGet} = useActions();
    const {userId} = useSelector(state => state?.login)
    const [shippingLabelCount, setShippingLabelCount] = useState(0); // initialize state variable for shipping label count
    const [shippingLabelIds, setShippingLabelIds] = useState(0); // initialize state variable for shipping label count
    const [pdfUrl, setPdfUrl] = useState(null);
    const [loading, setLoading] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);

    const logoutHandler = () => {
        console.log("logging out " + userId)
        if (userId) {
            logout(false, userId)
        }
    }

    const getNewShippingLabels = () => {
        setLoading(true);
        const datetime = '2023-04-16 06:00:00'
        customApiGet({
            url: `${getApiUrl('shipping_labels')}shipping_labels/?datetime_from=` + encodeURIComponent(datetime), //TODO
            successCallback: function (response) {
                const count = response.data.total;
                const ids = response.data?.objects.map(item => item.id); // use map to extract the id values from each object in the response data
                setShippingLabelIds(ids);
                setShippingLabelCount(count); // update the state variable with the count
                setLoading(false);
            },
            errorCallback: function (error) {
                message.error(error?.data?.message)
            },
        })
    }

    const renderPdfs = () => {
        const idString = shippingLabelIds.join(',');
        axios.get(`${getApiUrl('shipping_labels')}shipping_labels/pdf/?shipping_label_ids=` + encodeURIComponent(idString),  { //TODO run customApiGet instead
            responseType: "arraybuffer",
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            }
        ).then(res => {
            const blob = new Blob([res.data], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
            setModalVisible(true);
        }).catch(err => {
            message.error(err?.data?.message)
        })
    }

    useEffect(() => {
        getNewShippingLabels();
    }, []);

    const handleModalCancel = () => {
        // Hide the modal
        setModalVisible(false);

        // Clear the PDF URL
        setPdfUrl('');
    };

    return (
        <header className='header'>
            <div className="logo">
                <Link to='/'>Startsida</Link>
            </div>
            <Modal
                visible={modalVisible}
                onCancel={handleModalCancel}
                footer={null}
                width="80%"
              >
                <embed src={pdfUrl} type="application/pdf" width="100%" height="500px" />
            </Modal>
            <div>
                {shippingLabelCount > 0 &&
                    <Button loading={loading} type="primary" htmlType="submit" onClick={renderPdfs}>
                        {`Skriv ut (${shippingLabelCount}) ${
                            shippingLabelCount === 1 ? 'ny fraktsedel' : 'nya fraktsedlar'
                          }`}
                    </Button>
                }
                <Link to='/user' style={{marginRight: '15px'}}>
                    <UserOutlined />
                </Link>
                <Popconfirm
                    title="Vill du logga ut?"
                    onConfirm={logoutHandler}
                    okText="Ja"
                    placement='left'
                    cancelText="Nej"
                >
                    <LogoutOutlined/>
                </Popconfirm>
            </div>
        </header>
    );
};

export default Header;
