import React, { createRef, useEffect, useState, useRef, useContext } from 'react';
import {Row, Select, Input, Form, Collapse, Checkbox, Button, Table, message, InputNumber, Col} from 'antd';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import {PlusCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import ShippingServiceSelect from "../../components/ShippingServiceSelect";
import SenderSelect from "../../components/SenderSelect";
import axios from "axios";
import {customsInvoiceTypeOptions, getApiUrl, paymentTermsOptions, typeOptions} from "../../helpers/helpers";
import ParcelTypeSelect from "../../components/ParcelTypeSelect";
import CollapsePanel from "../../components/Misc/CollapsePanel";
import CountrySelect from "../../components/CountrySelect/CountrySelect";
const { Panel } = Collapse;
import { useForm } from 'antd/lib/form/Form';
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const AddShipmentForm = ({}) => {
    const {addItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const formRef = createRef();
    const [form] = useForm();
    const tableRef = useRef(null);
    const navigate = useNavigate();
    const [showText, setShowText] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [simpleAdditionalServices, setSimpleAdditionalServices] = useState(false);
    const [advancedAdditionalServices, setAdvancedAdditionalServices] = useState(false);
    const [selectedPaymentTerms, setSelectedPaymentTerms] = useState(null);
    const [selectedSender, setSelectedSender] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedCustomsInvoiceType, setSelectedCustomsInvoiceType] = useState(null);
    const [selectedPackageType] = useState(null);
    const [shippingService, setShippingService] = useState(null);
    const [showCustomsPanel, setShowCustomsPanel] = useState(null);
    const [forceUpdateTrigger, setForceUpdateTrigger] = useState(0);

    const handleClick = () => {
        setShowText(!showText);
    };

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };


    const handleShippingServiceChange = (value) => {
        setShippingService(value);
        axios.get(`${getApiUrl('additional_services')}additional_services/${value}/`, {
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            }
        ).then(res => {
            setSimpleAdditionalServices(res.data.simple);
            setAdvancedAdditionalServices(res.data.advanced);
        }).catch(err => {
            message.error(err?.data?.message)
        })
    }

    const handleSelectSender = (selectedOption) => {
        console.log(selectedOption)
        setSelectedSender(selectedOption);
    };
    const handleSelectPaymentTerms = (selectedOption) => {
        setSelectedPaymentTerms(selectedOption);
    };

    const handleSelectChangeCustomsInvoiceType = (selectedOption) => {
        setSelectedCustomsInvoiceType(selectedOption);
    };

    const handleSelectChangeType = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const renderService = (service) => {
        console.log(service);

        let key, name;

        if ("additional_service_key" in service) {
            key = service.additional_service_key;
            name = service.additional_service_name;
        } else {
            key = service.additional_service_code;
            name = service.name;
        }

        if (service.type === 'Boolean') {
            return (
                <Form.Item key={key} name={'additional_service_' + key} valuePropName="checked" noStyle>
                    <Checkbox>{name}</Checkbox>
                </Form.Item>
            );
        } else {
            return (
                <Form.Item key={key} name={'additional_service_' + key} noStyle>
                    <InputNumber placeholder={`Enter ${name}`} />
                </Form.Item>
            );
        }
    };

    const renderSimpleAdditionalServices = () => {
        return simpleAdditionalServices && simpleAdditionalServices.map(renderService);
    };

    const renderAdvancedAdditionalServices = () => {
        return advancedAdditionalServices && advancedAdditionalServices.map(renderService);
    };


    const validateForm = (values) => {
        return true;
    }

    const finishHandler = (values) => {
        if (!validateForm(values)) {
            navigate('/shipments');
            return;
        }
        values['order_items'] = tableRef.current.state.dataSource;
        addItem({
          values: values,
          ...getActionTypes('shipments'),
        });

        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/shipments');
    };

    const handleCountryChange = (value) => {
        if( value !== 'SE' ){
            setShowCustomsPanel(true);
            setForceUpdateTrigger((prevTrigger) => prevTrigger + 1);
            tableRef.current.forceUpdateTable();
        }
        else{
            setShowCustomsPanel(false);
            setForceUpdateTrigger((prevTrigger) => prevTrigger + 1);
            tableRef.current.forceUpdateTable();
        }
    }

    return (
        <>
            <QuestionCircleOutlined  style={{fontSize: '25px', color: '#1890ff'}} onClick={handleClick}/>
            {showText && <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>}

            <Form {...layout}
                ref={formRef}
                data-testid='add-shipping-service-mapping-form'
                name="add-shipping-service-mapping"
                onFinish={finishHandler}
                initialValues={{}}
                style={{width: '100%'}}
                onValuesChange={valuesChangeHandler}>
                <Collapse
                    defaultActiveKey="1"
                    borderRadiusLG="5"
                    colorBorder="#f7f7f7"
                >
                    <Panel header={<CollapsePanel icon={<PlusCircleOutlined />} text="Mottagare" />} key="1" >
                        <Row gutter={12}>
                            <Col span={6}>
                                <Form.Item
                                    label="Förnamn"
                                    name="receiver_first_name"
                                    rules={[{
                                        required: true,
                                        message: 'Vänligen välj mottagarens förnamn'
                                    }]}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Efternamn"
                                    name="receiver_last_name"
                                    rules={[{
                                        required: true,
                                        message: 'Vänligen välj mottagarens efternamn'
                                    }]}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={6}>
                                <Form.Item
                                    label="Adress"
                                    name="receiver_address_1"
                                    rules={[{
                                        required: true,
                                        message: 'Vänligen välj motagarens adress'
                                    }]}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Postnummer"
                                    name="receiver_zipcode"
                                    rules={[{
                                        required: true,
                                        message: 'Vänligen välj motagarens postnummer'
                                    }]}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Ort"
                                    name="receiver_city"
                                    rules={[{
                                        required: true,
                                        message: 'Vänligen välj mottagarens ort'
                                    }]}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <CountrySelect
                                    name='receiver_country'
                                    title='Land'
                                    mode=""
                                    onChange={handleCountryChange}
                                    rules={[{
                                        required: true,
                                        message: 'Vänligen välj mottagarens land'
                                    }]}/>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={6}>
                                <Form.Item
                                    label="Telefon"
                                    name="receiver_phone"
                                    rules={[{
                                        required: true,
                                        message: 'Vänligen välj mottagarens telefonnummer'
                                    }]}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Email"
                                    name="receiver_email"
                                    rules={[{
                                        required: true,
                                        message: 'Vänligen välj motagarens email'
                                    }]}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Panel>
                    <Panel header={<CollapsePanel icon={<PlusCircleOutlined />} text="Allmänt" />} key="2">
                        <Form.Item
                            label="Avsändare"
                            rules={[{
                                required: true,
                                message: 'Vänligen välj avsändare'
                            }]}
                        >
                            <SenderSelect name={'sender_id'} initialValue={selectedSender} onChange={handleSelectSender}/>
                        </Form.Item>
                        <Form.Item
                            label="Fraktmetod"
                            rules={[{
                                required: true,
                                message: 'Vänligen välj fraktmetod'
                            }]}
                        >
                            <ShippingServiceSelect name={'shipping_service_id'} onChange={handleShippingServiceChange}/>
                        </Form.Item>
                        <Form.Item
                            label="Pakettyp"
                            name="parcel_type"
                            rules={[{
                                required: true,
                                message: 'Vänligen välj pakettyp'
                            }]}>
                            <ParcelTypeSelect value={selectedPackageType} shippingService={shippingService} />
                        </Form.Item>
                        <Form.Item name="should_print_return_labels" valuePropName="checked" noStyle>
                            <Checkbox>Skriv ut retursedlar</Checkbox>
                        </Form.Item>
                    </Panel>
                    <Panel header={<CollapsePanel icon={<PlusCircleOutlined />} text="Tilläggstjänster"/>}  key="3">
                        { simpleAdditionalServices ?
                            <h3>Vanliga tilläggstjänster</h3> : null }
                        {renderSimpleAdditionalServices()}
                        { advancedAdditionalServices ?
                            <h3>Övriga tilläggstjänster</h3> : null }
                        {renderAdvancedAdditionalServices()}
                        {renderAdvancedAdditionalServices()}
                    </Panel>
                    { showCustomsPanel ? <Panel header={<CollapsePanel icon={<PlusCircleOutlined />} text="Tull" />} key="4">
                        <Form.Item
                        label="Betalvillkor"
                        name="payment_terms"
                        rules={[{required: false}]}>
                            <Select
                                value={selectedPaymentTerms}
                                onChange={handleSelectPaymentTerms}
                                options={paymentTermsOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Fakturatyp"
                            name="customs_invoice_type"
                            rules={[{required: false}]}>
                            <Select
                                value={selectedCustomsInvoiceType}
                                onChange={handleSelectChangeCustomsInvoiceType}
                                options={customsInvoiceTypeOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{required: false}]}>
                            <Select
                                value={selectedType}
                                onChange={handleSelectChangeType}
                                options={typeOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Valuta"
                            name="currency"
                            rules={[{required: false}]}>
                            <Input/>
                        </Form.Item>
                    </Panel> : null }
                </Collapse>
                <h3 style={{marginTop: '10px', marginBottom: '10px'}}>Försändelsens innehåll</h3>
                <EditableTable
                  tableData={tableData}
                  pagination={false}
                  showCustomsPanel={showCustomsPanel}
                  rowKey={(record) => record.key}
                  key={forceUpdateTrigger}
                  ref={tableRef}
                />
                <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                <Form.Item>
                    <Row>
                        {
                            isChanged ?
                                <Button style={{marginRight: '20px'}} type="primary"
                                        htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
};

export default AddShipmentForm;


const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({title, editable, children, dataIndex, record, required, handleSave, ...restProps}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex]
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: required,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
    ) : (
        <div
            className="editable-cell-value-wrap"
            style={{
            paddingRight: 24,
            }}
            onClick={toggleEdit}
        >
            {children}
        </div>
    );
    }

    return <td {...restProps}>{childNode}</td>;
};

class EditableTable extends React.Component{
    constructor(props) {

        super(props);
        this.ref = props.ref
        this.columns = [
            {
                title: 'Beskrivning',
                dataIndex: 'description',
                editable: true,
                required: true,
            },
            {
                title: 'Antal',
                dataIndex: 'quantity',
                editable: true,
                required: true,
                inputType: 'number',
            },
            {
                title: 'Vikt',
                dataIndex: 'weight',
                editable: true,
                required: false,
                inputType: 'number',
            }
        ];

        const { showCustomsPanel } = this.props;
        const taricColumn = showCustomsPanel
          ?
            {
                title: 'Taric nummer',
                dataIndex: 'taric_number',
                editable: true,
            }
          : null;
        const totalValueColumn = showCustomsPanel
          ?
            {
                title: 'Totalt Värde',
                dataIndex: 'total',
                editable: true,
            }
          : null;
        this.state = {
            dataSource: [],
            forceUpdateTrigger: 0,
        };

        if (taricColumn) {
          this.columns.push(taricColumn);
        }

        if (totalValueColumn) {
          this.columns.push(totalValueColumn);
        }

        this.columns.push({
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
              <Button onClick={() => this.handleDelete(record.key)}>Delete</Button>
            ),
        });
    }

    forceUpdateTable = () => {
      this.setState((prevState) => ({
        forceUpdateTrigger: prevState.forceUpdateTrigger + 1,
      }));
    };

    handleDelete = (key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            dataSource: dataSource.filter((item) => item.key !== key),
        });
    };

    handleSave = (row) => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        this.setState({
            dataSource: newData,
        });
    };

    handleAddRow = () => {
        const { dataSource } = this.state;
        const newRow = {
            key: dataSource.length + 1,
            description: '',
            quantity: '',
            weight: '',
            totalValue: '',
            taric_number: '',
        };

        this.setState((prevState) => ({
            dataSource: [...prevState.dataSource, newRow],
        }));
    };

    render() {
        const { dataSource, forceUpdate } = this.state;
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });


        return (
            <div>
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    ref={this.ref}
                    key={forceUpdate}
                />
                <Button onClick={this.handleAddRow}  style={{marginBottom: '10px'}}>Lägg till rad</Button>
            </div>
        );
    }
}
