import {InputNumber, Row, Select, Input, Checkbox} from "antd";
import React from "react";
import CustomSelect from "../components/CustomSelect/CustomSelect";
import ShippingDeliveryType from "../components/ShippingDeliveryType/ShippingDeliveryType";
import QuillEditor from "../components/Misc/QuillEditor/QuillEditor";

const {Option} = Select;
const {TextArea} = Input;

const getComponentByType = ({
                                value,
                                formRef = null,
                                setIsChanged = false,
                                isFormItem = true,
                                isAddingForm = false}) => {

    const type = value.type;
    const options = value.options && value.options.length > 0 ? value.options : [];
    const checkboxText = value.type === 'checkbox' ? value.label: '';
    const placeholder =  value.placeholder;
    const values = formRef && formRef.current ? formRef.current.getFieldsValue() : null;

    switch (type) {
        case 'select':
            return (
                <Select>
                    {
                        options && options.length > 0 && options.map(option => {
                            return <Option key={option + '_' + Math.random()} value={option.key}>{ option.value ?? option.key}</Option>
                        })
                    }
                </Select>
            )
        case 'multiple':
            return (
                <CustomSelect
                    formItem={{isFormItem: isFormItem, name: value.name, label: value.label}}
                    type={value.fetch_type ?? value.name}
                    optionName='name' optionValue='id' />
            )
        case 'single_select':
            return (
                <CustomSelect
                    formItem={{isFormItem: isFormItem, name: value.name, label: value.label}}
                    optionName='name' optionValue='id'
                    modeType='single' type={value.fetch_type ?? value.name}/>
            )
        case 'tags':
            return (
                <Select mode='tags'/>
            )
        case 'checkbox':
            return <Checkbox>{checkboxText}</Checkbox>
        case 'number':
            return <InputNumber placeholder={placeholder}/>
        case 'textarea':
            return <TextArea rows={4} placeholder={placeholder}/>
        case 'quill':
            return (
                <QuillEditor isAcf={true} field={{name: value.name}}/>
            )
        case 'home_delivery_type':
            return (
                <ShippingDeliveryType formRef={formRef} formValues={values} isAddingForm={true}/>
            )
        default:
            return <Input name={value.name} placeholder={placeholder}/>
    }
}

export default getComponentByType;
