import React, {useState, useEffect} from 'react';
import {Button, Form, Input, message, Row, Select, Spin} from 'antd';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import axios from "axios";
import {getApiUrl} from "../../helpers/helpers";

import {
    SET_POSTNORD_ACCOUNT, UPDATE_POSTNORD_ACCOUNT
} from "../../state/reducers/postnordAccountReducer";


const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const EditPostnordAccountForm = ({id}) => {
    const {updateItem, getItem} = useActions()
    const [postnordAccount, setPostnordAccount] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const navigate = useNavigate();
    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    useEffect(() => {
        getPostnordAccount();
    }, []);

    const getPostnordAccount = () => {
        getItem({
              type:'postnord_account',
              setItemActionType: 'SET_POSTNORD_ACCOUNT'
        });
    }

    const finishHandler = (values) => {
        updateItem({
            values,
            id,
            ...getActionTypes('postnord_account'),
            contentText: `Du har uppdaterat ditt Postnord konto`
        });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/');
    };
    console.log(postnordAccount);

    const renderEditPostnordAccount = () => {
        if (!postnordAccount) {
            return (
                <Form {...layout}
                  name="edit-postnord_account"
                  layout="vertical"
                  onFinish={finishHandler}
                  onValuesChange={valuesChangeHandler}>
                <h2>Postnord Konto</h2>
                <Form.Item
                    name='account_number'
                    label="Konto nummer"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <div style={{borderBottom: '1px solid #d9d9d9', marginBottom: '10px', marginTop: '10px'}}></div>
                <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                    <Row justify="space-between">
                        {
                            isChanged ?
                                <Button type="primary" htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
            );
        }

        return (
            <Form {...layout}
                  name="edit-postnord_account"
                  layout="vertical"
                  onFinish={finishHandler}
                  onValuesChange={valuesChangeHandler}>
                <h2>Postnord Konto</h2>
                <Form.Item
                    name='account_number'
                    label="Konto nummer"
                    initialValue={postnordAccount.account_number}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <div style={{borderBottom: '1px solid #d9d9d9', marginBottom: '10px', marginTop: '10px'}}></div>
                <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                    <Row justify="space-between">
                        {
                            isChanged ?
                                <Button type="primary" htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
        )
    };
    return (
        renderEditPostnordAccount()
    );
};

export default EditPostnordAccountForm;
