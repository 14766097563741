import React, {useState} from 'react';
import {Button, Form, Input, Row} from "antd";
import {KeyOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import useActions from "../../hooks/useActions";

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const NewPasswordForm = () => {
    const {newPassword} = useActions();
    const [isNewPassChanged, setIsNewPassChanged] = useState(false);
    const {loading, loginValues} = useSelector(state => state?.login)

    const finishHandler = (values) => {
        if (!isNewPassChanged) return;
        newPassword({...values, loginValues});
        setIsNewPassChanged(false);
    }

    const valuesChangeHandler = (setIsChanged) => {
        setIsChanged(true);
    }

    return (
        <div className='container login-page'>
            <h2 style={{textAlign: 'center', maxWidth: '430px', width: '100%', fontSize: '20px'}}>Skapa nytt lösenord</h2>

            <Form {...layout}
                  className='login-form'
                  name="new-password-form"
                  onFinish={finishHandler}
                  onChange={() => valuesChangeHandler(setIsNewPassChanged)}
            >
                <Form.Item
                    name='new_password'
                    label="Nytt lösenord"
                    labelCol={{ span: 24 }}
                    wrapperCol={{span: 24}}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input autoComplete='new-password' type='password' width='100%' prefix={<KeyOutlined />}/>
                </Form.Item>

                <Form.Item
                    wrapperCol={{span: 24}}
                >
                    <Row justify='space-between' align='middle'>
                        <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                            Skapa nytt lösenord
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
        </div>
    );
};

export default NewPasswordForm;
