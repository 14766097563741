const initialState = {
    shippingServiceMappings: {
        objects: [],
        total: 0,
        page: 1,
    },
    shippingServiceMapping: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    shippingServiceMappingsTableFilters: {},
    shippingServiceMappingsTableSorting: {},
    shippingServiceMappingsTablePagination: {},
    senders: [],
    shippingServices: [],
    loading: false,
}

export const SET_SHIPPING_SERVICE_MAPPINGS = 'SET_SHIPPING_SERVICE_MAPPINGS';
export const SET_SHIPPING_SERVICE_MAPPING = 'SET_SHIPPING_SERVICE_MAPPING';
export const SET_SHIPPING_SERVICE_MAPPINGS_LOADING = 'SET_SHIPPING_SERVICE_MAPPINGS_LOADING';
export const SET_SHIPPING_SERVICE_MAPPINGS_PAGINATION = 'SET_SHIPPING_SERVICE_MAPPINGS_PAGINATION';
export const UPDATE_SHIPPING_SERVICE_MAPPING = 'UPDATE_SHIPPING_SERVICE_MAPPING';
export const SET_SHIPPING_SERVICE_MAPPINGS_TABLE_FILTERS = 'SET_SHIPPING_SERVICE_MAPPINGS_TABLE_FILTERS';
export const SET_SHIPPING_SERVICE_MAPPINGS_TABLE_SORTING = 'SET_SHIPPING_SERVICE_MAPPINGS_TABLE_SORTING';
export const SET_SHIPPING_SERVICE_MAPPINGS_TABLE_PAGINATION = 'SET_SHIPPING_SERVICE_MAPPINGS_TABLE_PAGINATION';

export const UPDATE_SHIPPING_SERVICE = 'UPDATE_SHIPPING_SERVICE';
const shippingServiceMappingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIPPING_SERVICE_MAPPINGS_LOADING:
            return {...state, loading: action.payload};
        case SET_SHIPPING_SERVICE_MAPPINGS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_SHIPPING_SERVICE_MAPPINGS:
            return {...state, shippingServiceMappings: action.payload, loading: false};
        case SET_SHIPPING_SERVICE_MAPPING:
            return {...state, shippingServiceMapping: {...action.payload}, loading: false};
        case SET_SHIPPING_SERVICE_MAPPINGS_TABLE_FILTERS:
            return {...state, shippingServiceMappingsTableFilters: action.payload};
        case UPDATE_SHIPPING_SERVICE_MAPPING:
            return {...state, shippingServiceMapping: {...action.payload}, loading: false};
        case SET_SHIPPING_SERVICE_MAPPINGS_TABLE_SORTING:
            return {...state, shippingServiceMappingsTableSorting: action.payload};
        case SET_SHIPPING_SERVICE_MAPPINGS_TABLE_PAGINATION:
            return {...state, shippingServiceMappingsTablePagination: action.payload};
        default:
            return state;
    }
}

export default shippingServiceMappingsReducer
