

import React, {useState, useEffect} from 'react';
import {Button, Form, Input, message, Row, InputNumber, Select} from 'antd';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import axios from "axios";
import {getApiUrl, getParams} from "../../helpers/helpers";

import {ADD_DEFAULT_CUSTOMS_SETTINGS} from "../../state/reducers/defaultCustomsSettingsReducer";

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};



const EditDefaultCustomsSettingsForm = ({id}) => {
    const {updateItem} = useActions()
    const {addItem, setItemNull} = useActions();
    const [customsSettings, setCustomsSettings] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [selectedPaymentTerms, setSelectedPaymentTerms] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedCustomsInvoiceType, setSelectedCustomsInvoiceType] = useState(null);
    const [userId, setUserId] = useState(null);
    const {customApiGet} = useActions();


    const navigate = useNavigate();
    const valuesChangeHandler = () => {
        setIsChanged(true);
    };


    const typeOptions = [
      { value: 'gift', label: 'Gåva' },
      { value: 'document', label: 'Dokument' },
      { value: 'returned_goods', label: 'Returnerat gods' },
      { value: 'commercial_sample', label: 'Kommersiell sample' },
      { value: 'other', label: 'Annan' },
      { value: 'sale_of_goods', label: 'Försäljning av varor' }
    ];

    const paymentTermsOptions = [
      { value: 'ddu', label: 'Tullavgifter förbetalda av säljaren' },
      { value: 'dap', label: 'Tullavgifter betalas av köparen' },
    ];
    const customsInvoiceTypeOptions = [
      { value: 'commercial', label: 'Kommersiell' },
      { value: 'proforma', label: 'Proforma' },
      { value: 'cn22', label: 'CN22' },
      { value: 'cn23', label: 'CN23' }
    ];

    useEffect(() => {
        getCustomsSettings();
    }, []);

    useEffect(() => {
        getCustomsSettings();
    }, []);

    const getCustomsSettings = () => {
        customApiGet({
            url: `${getApiUrl('default_customs_settings')}default_customs_settings/`,
            successCallback: function (response) {
                setCustomsSettings(response.data)
            },
            errorCallback: function (error) {
            },
        })
    }


    const handleSelectPaymentTerms = (selectedOption) => {
        setSelectedPaymentTerms(selectedOption);
    };
    const handleSelectChangeCustomsInvoiceType = (selectedOption) => {
        setSelectedCustomsInvoiceType(selectedOption);
    };
    const handleSelectChangeType = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const updateHandler = (values) => {
        console.log("FINISH");
        values.user_id = userId;
        updateItem({
            values,
            ...getActionTypes('default_customs_settings'),
            contentText: `Du har uppdaterat tullinställningarna`
        });
        setIsChanged(false);
    };

    const saveHandler = (values) => {
        values.user_id = userId;
        addItem({
            values: values,
            ...getActionTypes('default_customs_settings'),
            ADD_DEFAULT_CUSTOMS_SETTINGS
        });
    };

    const cancelHandler = () => {
        navigate('/');
    };

    const renderEditCustomsSettings = () => {
        if (!customsSettings) {
            return (
                <Form {...layout}
                      name="edit-customs-settings"
                      onFinish={saveHandler}
                      layout={'vertical'}
                      validateMessages={validateMessages}>
                    <h2>Tull Inställningar</h2>
                    <Form.Item
                        label="Betalvillkor"
                        name="payment_terms"
                        rules={[{required: true}]}>
                        <Select
                            value={selectedPaymentTerms}
                            onChange={handleSelectPaymentTerms}
                            options={paymentTermsOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Fakturatyp"
                        name="customs_invoice_type"
                        rules={[{required: true}]}>
                        <Select
                            value={selectedCustomsInvoiceType}
                            onChange={handleSelectChangeCustomsInvoiceType}
                            options={customsInvoiceTypeOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Type"
                        name="type"
                        rules={[{required: true}]}>
                        <Select
                            value={selectedType}
                            onChange={handleSelectChangeType}
                            options={typeOptions}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                        <Row justify="space-between">
                            <Button type="primary" htmlType="submit">
                                Spara
                            </Button>
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            );
        }
    return (
        <Form {...layout}
              name="edit-customs-settings"
              onFinish={updateHandler}
              layout={'vertical'}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}>
            <h2>Tull Inställningar</h2>
            <Form.Item
                name='id'
                label="ID"
                hidden={true}
                initialValue={customsSettings.id}
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input bordered={false} readOnly={true} hidden={true}/>
            </Form.Item>
            <Form.Item
                name='payment_terms'
                label="Betalvillkor"
                initialValue={customsSettings.payment_terms}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                 <Select
                  value={selectedPaymentTerms}
                  onChange={handleSelectPaymentTerms}
                  options={paymentTermsOptions}
                />
            </Form.Item>
            <Form.Item
                name='customs_invoice_type'
                label="Fakturatyp"
                initialValue={customsSettings.customs_invoice_type}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                 <Select
                  value={selectedCustomsInvoiceType}
                  onChange={handleSelectChangeCustomsInvoiceType}
                  options={customsInvoiceTypeOptions}
                />
            </Form.Item>
            <Form.Item
                name='type'
                label="Typ"
                initialValue={customsSettings.type}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                 <Select
                  value={selectedType}
                  onChange={handleSelectChangeType}
                  options={typeOptions}
                />
            </Form.Item>
            <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
            <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                <Row justify="space-between">
                    {
                        isChanged ?
                            <Button type="primary" htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    );
}

    return <>{renderEditCustomsSettings()}</>;
};

export default EditDefaultCustomsSettingsForm;
