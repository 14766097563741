

export const attributesActions = {
    setAttributesFilters: (payload) => (
        {
            type: SET_ATTRIBUTES_TABLE_FILTERS,
            payload
        }
    ),
    setAttributesSorting: (payload) => (
        {
            type: SET_ATTRIBUTES_TABLE_SORTING,
            payload
        }
    ),
    setAttributesPagination: (payload) => (
        {
            type: SET_ATTRIBUTES_TABLE_PAGINATION,
            payload
        }
    )
}
