
const initialState = {
    loading: false,
    confirmUserValues: {},
}

export const SET_CONFIRM_USER_VALUES = 'SET_CONFIRM_USER_VALUES';
export const SET_CONFIRM_USER_LOADING = 'SET_REGISTER_LOADING';

const confirmUserReducer = (state= initialState, action) => {
    switch (action.type) {
        case SET_CONFIRM_USER_VALUES:
            return {...state, confirmUserValues: action.payload}
        case SET_CONFIRM_USER_LOADING:
            return {...state, loading: action.payload}
        default:
            return state
    }
}

export default confirmUserReducer
