import React from 'react';
import {useSelector} from "react-redux";
import {Button, Card, Row, Tag} from "antd";
import {getParamsFromObject, renderTitleFromType} from "../../../helpers/helpers";
import useActions from "../../../hooks/useActions";
import {EditOutlined, FilterOutlined} from "@ant-design/icons";
import moment from "moment";
import {getDateFormat} from "../../../helpers/getDate";

const PickListActiveFilters = ({setIsPicklistFiltersPopupVisible, setRangeDate}) => {
    const {filters, picklistFormRef, productsNames} = useSelector(state => state?.picklist);
    const {sites} = useSelector(state => state?.sites);
    const {shippingMethods} = useSelector(state => state?.shippingMethods);
    const {setPicklistFilters, getPicklists} = useActions()
    const dateFormat = getDateFormat();
    const todayDate = [moment(new Date(), dateFormat), moment(new Date(), dateFormat)];

    const renderTitleForTag = (type) => {
        switch (type){
            case 'name':
                return 'Namn'
            case 'price':
                return 'Pris'
            case 'skus':
                return 'Produkter'
            case 'delivery_date':
                return 'Leveransdag'
            case 'customer_first_name':
                return 'Kundens namn'
            case 'customer_last_name':
                return 'Kundens efternamn'
            case 'site_id':
                return 'Försäljningsställen'
            case 'shipping_method_machine_name':
                return 'Fraktmetod'
            default:
                return renderTitleFromType(type)
        }
    }

    const removeFilterHandler = (name) => {
        if (picklistFormRef.current) {
            let allValues;
            picklistFormRef.current.setFieldsValue({
                [name]: (
                    name === 'skus' ||
                    name === 'status'
                )
                    ? [] :
                    (
                        name === 'order_total' ||
                        name === 'order_number'
                    )
                        ? {min: null, max: null} :
                        null
            })

            if (name === 'date') {
                setRangeDate(todayDate)
            }

            if (Object.keys(picklistFormRef.current.getFieldsValue()).length > 0) {
                allValues = {...picklistFormRef.current.getFieldsValue()};
            } else {
                allValues = {...filters}
                setPicklistFilters({...filters, [name]: null})
            }

            setPicklistFilters(allValues)

            getPicklists({
                params: {
                    summary: 1,
                    ...getParamsFromObject(allValues)
                }
            })
        }
    }

    const renderActiveFilters = () => {
        const filteredObject = {};

        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                const filtersValues = filters[key];
                if (!Array.isArray(filtersValues) && typeof filtersValues === 'object' && filtersValues !== null) {
                    let fixedString = '', index = 0;
                    for (const newKey in filtersValues) {
                        if (filtersValues[newKey]) {
                            fixedString += index >= 1 ? '-' + filtersValues[newKey] : `${renderTitleForTag(key)}: ` + filtersValues[newKey]
                        }
                        index++;
                    }
                    if (fixedString) {
                        filteredObject[key] = fixedString;
                    }
                }

                if (Array.isArray(filtersValues) && filtersValues && filtersValues?.length > 0) {
                    filteredObject[key] = renderTitleForTag(key) + ': ' + ( key === 'skus' ? productsNames?.join(', ') : filtersValues.join(', '))
                }

                if (typeof filtersValues !== 'object' && filtersValues !== null && filtersValues !== undefined) {
                    const currentSite = sites?.objects.find(item => +item.id === +filtersValues);
                    const currentShippingMethod = shippingMethods?.objects.find(item => item.machine_name === filtersValues)
                    const isSite = key === 'site_id' && currentSite;
                    const isShippingMethod = key === 'shipping_method_machine_name' && currentShippingMethod;
                    filteredObject[key] = renderTitleForTag(key) + ': ' + (isSite ? currentSite.name : (isShippingMethod ? currentShippingMethod.name : filtersValues))
                }
            }
        }

       return Object.keys(filteredObject).length > 0 &&
           Object.keys(filteredObject).map(obj => {
               return <Tag className='filter-tag'
                           style={{marginBottom: '5px'}}
                           key={obj} {...obj !== 'delivery_date' && {closable: true}}
                           onClose={() => removeFilterHandler(obj)}>
                   {filteredObject[obj]}
               </Tag>;
           })

    }

    return (
        <div style={{marginBottom: '20px'}}>
            <Card title={
                    <Row align='middle'><FilterOutlined style={{marginRight: '5px'}} />
                    <h4 style={{marginBottom: '0'}}>Aktiva filter</h4>
                </Row>} extra={<Button onClick={() => {setIsPicklistFiltersPopupVisible(true)}} type='link' style={{padding: '0'}} icon={<EditOutlined />}/>} style={{ width: 300 }}>
                {renderActiveFilters()}
            </Card>

        </div>
    );
};

export default PickListActiveFilters;
