
import {
    SET_SENDERS_TABLE_FILTERS,
    SET_SENDERS_TABLE_SORTING
} from "../reducers/sendersReducer";

export const senderActions = {

    setSendersFiltering: (payload) => (
        {
            type: SET_SENDERS_TABLE_FILTERS,
            payload
        }
    ),
    setSendersSorting: (payload) => (
        {
            type: SET_SENDERS_TABLE_SORTING,
            payload
        }
    ),
}
