import React, {useEffect, useRef, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";

const MenuListItem = ({menuItem, currentMenuName, setCurrentMenuName}) => {
    const [isActive, setIsActive] = useState(false);
    let navigate = useNavigate();
    let ref = useRef(null);

    useEffect(() => {
        if (!ref.current) return;
        ref.current.style.height = isActive ? `${ref.current.scrollHeight}px` : "0";
        ref.current.style.overflow = isActive ? `visible` : "hidden";
        ref.current.style.transition = 'height 0.2s';
        ref.current.style.marginBottom = isActive ? '10px' : '0';
    }, [ref, isActive]);

    useEffect(() => {
        if (currentMenuName === menuItem.name) {
            setIsActive(true)
        } else {
            setIsActive(false);
        }
    }, [currentMenuName, menuItem.name, setIsActive]);


    const renderChildren = (children) => {
        return <ul ref={ref} className='sub-menu'>
            {children.map(child => {
                return <li key={child.name}><NavLink to={child.link}>{child.title}</NavLink></li>
            })}
        </ul>
    }

    const menuLinkClickHandler = (e, menuItem) => {
        if (menuItem.parent) {
            e.preventDefault();
            if ( !isActive ) {
                navigate(menuItem.children[0].link)
                setCurrentMenuName(menuItem.name)
            } else {
                navigate('/')
            }
            setIsActive(prev => !prev)
        }else {
            setCurrentMenuName('')
        }
    }

    const activeClass = isActive ? 'active' : '';

    return (
        <li className='menu__list--item'>
            {
                menuItem.children ?
                    <>
                        <a className={['has-child', activeClass].join(' ')} onClick={(e) => menuLinkClickHandler(e, menuItem)}
                            href='/'
                            key={menuItem.name}>{menuItem.icon ?? ''} {menuItem.title}</a>
                        {renderChildren(menuItem.children)}
                    </>
                    :
                    <NavLink to={menuItem.link}
                             onClick={(e) => menuLinkClickHandler(e, menuItem)}>
                        {menuItem.icon ?? ''} {menuItem.title}
                    </NavLink>
            }
        </li>
    );
};

export default MenuListItem;
