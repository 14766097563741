import React, {useEffect, useState} from 'react';
import {Row, DatePicker} from "antd";
import './rangeDatePicker.scss'
import locale from 'antd/es/date-picker/locale/sv_SE';
import {renderDateYMD} from "../../../helpers/helpers";
import moment from 'moment';
import {getDateFormat} from "../../../helpers/getDate";

const RangeDatePicker = ({
                             custom = {
                                 customRange: [],
                                 customIsRangeChanged: false,
                                 isCustom: false,
                                 customSetIsRangeChanged: () => {},
                                 customSetRange: () => {},
                                 customFrom: 'date_from',
                                 customTo: 'date_to',
                                 customName: 'date'
                             },
                             formRef = null,
                             ...props}) => {
    const dateFormat = getDateFormat()
    const todayDate = [moment(new Date(), dateFormat), moment(new Date(), dateFormat)];
    const [range, setRangeDate] = custom.isCustom ? [custom.customRange, custom.customSetRange] : useState(todayDate)
    const [isRangeChanged, setIsRangeChanged] = custom.isCustom ? [custom.customIsRangeChanged, custom.customSetIsRangeChanged] : useState(false)
    const { RangePicker } = DatePicker;

    useEffect(() => {
        if (formRef?.current) {
            formRef.current.setFieldsValue({
                [custom.customName]: {
                    [custom.customFrom]: renderDateYMD(range[0]._d),
                    [custom.customTo]: renderDateYMD(range[1]._d)
                }
            })
        }
    }, [])

    useEffect(() => {
        if (isRangeChanged && formRef?.current) {
            formRef.current.setFieldsValue({
                [custom.customName]: {
                    [custom.customFrom]: renderDateYMD(range[0]._d),
                    [custom.customTo]: renderDateYMD(range[1]._d)
                }
            })
            setIsRangeChanged(false);
        }
    },[formRef, isRangeChanged])

    const changeDate = (date) => {
        setIsRangeChanged(true)
        setRangeDate([moment(date[0]._d, dateFormat), moment(date[1]._d, dateFormat)]);
    }

    return (
        <Row className='react-datepicker__row' align='middle' {...props}>
            <RangePicker value={range} allowClear={false} locale={locale} onChange={changeDate} format="YYYY-MM-DD"/>
        </Row>
    );
};

export default RangeDatePicker;
