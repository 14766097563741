import React from 'react';
import AntTable from "../../AntTable/AntTable";
import {dashboardColumns} from "../../ant-table/dashboardColumns";
import {Row} from "antd";

const PickListTables = ({summary, picklistLoading, rangeDate}) => {
    return (
        <Row justify='space-between' style={{width: '100%', marginBottom: '20px'}}>
            <AntTable dataSource={summary.products}
                      loading={picklistLoading}
                      style={{width: 'calc(50% - 20px)'}}
                      rowSelection={false}
                      rowKey={row => row.name + Math.random()}
                      rangeDate={rangeDate}
                      pagination={false}
                      columns={dashboardColumns({type: 'pick_lists'}).picklistTotalProductsColumns}
            />
            <AntTable style={{width: 'calc(50% - 20px)'}}
                      dataSource={summary.shipping_services}
                      loading={picklistLoading}
                      rowSelection={false}
                      rowKey={row => row.name + Math.random()}
                      rangeDate={rangeDate}
                      pagination={false}
                      columns={dashboardColumns({type: 'pick_lists'}).picklistTotalDeliveryColumns}
            />
        </Row>
    );
};

export default PickListTables;
