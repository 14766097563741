import React, {useEffect, useState} from 'react';
import {Button, Form, Row, Select, Col} from 'antd';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";

import {
    SET_USER_CARRIER, ADD_USER_CARRIER
} from "../../state/reducers/userCarriersReducer";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const AddUserCarrierForm = () => {
    const {addItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const {loading} = useSelector(state => state?.senders);
    const [selectedOption, setSelectedOption] = useState(null);
    const [userId] = useState(null);
    const navigate = useNavigate();

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const options = [
        { value: 'dhl', label: 'DHL' },
        { value: 'db_schenker', label: 'DB Schenker' },
        { value: 'budbee', label: 'Budbee' },
        { value: 'postnord', label: 'Postnord' },
        { value: 'best', label: 'Best' }
    ];

    const finishHandler = (values) => {
        values.user_id = userId;
        console.log(values)
        addItem({
            values: values,
            ...getActionTypes('user_carriers'),
            ADD_USER_CARRIER
        });
        setIsChanged(false);
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const cancelHandler = () => {
        navigate('/user_carriers');
    };

    return (
        <>
            <Form {...layout}
                  data-testid='add-user-carrier-form'
                  name="add-user-carrier-mapping"
                  onFinish={finishHandler}
                  initialValues={{}}
                  style={{width: '100%'}}
                  onValuesChange={valuesChangeHandler}
                  validateMessages={validateMessages}>

                <h2>Ny Transportör</h2>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item
                            name='carrier_id'
                            label="Transportör"
                            wrapperCol={{ span: 4 }}
                            rules={[{required: true}]}
                        >
                            <Select
                                value={selectedOption}
                                onChange={handleSelectChange}
                                options={options}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                <Form.Item>
                    <Row>
                        {
                            isChanged ?
                                <Button style={{marginRight: '20px'}} type="primary" loading={loading}
                                        htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
};

export default AddUserCarrierForm;
