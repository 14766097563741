import React, {createRef, useEffect, useState} from 'react';
import {Row, Select, Spin, Col, message} from 'antd';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {SET_SHIPMENT} from "../../state/reducers/shipmentsReducer";

import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import "./EditShipmentForm.scss";
import {renderDateYMD, getOrderLink, getSenderLink, getApiUrl, getParams,} from "../../helpers/helpers";
import {getColumns} from "../../helpers/getColumns";
import ParcelTable from "./ParcelTable";
import axios from "axios";
import PdfViewer from "../ShippingLabels/pdfViewer";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditShipmentForm = ({id}) => {
    const {updateItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const [shipment, setShipment] = useState(false);
    const {loading} = useSelector(state => state?.shipments);
    const formRef = createRef();
    const {Option} = Select;
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get(`${getApiUrl('shipments')}shipments/${id}/`, {
              headers: { Authorization: localStorage.getItem('token')?.replaceAll('"', '') },
            })
            .then((res) => {
              setShipment(res.data);
            })
            .catch((err) => {
              message.error(err?.data?.message);
            });
    }, []);


    const finishHandler = (values) => {
        updateItem({
            values: values,
            id,
            ...getActionTypes('shipments'),
        });

        setIsChanged(false);
    };

    const getShippingLabels = () => {
        navigate('/shipments');
    };

    if (Object.keys(shipment).length === 0) {
        return <Row data-testid='spinner' justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <>
            <h2>Försändelse {shipment.id}</h2>
            <div style={{marginBottom: '20px'}}>
                <Row>
                <Col style={{marginRight: '20px'}}>
                    <h3>Mottagare</h3>
                    <ul>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Namn:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.receiver_first_name + ' ' + shipment.receiver_last_name}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Adress:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.receiver_address_1}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Postnummer:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.receiver_zipcode}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Ort:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.receiver_city}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Land:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.receiver_country}</span>
                        </li>
                    </ul>
                </Col>
            </Row>
            <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
            <Row>
                 <Col style={{marginRight: '20px'}}>
                    <h3>Information</h3>
                    <ul>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Skapad datum:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.datetime_created}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Uppdaterad datum:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.datetime_last_updated}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Leveransdatum:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.shipment_date}</span>
                        </li>
                         { shipment.order_id ?
                            <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Order:</b></span>
                            <span style={{ color: '#8e8e8e'}}><a href={getOrderLink(shipment.order_id)}>Order {shipment.order_id}</a></span>
                        </li> : null }
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Avsändare:</b></span>
                            <span style={{ color: '#8e8e8e'}}><a href={getSenderLink(shipment.sender_id)}>{shipment.sender.company_name}</a></span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Frakttjänst:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.shipping_service_id}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Status:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.status}</span>
                        </li>
                        <li>
                            <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}><b>Shipping Label:</b></span>
                            <span style={{ color: '#8e8e8e'}}>{shipment.shipping_label}</span>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col style={{marginRight: '20px'}}>
                    <PdfViewer shipment={shipment} />
                </Col>
            </Row>
            </div>
                <ParcelTable ref={formRef} shipment={shipment} loading={loading} columns={getColumns} />
        </>
    );
};

export default EditShipmentForm;
