import React, {useEffect, useState} from 'react';

import {useSelector} from "react-redux";
import useActions from "../../hooks/useActions";

import {Button, Row} from "antd";

import {dashboardColumns} from "../../components/ant-table/dashboardColumns";

import AntTable from "../../components/AntTable/AntTable";
import PickListButtons from "../../components/PickList/PickListButtons/PickListButtons";
import PickListTables from "../../components/PickList/PickListTables/PickListTables";
import BulkOperations from "../../components/BulkOperations/BulkOperations";
import PickListOrderPopup from "../../components/PickList/PickListOrderPopup/PickListOrderPopup";
import PickListOrderPopupContent
    from "../../components/PickList/PickListOrderPopup/PickListOrderPopupContent/PickListOrderPopupContent";
import PickListFilters from "../../components/PickList/PickListFilters/PickListFilters";

import {getParamsFromObject} from "../../helpers/helpers";
import {getDateFormat} from "../../helpers/getDate";
import {getActionTypes} from "../../helpers/getActionTypes";

import moment from "moment";


import './picklist.scss';

const PickList = ({type, title}) => {
    const {getPicklists, getItems, setPickListOrderPopup, setIsPrintAllButtonPressed, setPicklistTableSorting} = useActions();
    const [activeButtons, setActiveButtons] = useState({isViewListActive: true, isViewTotalActive: false})
    const dateFormat = getDateFormat();
    const todayDate = [moment(new Date(), dateFormat), moment(new Date(), dateFormat)];

    const {
        picklists,
        picklistLoading,
        isPrintAllButtonPressed,
        filters
    } = useSelector(state => state?.picklist)

    const [deliveryRangeDate, setDeliveryRangeDate] = useState(filters?.delivery_date ? [moment(new Date(filters.delivery_date.delivery_date_from), dateFormat), moment(new Date(filters.delivery_date.delivery_date_to), dateFormat)] : todayDate)
    const [isDeliveryRangeChanged, setIsDeliveryRangeChanged] = useState(false);

    const actionsTypes = {
        type: 'orders',
        setPaginationActionType: `SET_ORDERS_PAGINATION`,
        setLoadingActionType: `SET_ORDERS_LOADING`,
        setItemsActionType: `SET_ORDERS`
    }

    useEffect(() => {
        getPicklists({
            params: {
                ...getParamsFromObject(filters),
                summary: 1,
            }
        })

        getItems({...getActionTypes('sites')})
    }, []);

    const orderNumberClickHandler = (orderId = null) => {
        setIsPrintAllButtonPressed(false);
        setPickListOrderPopup({
            visible: true,
            orderId: orderId
        })
    }

    const orderRender = (order) => {
        const orderItems = order?.order_items;

        if (orderItems.length > 0) {
            const uniqueItems = new Set(orderItems.map(orderItem => orderItem.external_order_id))

            return (
                <Button onClick={() => orderNumberClickHandler(order.id)} style={{padding: '0'}}
                        type='link'>
                    {[...uniqueItems].join(', ')}
                </Button>
            )
        }

        return null;
    }

    return (
        <div>
            <h2>{title}</h2>

            <PickListFilters deliveryRangeDate={deliveryRangeDate}
                             setDeliveryRangeDate={setDeliveryRangeDate}
                             isDeliveryRangeChanged={isDeliveryRangeChanged}
                             setIsDeliveryRangeChanged={setIsDeliveryRangeChanged}/>
            <Row align='middle' style={{marginBottom: '20px'}}>
                <PickListButtons activeButtons={activeButtons}
                                 setActiveButtons={setActiveButtons} picklists={picklists}/>
                <BulkOperations type='picklist' dashboardBulkFormElementsType='picklistsBulkFormElements'  actionsTypes={actionsTypes} itemType='orders'/>
            </Row>

            <div className={['pick-list-table', activeButtons.isViewListActive && 'visible'].join(' ')}>
                <AntTable rangeDate={deliveryRangeDate} initialData={picklists} loading={picklistLoading}
                          pagination={false} setSorting={setPicklistTableSorting}
                          columns={dashboardColumns({orderRender, type}).picklistColumns} isPickList={true}
                          total={picklists.total}/>
                {
                    picklists.objects.length > 0 &&
                    <div style={{marginTop: '20px'}}>
                        <p style={{marginBottom: '0'}}><b>Antal produkter:</b> {
                            picklists.objects.reduce((prev, next) => prev + (next.order_items?.reduce((prev, item) => +prev + +item.quantity, 0) ?? 0), 0)
                        }
                        </p>
                        <p><b>Summa:</b> {picklists.objects.reduce((prev, next) => prev + next.total, 0)}</p>
                    </div>
                }
            </div>

            {
                activeButtons.isViewTotalActive &&
                picklists.summary?.map((sum, i) => (
                        <React.Fragment key={sum.delivery_date + i}>
                            <b style={{marginBottom: '10px', display: 'block'}}>{sum.delivery_date}</b>
                            <PickListTables picklistLoading={picklistLoading} summary={sum}/>
                        </React.Fragment>
                    )
                )
            }
            <PickListOrderPopup>
                <PickListOrderPopupContent isAllOrders={isPrintAllButtonPressed}/>
            </PickListOrderPopup>
        </div>
    );
};

export default PickList;
