import {Outlet, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import menu from "./menu";
import MenuList from "../../components/Misc/MenuList/MenuList";
import {useDispatch, useSelector} from "react-redux";
import Login from "../Login/Login";
import Header from "../../components/Header/Header";
import {getTimeExpire} from "../../helpers/getTimeExpire";
import {refreshSession} from "../../helpers/auth";

const Dashboard = () => {
    let navigate = useNavigate();
    const {isAuth} = useSelector(state => state?.auth);
    const {tokenExpire} = useSelector(state => state?.login)

    useEffect(() => {
        if (!isAuth) {
            navigate('/');
        }
        console.log("IS AUTH 1 " + isAuth)

    }, [isAuth]);

    useEffect(() => {
        if (isAuth) {
            console.log("IS AUTH " + tokenExpire)
            /**
             * Refresh session token
             */
            if (!tokenExpire) return;
            const currentDay = Math.floor(new Date().getTime() / 1000);
            const millisecondsToExpiration = getTimeExpire(tokenExpire) - getTimeExpire(currentDay);
            setTimeout(() => {
                //refreshSession();
            }, millisecondsToExpiration)
        }

    }, [tokenExpire]);



    if (!isAuth) {
        return <Login/>
    }

    return (
        <div className='container'>
            <Header/>
            <div className='dashboard'>
                <aside className="dashboard__sidebar">
                    <div className="dashboard__sidebar--fixed">
                        <h4 className='dashboard__sidebar--title-nav'>Wetail Shipping</h4>
                        <div className='stage_name'>-{process.env.NAME}-</div>
                        <MenuList menu={menu}/>
                    </div>
                </aside>
                <div className='dashboard__content'>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
