import React, {forwardRef} from 'react';
import {Form, Table} from "antd";

const ParcelTable = forwardRef(({shipment, columns, ...props}, ref) => {
    const mergedColumns = columns(shipment).map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => {
                return {
                    record,
                    inputType: col.type === 'number' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                }
            },
        };
    });

    const handleFormValuesChange = (value) => {
        setIsFormChanged(true)
        let id = 0, isQty = false, qty = 0;

        Object.keys(value).forEach(val => {
            const splitVal = val.split('_id_');
            id = splitVal[1];

            if (splitVal[0].includes('quantity')) {
                isQty = true
                qty = value[val];
            }

        })

        const foundItem = shipment?.parcels.find(item => +item.id === +id)

        if (isQty && foundItem && Object.keys(foundItem).length > 0) {
            ref?.current.setFieldsValue({
                ['total_id_' + id] : +(foundItem.price ?? (foundItem.total / foundItem.quantity)) * +qty
            })
        }
    }
    return (
        <Form ref={ref} component={false} onValuesChange={handleFormValuesChange}>
            <Table bordered
                   rowSelection={false}
                   rowKey={record => record.id}
                   rowClassName="editable-row"
                   dataSource={shipment?.parcels}
                   pagination={false}
                   columns={mergedColumns}
                   {...props}
            />
        </Form>
    );
});

export default ParcelTable;
