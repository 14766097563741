import React, {useEffect, useState} from 'react';
import {Form, Select} from "antd";
import {getApiUrl, getParams} from "../helpers/helpers";
import useActions from "../hooks/useActions";

const SiteSelect = ({name, initialValue = null, onChange}) => {
    const [options, setOptions] = useState([]);
    const {customApiGet} = useActions()

    const params = { }

    useEffect(() => {
        customApiGet({
            url: `${getApiUrl('sites')}sites/${getParams(params)}`,
            successCallback: function (response) {
                setOptions([response.data])
            },
            errorCallback: function (error) {
            },
        })
    }, []);

    const renderSelectOptions = () => (
        options &&
        options.length > 0 &&
        options.map(option => {
            return (
                <Select.Option key={option.id + Math.random()} value={option.id} >{option.name}</Select.Option>
            )
        })
    )

    return (
        <Form.Item labelCol={4} wrapperCol={20} name={name} initialValue={initialValue} rules={[{required: true}]}>
            <Select style={{width: '100%'}} initialValue={initialValue} onChange={onChange}>
                {renderSelectOptions()}
            </Select>
        </Form.Item>
    );
};

export default SiteSelect;
