const initialState = {
    selected: [],
    isAvailable: false,
    isModalVisible: false,
    selectedRowKeys: [],
    refFound: false,
    bulkFormRef: null,
    loading: false,
}

export const SET_SELECTED = 'SET_SELECTED';
export const SET_SELECTED_ROW_KEYS = 'SET_SELECTED_ROW_KEYS';
export const SET_FORM_REF = 'SET_FORM_REF';
export const SET_REF_FOUND = 'SET_REF_FOUND';
export const SET_AVAILABLE = 'SET_AVAILABLE';
export const SET_BULK_LOADING = 'SET_BULK_LOADING';
export const SET_MODAL_VISIBLE = 'SET_MODAL_VISIBLE';

const bulkReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED:
            return {...state, selected: action.payload, loading: false};
        case SET_AVAILABLE:
            return {...state, isAvailable: action.payload, loading: false};
        case SET_FORM_REF:
            return {...state, bulkFormRef: action.payload};
        case SET_REF_FOUND:
            return {...state, refFound: action.payload};
        case SET_SELECTED_ROW_KEYS:
            return {...state, selectedRowKeys: action.payload};
        case SET_BULK_LOADING:
            return {...state, loading: action.payload};
        case SET_MODAL_VISIBLE:
            return {...state, isModalVisible: action.payload};
        default:
            return state;
    }
}

export default bulkReducer;
