import React, {useEffect} from 'react';

import {useParams} from "react-router-dom";

import useActions from "../../../hooks/useActions";

import {getTextFromType, renderTitleFromType} from "../../../helpers/helpers";
import {getActionTypes} from "../../../helpers/getActionTypes";

const ItemEdit = ({type, EditForm}) => {
    const {id} = useParams();
    const {getItem, setItemNull} = useActions();
    const itemsType = type.includes('-') ? type.replaceAll('-', '_') : type;

    const itemType = getTextFromType({
        string: itemsType,
        isRemoveSymbols: false,
        isRemoveLastAndAllToUppercase: true
    });

    useEffect(() => {
        if (type !== 'orders') {
            getItem({
                id,
                ...getActionTypes(type)
            });
        }

        return () => {
            setItemNull(`SET_${itemType}_NULL`)
        }
    }, []);

    return (
        <>
            <EditForm id={id}/>
        </>
    );
};

export default ItemEdit;
