import React, {useEffect} from 'react';
import {getColumns} from "../../helpers/getColumns";
import { Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../components/ant-table/dashboardColumns";
import AntTable from "../../components/AntTable/AntTable";
import {getActionTypes} from "../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../helpers/getUseSelectorFromType";

const ShippingServices = ({type = 'shipping_services', title = 'Frakttjänster'}) => {
    const {getItems, removeItem, setshippingServicesFiltering, setshippingServicesSorting, setshippingServicesPagination, setItemsNull} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const {id} = useParams();
    const location = useLocation();
    const {shippingServices, loading, pagination, shippingServicesTableSorting, shippingServicesTableFilters} = useSelector(state => state?.shippingServices);

    useEffect(() => {
        if (!location.pathname.includes('new') && !id) {
            getItems({
                params: {
                    ...(shippingServicesTableSorting && Object.keys(shippingServicesTableSorting).length > 0 && shippingServicesTableSorting?.order && {
                        order_by: shippingServicesTableSorting.field,
                        order_type: shippingServicesTableSorting.order.replace('end', '')
                    }),
                    ...{...(shippingServicesTableFilters && Object.keys(shippingServicesTableFilters).length > 0 && shippingServicesTableFilters)}
                },
                ...getActionTypes(type)
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        isRemovable:false,
    })

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <AntTable initialData={shippingServices} total={shippingServices?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setshippingServicesFiltering}
                                  setSorting={setshippingServicesSorting}
                                  setPagination={setshippingServicesPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                        />
                    </>
                }
            </>
    );
};

export default ShippingServices;
