export const getSwitchSitesForProducts = (record, restProps, switcher, dataIndex) => {
    const foundSiteIndex = record.sites.findIndex(_site => +_site.site_id === +restProps.site_id)
    const separateName = dataIndex.split('_name_');
    const site_name = separateName[1];
    const site_id = +separateName[0].split('site_')[1];
    const product = {...record};
    if (foundSiteIndex !== -1) {
        product.sites.map((item, index) => {
            if (index === foundSiteIndex) {
                item.active = switcher;
            }

            return item;
        })
    } else {
        product?.sites.push({
            site_name,
            site_id,
            active: switcher,
            product_id: record.id
        })
    }

    return product.sites.length > 0 && product.sites;
}
