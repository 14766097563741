import {
    SET_LOGIN_LOADING, SET_LOGIN_VALUES,
    SET_NEW_PASS_FORM_VISIBLE,
    SET_USER_ATTRIBUTES,
    SET_USER
} from "../reducers/loginReducer";
import {loginUser,setSessionData} from "../../helpers/auth";
import {message} from "antd";

export const loginActionCreator = {
    login: (values, tokenData = {}) => (dispatch, useState) => {

        console.log(values);
        console.log(tokenData);
        dispatch({type: SET_LOGIN_LOADING, payload: true})
        dispatch({type: SET_LOGIN_VALUES, payload: values})

        if (Object.keys(tokenData).length > 0) {
            return setSessionData({
                accessToken: tokenData.accessToken,
                refreshToken: tokenData.refreshToken,
                tokenExpire: tokenData.tokenExpire,
                userId: tokenData.userId,
                siteId: tokenData.siteId,
                dispatch
            })
        } else {
            loginUser(values).then(response => {
                console.log(response);
                setSessionData({
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token,
                    tokenExpire: response.token_expire,
                    userId: response.user_id,
                    siteId: response.site_id,
                    dispatch
                });
                dispatch({type: SET_LOGIN_LOADING, payload: false})
            })
            .catch(error => {
                message.error(error.toString());
                dispatch({type: SET_LOGIN_LOADING, payload: false})
            })
        }
    }
}
