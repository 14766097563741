const initialState = {
    dhlAccount: {},
    loading: false,
}

export const SET_DHL_ACCOUNT = 'SET_DHL_ACCOUNT';
export const SET_DHL_ACCOUNTS = 'SET_DHL_ACCOUNTS';
export const SET_DHL_ACCOUNTS_LOADING = 'SET_DHL_ACCOUNTS_LOADING';
export const SET_DHL_ACCOUNTS_PAGINATION = 'SET_PAGINATION';
export const UPDATE_DHL_ACCOUNT = 'UPDATE_DHL_ACCOUNT';
export const DELETE_DHL_ACCOUNT = 'DELETE_DHL_ACCOUNT';

const dhlAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DHL_ACCOUNTS_LOADING:
            return {...state, loading: action.payload};
        case SET_DHL_ACCOUNTS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_DHL_ACCOUNTS:
            return {...state, sites: action.payload, loading: false};
        case SET_DHL_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case UPDATE_DHL_ACCOUNT:
            return {...state, site: {...action.payload}, loading: false};
        case DELETE_DHL_ACCOUNT:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default dhlAccountReducer;
