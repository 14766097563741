import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import {allActionCreators} from "../state/actions/allActionCreators";

const useActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(allActionCreators, dispatch)
}

export default useActions;
