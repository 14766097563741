import React, {useState} from 'react';
import {SwapRightOutlined} from "@ant-design/icons";
import {Row, Input} from "antd";

const RangeInput = ({value = {}, onChange, isCustom = false, customMin = null, customMax = null, customSetMin = () => {},
                        customSetMax = () => {}, ...props}) => {

    const [min, setMin] = isCustom ? [customMin, customSetMin] : useState(null)
    const [max, setMax] = isCustom ? [customMax, customSetMax] : useState(null)

    const triggerChange = (changedValue) => {
        onChange?.({
            min,
            max,
            ...value,
            ...changedValue,
        });
    };

    const onNumberChange = (e, isMin = true) => {
        const newNumber = parseInt(e.target.value || '0', 10);
        if (Number.isNaN(isMin ? min : max)) return;

        if (!((isMin ? 'min' : 'max') in value)) {
            isMin ? setMin(newNumber) :  setMax(newNumber)
        }

        triggerChange({...(isMin ? {min: newNumber} : {max: newNumber})});
    };

    return (
        <Row justify='space-between' align='middle' {...props}>
          <Input
              type="text"
              value={isCustom ? min : value.min}
              onChange={e => onNumberChange(e)}
              style={{width: '40%'}}
          />
          <SwapRightOutlined style={{ width: '10%'}}/>
          <Input
              type="text"
              value={isCustom ? max : value.max}
              onChange={e => onNumberChange(e, false)}
              style={{width: '40%'}}
          />
    </Row>
    );
};

export default RangeInput;
