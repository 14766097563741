import React, {createRef, useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';

import {useSelector} from "react-redux";

import {SET_NOTIFICATION} from "../../../state/reducers/notificationsReducer";

import useActions from "../../../hooks/useActions";

import {Button, Col, message, Popconfirm, Row, Spin} from "antd";

import {getActionTypes} from "../../../helpers/getActionTypes";

import './notification-page.scss';

const NotificationPage = () => {
    const {id} = useParams();
    const formRef = createRef();
    const {notification, loading} = useSelector(state => state?.notifications)
    const {getItem, setItemNull} = useActions();

    useEffect(() => {
        getItem({
            id,
            ...getActionTypes('notifications'),
        });

        return () => {
            setItemNull(SET_NOTIFICATION)
        }

    }, []);

    if (Object.keys(notification).length === 0) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <>
            <Row justify='space-between' align='center'>
                <h2>Notis #{notification.id}</h2>
            </Row>

            <br/>
            <Row justify='space-between'>
                <span style={{display: 'block', marginBottom: '20px'}}>{notification.message}</span>
            </Row>
        </>
    );
};

export default NotificationPage;
