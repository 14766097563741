import {
    SET_FILTERS,
    SET_IS_FILTERED,
    SET_SORTING
} from "../reducers/tableFiltersReducer";

export const tableFiltersActions = {
    setTableFilters: (payload) => ({
        type: SET_FILTERS,
        payload
    }),
    setTableSorting: (payload) => ({
        type: SET_SORTING,
        payload
    }),
    setIsTableFiltered: (payload) => ({
        type: SET_IS_FILTERED,
        payload
    })
}
