const initialState = {
    woocommerceSettings: {},
    loading: false,
}
export const ADD_WOOCOMMERCE_SETTINGS = 'ADD_WOOCOMMERCE_SETTINGS';
export const SET_WOOCOMMERCE_SETTINGS = 'SET_WOOCOMMERCE_SETTINGS';
export const SET_WOOCOMMERCE_SETTINGS_LOADING = 'SET_WOOCOMMERCE_SETTINGS_LOADING';
export const SET_WOOCOMMERCE_SETTINGS_PAGINATION = 'SET_PAGINATION';
export const UPDATE_WOOCOMMERCE_SETTINGS = 'UPDATE_WOOCOMMERCE_SETTINGS';
export const DELETE_WOOCOMMERCE_SETTINGS = 'DELETE_WOOCOMMERCE_SETTINGS';

const woocommerceSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WOOCOMMERCE_SETTINGS_LOADING:
            return {...state, loading: action.payload};
        case SET_WOOCOMMERCE_SETTINGS_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_WOOCOMMERCE_SETTINGS:
            return {...state, defaultCustomsSettings: action.payload, loading: false};
        case UPDATE_WOOCOMMERCE_SETTINGS:
            return {...state, defaultCustomsSettings: {...action.payload}, loading: false};
        case DELETE_WOOCOMMERCE_SETTINGS:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default woocommerceSettingsReducer;
