import React, { useState } from 'react';
import axios from 'axios';
import shippingLabelsReducer from "../../state/reducers/shippingLabelsReducer";
import {Button, DatePicker} from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import locale from "antd/es/date-picker/locale/sv_SE";
import {getApiUrl} from "../../helpers/helpers";

const SyncView = () => {
    const [showDatetimeFieldOrders, setShowDatetimeFieldOrders] = useState(false);
    const [showDatetimeFieldProducts, setShowDatetimeFieldProducts] = useState(false);
    const [datetimeValue, setDatetimeValue] = useState('');
    const url = getApiUrl('orders')

    const handleOrdersCheckboxChange = (event) => {
        setShowDatetimeFieldOrders(event.target.checked);
    };
    const handleProductsCheckboxChange = (event) => {
        setShowDatetimeFieldProducts(event.target.checked);
    };

    const handleDatetimeChange = (date, dateString) => {
        setDatetimeValue(dateString);
    };

    const handleSettingsSyncButtonClick = () => {

      axios.post(`${url}synchronization/shipping_methods/`, { since_datetime: datetimeValue },{
        headers: {'AccessToken': localStorage.getItem('accessToken')}
        })//TODO REMOVE HARDCODED SITE ID
        .then((response) => {
          console.log('POST request successful:', response.data);
        })
        .catch((error) => {
          console.error('Error making POST request:', error);
        }
        );
    };
    const handleOrderSyncButtonClick = (datetimeValue, type) => {
        const userId = localStorage.getItem('userId');
        if (type === 'ORDERS') {
          axios.post(`${url}synchronization/orders/`, { since_datetime: datetimeValue },{
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            })//TODO REMOVE HARDCODED SITE ID
            .then((response) => {
              console.log('POST request successful:', response.data);
            })
            .catch((error) => {
              console.error('Error making POST request:', error);
            });
        } else {
          axios.post(`${url}synchronization/products/`, { since_datetime: datetimeValue },{
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            })
            .then((response) => {
              console.log('POST request successful:', response.data);
            })
            .catch((error) => {
              console.error('Error making POST request:', error);
            });
        }
    };

    return (
        <div>
          <h3 style={{ marginRight: '10px' }}>Synkronisera ordrar</h3>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginRight: '10px' }}>
            <Checkbox checked={showDatetimeFieldOrders} onChange={handleOrdersCheckboxChange} style={{ marginLeft: '10px', marginRight: '10px' }}>
              Ange datum för synkronisering
            </Checkbox>
            {showDatetimeFieldOrders && (
              <DatePicker
                mode='date'
                allowClear={false}
                style={{ marginRight: '10px' }}
                onChange={handleDatetimeChange}
              />
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: '1' }}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                onClick={() => handleOrderSyncButtonClick(datetimeValue, 'ORDERS')}
              >
                Synkronisera
              </Button>
            </div>
          </div>
          <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' }}></div>
          <h3 style={{ marginRight: '10px' }}>Synkronisera produkter</h3>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginRight: '10px' }}>
            <Checkbox checked={showDatetimeFieldProducts} onChange={handleProductsCheckboxChange} style={{ marginLeft: '10px', marginRight: '10px' }}>
              Ange datum för synkronisering
            </Checkbox>
            {showDatetimeFieldProducts && (
              <DatePicker
                mode='date'
                allowClear={false}
                style={{ marginRight: '10px' }}
                onChange={handleDatetimeChange}
              />
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: '1' }}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                onClick={() => handleOrderSyncButtonClick(datetimeValue, 'PRODUCTS')}
              >
                Synkronisera
              </Button>
            </div>
          </div>
          <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' }}></div>
          <h3 style={{ marginRight: '10px' }}>Hämta inställningar</h3>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginRight: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: '1' }}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                onClick={() => handleSettingsSyncButtonClick()}
              >
                Hämta
              </Button>
            </div>
          </div>
        </div>

    );
}

export default SyncView
