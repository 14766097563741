import React, {useEffect} from 'react';
import {getColumns} from "../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../components/ant-table/dashboardColumns";
import {Row} from "antd";
import AntTable from "../../components/AntTable/AntTable";
import {getTextFromType} from "../../helpers/helpers";
import {getActionTypes} from "../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../helpers/getUseSelectorFromType";

const Orders = ({type = 'orders', title = 'Ordrar'}) => {
    const {getItems, removeItem, setOrdersSorting, setOrdersFiltering} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const dashboardBookFormElementsType = useSelectorType + 'BookFormElements';
    const {id} = useParams();
    const location = useLocation();
    const {orders, loading, pagination, ordersTableSorting, ordersTableFilters} = useSelector(state => state?.orders);

    useEffect(() => {
        if (!location.pathname.includes('new')) {
            getItems({
                ...getActionTypes(type),
                params: {
                    ...(ordersTableSorting && Object.keys(ordersTableSorting).length > 0 && ordersTableSorting?.order && {
                        order_by: ordersTableSorting.field,
                        order_type: ordersTableSorting.order.replace('end', '')
                    }),
                    ...{...(ordersTableFilters && Object.keys(ordersTableFilters).length > 0 && ordersTableFilters)},
                }
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        isRemovable: false,
        cols: dashboardColumns({type})[dashboardType],
        removing: (id) => {
            removeItem({
                id,
                ...getActionTypes(type),
                contentText: `${getTextFromType({string: type})} med ID ${id} har raderats`
            })
        },
    })

    return (
        id ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                <Row className='filter-block' justify='space-between' align='middle'>
                </Row>

                <AntTable initialData={orders} total={orders?.total} loading={loading}
                          pagination={pagination} columns={columns} type='orders'
                          setSorting={setOrdersSorting}
                          setFilters={setOrdersFiltering}
                          getItemsDynamicData={getActionTypes(type)}
                />
            </>
    );
};

export default Orders;
