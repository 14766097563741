const initialState = {
    shippingServices: {
        objects: [],
        total: 0,
        page: 1,
    },
    shippingService: {},
    pagination: {
        current: 1,
        pageSize: 25,
    },
    shippingServicesTableFilters: {},
    shippingServicesTableSorting: {},
    shippingServicesTablePagination: {},
    loading: false,
}

export const SET_SHIPPING_SERVICES = 'SET_SHIPPING_SERVICES';
export const SET_SHIPPING_SERVICE = 'SET_SHIPPING_SERVICE';
export const SET_SHIPPING_SERVICES_LOADING = 'SET_SHIPPING_SERVICES_LOADING';
export const SET_SHIPPING_SERVICES_PAGINATION = 'SET_SHIPPING_SERVICES_PAGINATION';
export const UPDATE_SHIPPING_SERVICE = 'UPDATE_SHIPPING_SERVICE';
export const SET_SHIPPING_SERVICES_TABLE_FILTERS = 'SET_SHIPPING_SERVICES_TABLE_FILTERS';
export const SET_SHIPPING_SERVICES_TABLE_SORTING = 'SET_SHIPPING_SERVICES_TABLE_SORTING';
export const SET_SHIPPING_SERVICES_TABLE_PAGINATION = 'SET_SHIPPING_SERVICES_TABLE_PAGINATION';

const shippingServicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIPPING_SERVICES_LOADING:
            return {...state, loading: action.payload};
        case SET_SHIPPING_SERVICES_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_SHIPPING_SERVICES:
            return {...state, shippingServices: action.payload, loading: false};
        case SET_SHIPPING_SERVICE:
            return {...state, shippingService: {...action.payload}, loading: false};
        case UPDATE_SHIPPING_SERVICE:
            return {...state, shippingService: {...action.payload}, loading: false};
        case SET_SHIPPING_SERVICES_TABLE_FILTERS:
            return {...state, shippingServicesTableFilters: action.payload};
        case SET_SHIPPING_SERVICES_TABLE_SORTING:
            return {...state, shippingServicesTableSorting: action.payload};
        case SET_SHIPPING_SERVICES_TABLE_PAGINATION:
            return {...state, shippingServicesTablePagination: action.payload};
        default:
            return state;
    }
}

export default shippingServicesReducer
