import React, {createRef, useEffect, useState} from 'react';
import {Button, Form, Row, Select, Spin} from 'antd';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {SET_SYNCHRONIZATION_JOB} from "../../state/reducers/synchronizationJobsReducer";

import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import "./EditSynchronizationJobForm.scss";
import SiteSelect from "../../components/SiteSelect";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditSynchronizationJobForm = ({id}) => {
    const {updateItem, setItemNull} = useActions();
    const [isChanged, setIsChanged] = useState(false);
    const {synchronizationJob, loading} = useSelector(state => state?.synchronizationJobs);
    const [selectedSlaveSystem, setSelectedSlaveSystem] = useState(null);
    const [selectedSyncFrequency, setSelectedSyncFrequency] = useState(null);
    const [selectedJobType, setSelectedJobType] = useState(null);
    const formRef = createRef();
    const {Option} = Select;
    const navigate = useNavigate();

    const slaveSystemOptions = [
      { value: 1, label: 'WooCommerce' }
    ];

    const syncFrequencyOptions = [
      { value: 'every_2_minutes', label: 'Varannan minut' },
      { value: 'daily', label: 'Dagligen' }
    ];

    const jobTypeOptions = [
      { value: 'sync_products', label: 'Produkter' },
      { value: 'sync_orders', label: 'Ordrar' }
    ];

    useEffect(() => {
        return () => {
            setItemNull(SET_SYNCHRONIZATION_JOB);
        };
    }, []);

    const handleSelectSlaveSystem = (selectedOption) => {
        setSelectedSlaveSystem(selectedOption);
    };
    const handleSelectSyncFrequency = (selectedOption) => {
        setSelectedSyncFrequency(selectedOption);
    };
    const handleSelectJobType = (selectedOption) => {
        setSelectedJobType(selectedOption);
    };

    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    const finishHandler = (values) => {
        updateItem({
            values: values,
            id,
            ...getActionTypes('synchronization_jobs'),
        });

        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/synchronization_jobs');
    };

    if (Object.keys(synchronizationJob).length === 0) {
        return <Row data-testid='spinner' justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>;
    }

    return (
        <>
            <Form {...layout}
              ref={formRef}
              layout="vertical"
              data-testid='edit-synchronization-jobs-form'
              name="edit-synchronization-jobs"
              onFinish={finishHandler}
              initialValues={{}}
              style={{width: '100%'}}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}>

                <Form.Item label="Site" rules={[{required: true}]}>
                    <SiteSelect name={'site_id'} initialValue={synchronizationJob.site_id}/>
                </Form.Item>
                <Form.Item
                    label="Slave System"
                    name="slave_system_id"
                    initialValue={synchronizationJob.slave_system_id}
                    rules={[{required: true}]}>
                    <Select
                        value={selectedSlaveSystem}
                        onChange={handleSelectSlaveSystem}
                        options={slaveSystemOptions}
                    />
                </Form.Item>
                <Form.Item
                    label="Synkronisationstyp"
                    initialValue={synchronizationJob.synchronization_type}
                    name="synchronization_type"
                    rules={[{required: true}]}>
                    <Select
                        initialValue={selectedJobType}
                        onChange={handleSelectJobType}
                        options={jobTypeOptions}
                    />
                </Form.Item>
                <Form.Item
                    label="Synk frekvens"
                    name="sync_frequency"
                    initialValue={synchronizationJob.sync_frequency}
                    rules={[{required: true}]}>
                    <Select
                        initialValue={setSelectedSyncFrequency}
                        onChange={handleSelectSyncFrequency}
                        options={syncFrequencyOptions}
                    />
                </Form.Item>
                <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
                <Form.Item>
                    <Row>
                        {
                            isChanged ?
                                <Button style={{marginRight: '20px'}} type="primary" loading={loading}
                                        htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
};

export default EditSynchronizationJobForm;
