import {
    SET_AVAILABLE, SET_FORM_REF, SET_REF_FOUND,
    SET_SELECTED,
    SET_SELECTED_ROW_KEYS
} from "../reducers/printReducer";

export const printOperationsAction = {
    setSelectedRows: (payload) => {
        return {
            type: SET_SELECTED,
            payload
        }
    },
    setPrintOperationsAvailable: (payload) => {
        return {
            type: SET_AVAILABLE,
            payload
        }
    },
    setSelectedRowKeys: (payload) => {
        return {
            type: SET_SELECTED_ROW_KEYS,
            payload
        }
    },
    setPrintFormRef: (payload) => {
        return {
            type: SET_FORM_REF,
            payload
        }
    },
    setPrintFormRefFound: (payload) => {
        return {
            type: SET_REF_FOUND,
            payload
        }
    }

}

export const setSelectedRows = (payload) => {
    return {
        type: SET_SELECTED,
        payload
    }
}

export const setPrintOperationsAvailable = (payload) => {
    return {
        type: SET_AVAILABLE,
        payload
    }
}

export const setIsPrintModalVisible = (payload) => {
    return {
        type: SET_MODAL_VISIBLE,
        payload
    }
}

export const setSelectedRowKeys = (payload) => {
    return {
        type: SET_SELECTED_ROW_KEYS,
        payload
    }
}

export const setPrintFormRef = (payload) => {
    return {
        type: SET_FORM_REF,
        payload
    }
}

export const setPrintFormRefFound = (payload) => {
    return {
        type: SET_REF_FOUND,
        payload
    }
}



