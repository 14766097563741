import {callApi, getApiUrl, getParams} from "../../helpers/helpers";
import {
    SET_PICKLISTS_LOADING,
    SET_PICKLISTS_PAGINATION,
    SET_PICKLISTS_FILTERS,
    SET_PICKLISTS,
    SET_IS_VIEW_PICKLISTS_TABLE_ACTIVE,
    SET_IS_VIEW_TOTAL_TABLE_ACTIVE,
    SET_PICK_LIST_ORDER_POPUP,
    SET_PICK_LIST_PRODUCTS_NAMES,
    SET_PICK_LISTS_IS_PRINT_ALL_BUTTON_PRESSED, SET_PICKLISTS_FORM_REF, SET_PICKLISTS_TABLE_SORTING
} from "../reducers/picklistReducer";

export const picklistActions = {
    getPicklists: ({params = {}}) => dispatch => {
        dispatch({type: SET_PICKLISTS_LOADING, payload: true})
        const {page} = params;
        return callApi({
            url: `${getApiUrl('orders')}pick_list/${getParams(params)}`,
            method: 'get',
            token: localStorage.getItem('token')?.replaceAll('"', ''),
            successCallback: (res) => {
                dispatch({type: SET_PICKLISTS, payload: res.data})
                dispatch({
                    type: SET_PICKLISTS_PAGINATION,
                    payload: {
                        pageSize: 25,
                        current: page ?? 1
                    }
                })
            },
            setLoading: () => {
                dispatch({type: SET_PICKLISTS_LOADING, payload: false})
            }
        })
    },
    setPickListOrderPopup: payload => {
        return {
            type: SET_PICK_LIST_ORDER_POPUP,
            payload
        }
    },
    setPicklistFilters: payload => {
        return {
            type: SET_PICKLISTS_FILTERS,
            payload
        }
    },
    setIsViewPicklistsTableActive: payload => (
        {
            type: SET_IS_VIEW_PICKLISTS_TABLE_ACTIVE,
            payload
        }
    ),
    setIsViewTotalTableActive: payload => (
        {
            type: SET_IS_VIEW_TOTAL_TABLE_ACTIVE,
            payload
        }
    ),
    setPicklistProductsNames: payload => (
        {
            type: SET_PICK_LIST_PRODUCTS_NAMES,
            payload
        }
    ),
    setIsPrintAllButtonPressed: payload => (
        {
            type: SET_PICK_LISTS_IS_PRINT_ALL_BUTTON_PRESSED,
            payload
        }
    ),
    setPicklistFormRef: payload => (
        {
            type: SET_PICKLISTS_FORM_REF,
            payload
        }
    ),
    setPicklistTableSorting: payload => (
        {
            type: SET_PICKLISTS_TABLE_SORTING,
            payload
        }
    )
}
