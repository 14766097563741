import React, {useState, useEffect} from 'react';
import {Button, Form, Input, InputNumber, Row} from 'antd';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";

import {
    SET_ONGOING_ACCOUNT, UPDATE_ONGOING_ACCOUNT
} from "../../state/reducers/ongoingAccountReducer";


const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const EditOngoingAccountForm = ({id}) => {
    const {updateItem, getItem} = useActions()
    const [ongoingAccount, setOngoingAccount] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const navigate = useNavigate();
    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    useEffect(() => {
        getOngoingAccount();
    }, []);

    const getOngoingAccount = () => {
        getItem({
              type:'ongoing_account',
              setItemActionType: 'SET_ONGOING_ACCOUNT'
        });
    }

    const finishHandler = (values) => {
        values.site_id = localStorage.getItem('siteId');
        updateItem({
            values,
            id,
            ...getActionTypes('ongoing_account'),
            contentText: `Du har uppdaterat ditt Ongoing konto`
        });
        setIsChanged(false);
    };

    const cancelHandler = () => {
        navigate('/');
    };
    console.log(ongoingAccount);

    const renderEditOngoingAccount = () => {
        if (!ongoingAccount) {
            return (
                <Form {...layout}
                  name="edit-best_account"
                  layout="vertical"
                  onFinish={finishHandler}
                  onValuesChange={valuesChangeHandler}>
                <h2>Ongoing Konto</h2>
                <Form.Item
                    name='warehouse_id'
                    label="Warehouse ID"
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='goods_owner_id'
                    label="Goods owner ID"
                    rules={[{ required: true, }, ]}
                ><InputNumber/>
                </Form.Item>
                <Form.Item
                    name='goods_owner_code'
                    label="Goodsowner Code"
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='automation_api_username'
                    label="Automation API Username"
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='automation_api_password'
                    label="Automation API Password"
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='rest_api_username'
                    label="REST API Username"
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='rest_api_password'
                    label="REST API Password"
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <div style={{borderBottom: '1px solid #d9d9d9', marginBottom: '10px', marginTop: '10px'}}></div>
                <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                    <Row justify="space-between">
                        {
                            isChanged ?
                                <Button type="primary" htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
            );
        }

        return (
            <Form {...layout}
                  name="edit-ongoing_account"
                  layout="vertical"
                  onFinish={finishHandler}
                  onValuesChange={valuesChangeHandler}>
                <h2>Ongoing Konto</h2>
                <Form.Item
                    name='warehouse_id'
                    label="Warehouse ID"
                    initialValue={ongoingAccount.warehouse_id}
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='goods_owner_id'
                    label="Goods owner ID"
                    initialValue={ongoingAccount.goods_owner_id}
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='goods_owner_code'
                    label="Goodsowner Code"
                    initialValue={ongoingAccount.goods_owner_code}
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='automation_api_username'
                    label="Automation API Username"
                    initialValue={ongoingAccount.automation_api_username}
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='automation_api_password'
                    label="Automation API Password"
                    initialValue={ongoingAccount.automation_api_password}
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='rest_api_username'
                    label="REST API Username"
                    initialValue={ongoingAccount.rest_api_username}
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <Form.Item
                    name='rest_api_password'
                    label="REST API Password"
                    initialValue={ongoingAccount.rest_api_password}
                    rules={[{ required: true, }, ]}
                ><Input/>
                </Form.Item>
                <div style={{borderBottom: '1px solid #d9d9d9', marginBottom: '10px', marginTop: '10px'}}></div>
                <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                    <Row justify="space-between">
                        {
                            isChanged ?
                                <Button type="primary" htmlType="submit">
                                    Spara
                                </Button> :
                                <Button type="primary" ghost onClick={cancelHandler}>
                                    Gå tillbaka
                                </Button>
                        }
                        {
                            isChanged ?
                                <Button type="default" danger onClick={cancelHandler}>
                                    Avbryt
                                </Button> :
                                null
                        }
                    </Row>
                </Form.Item>
            </Form>
        )
    };
    return (
        renderEditOngoingAccount()
    );
};

export default EditOngoingAccountForm;
