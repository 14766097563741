import React, {useState} from 'react';
import {Button, Form, Input, Select} from "antd";
import {countriesWithCode} from "../../../../helpers/helpers";
import useActions from "../../../../hooks/useActions";

const OrderCustomerDetailsForm = ({id, type = 'shipping', setModalVisible, details, orderDetailsTranslations}) => {
    const [isChanged, setIsChanged] = useState(false)
    const {updateOrderCustomerDetails} = useActions()

    const finishHandler = (values) => {
        if (isChanged) {
            const newValues = Object.fromEntries(Object.entries({...values}).map(([key, value]) => [`${type}${key}`, value]))
            updateOrderCustomerDetails({
                id,
                values: newValues,
                contentText: 'Inställningarna har uppdaterats'
            })
            setIsChanged(false);
            setModalVisible(false)
        }
    }

    return (
     <>
         <h2 style={{marginBottom: '20px'}}>Ändra {type}-inställningar</h2>
         <Form onFinish={finishHandler} onValuesChange={() => setIsChanged(true)} name={`${type}-customer`} labelCol={{span: 5}} wrapperCol={{span: 18}}>
             {
                 details &&
                 Object.keys(details).map(detail => {
                     return (
                         <Form.Item key={detail} initialValue={details[detail]} name={detail} label={orderDetailsTranslations[detail]}>
                             {
                                 detail === '_country' ?
                                 <Select showSearch  filterOption={(input, option) =>
                                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                 }>
                                     {
                                         Object.keys(countriesWithCode).map(country => {
                                             return <Select.Option key={country} value={country}>{countriesWithCode[country]}</Select.Option>
                                         })
                                     }
                                 </Select>
                                 : <Input/>
                             }
                         </Form.Item>
                     )
                 })
             }
             <Form.Item>
                 <Button disabled={!isChanged} type='primary' htmlType='submit'>Skicka</Button>
             </Form.Item>
         </Form>
     </>
    );
};

export default OrderCustomerDetailsForm;
