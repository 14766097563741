import { combineReducers } from 'redux';
import productsReducer from './productsReducer';
import bulkReducer from "./bulkReducer";
import printReducer from "./printReducer";
import authReducer from "./authReducer";
import loginReducer from "./loginReducer";
import ordersReducer from "./ordersReducer";
import productAttributeReducer from "./productAttributeReducer";
import picklistReducer from "./picklistReducer";
import rangeDateReducer from "./rangeDateReducer";
import tableFiltersReducer from "./tableFiltersReducer";
import shippingServicesReducer from "./shippingServicesReducer";
import shippingServiceMappingsReducer from "./shippingServiceMappingsReducer";
import shipmentsReducer from "./shipmentsReducer";
import shippingLabelReducer from "./shippingLabelsReducer";
import sitesReducer from "./sitesReducer";
import notificationsReducer from "./notificationsReducer";
import sendersReducer from "./sendersReducer";
import defaultCustomsSettingsReducer from "./defaultCustomsSettingsReducer";
import printerSettingsReducer from "./printerSettingsReducer";
import postnordAccountReducer from "./postnordAccountReducer";
import dhlAccountReducer from "./dhlAccountReducer";
import woocommerceSettingsReducer from "./woocommerceSettingsReducer";
import synchronizationJobsReducer from "./synchronizationJobsReducer";
import budbeeAccountReducer from "./budbeeAccountReducer";
import dbschenkerAccountReducer from "./dbschenkerAccountReducer";
import bestAccountReducer from "./bestAccountReducer";
import userCarriersReducer from "./userCarriersReducer";
import ongoingAccountReducer from "./ongoingAccountReducer";

export const rootReducer = combineReducers( {
    products: productsReducer,
    orders: ordersReducer,
    bulkOperations: bulkReducer,
    printOperations: printReducer,
    auth: authReducer,
    site: sitesReducer,
    login: loginReducer,
    productAttribute: productAttributeReducer,
    picklist: picklistReducer,
    rangeDate: rangeDateReducer,
    tableFilters: tableFiltersReducer,
    shippingLabels: shippingLabelReducer,
    shipments: shipmentsReducer,
    shippingServiceMappings: shippingServiceMappingsReducer,
    shippingServices: shippingServicesReducer,
    notifications: notificationsReducer,
    senders: sendersReducer,
    printerSettings: printerSettingsReducer,
    defaultCustomsSettings: defaultCustomsSettingsReducer,
    postnordAccounts: postnordAccountReducer,
    dhlAccounts: dhlAccountReducer,
    budbeeAccounts: budbeeAccountReducer,
    woocommerceSettings: woocommerceSettingsReducer,
    synchronizationJobs: synchronizationJobsReducer,
    dbschenkerAccounts: dbschenkerAccountReducer,
    bestAccounts: bestAccountReducer,
    userCarriers: userCarriersReducer,
    ongoingAccounts: ongoingAccountReducer,
} );
