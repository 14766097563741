import React, {useEffect} from 'react';
import {getColumns} from "../../helpers/getColumns";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../components/ant-table/dashboardColumns";
import AntTable from "../../components/AntTable/AntTable";
import {getActionTypes} from "../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../helpers/getUseSelectorFromType";
import {PlusCircleOutlined} from "@ant-design/icons";
import {Row} from "antd";

const Senders = ({type = 'senders', title = 'Avsändare'}) => {
    const {getItems, removeItem, setSendersFiltering, setSendersSorting, setSendersPagination, setItemsNull} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const {id} = useParams();
    const location = useLocation();
    const {senders, loading, pagination, sendersTableSorting, sendersTableFilters} = useSelector(state => state?.senders);

    useEffect(() => {
        if (!location.pathname.includes('new') && !id) {
            getItems({
                params: {
                    ...(sendersTableSorting && Object.keys(sendersTableSorting).length > 0 && sendersTableSorting?.order && {
                        order_by: sendersTableSorting.field,
                        order_type: sendersTableSorting.order.replace('end', '')
                    }),
                    ...{...(sendersTableFilters && Object.keys(sendersTableFilters).length > 0 && sendersTableFilters)}
                },
                ...getActionTypes(type)
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        isRemovable:true,
    })

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <Row>
                            <Link to='new'>
                                <PlusCircleOutlined style={{fontSize: '25px', color: '#1890ff'}}/>
                            </Link>
                        </Row>
                        <AntTable initialData={senders} total={senders?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setSendersFiltering}
                                  setSorting={setSendersSorting}
                                  setPagination={setSendersPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                        />
                    </>

                }
            </>
    );
};

export default Senders;
