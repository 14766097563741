import {
    SET_CONFIRM_USER_VALUES, SET_CONFIRM_USER_LOADING
} from "../reducers/confirmUserReducer";
import {confirmUser} from "../../helpers/auth";
import {message} from "antd";

export const confirmUserActionCreator = {
    confirm: (values) => (dispatch, useState) => {

        console.log(values);
        dispatch({type: SET_CONFIRM_USER_LOADING, payload: true})
        dispatch({type: SET_CONFIRM_USER_VALUES, payload: values})

        confirmUser(values).then(response => {
                console.log(response);
        })
        .catch(error => {
            message.error(error.toString());
            dispatch({type: SET_CONFIRM_USER_LOADING, payload: false})
        })
    }
}
