import React, {useEffect} from 'react';
import {getColumns} from "../../helpers/getColumns";
import { Outlet, useLocation, useParams} from "react-router-dom";
import useActions from "../../hooks/useActions";
import {useSelector} from "react-redux";
import {dashboardColumns} from "../../components/ant-table/dashboardColumns";
import AntTable from "../../components/AntTable/AntTable";
import {getActionTypes} from "../../helpers/getActionTypes";
import {getUseSelectorFromType} from "../../helpers/getUseSelectorFromType";

const ShippingLabels = ({type = 'shippingLabels', title = 'Försändelser'}) => {
    const {getItems, removeItem, setShippingLabelsFiltering, setShippingLabelsSorting, setShippingLabelsPagination, setItemsNull} = useActions();
    const useSelectorType = getUseSelectorFromType(type);
    const dashboardType = useSelectorType + 'Columns';
    const {id} = useParams();
    const location = useLocation();
    const {shippingLabels, loading, pagination, shippingLabelsTableSorting, shippingLabelsTableFilters} = useSelector(state => state?.shippingLabels);

    useEffect(() => {
        if (!location.pathname.includes('new') && !id) {
            getItems({
                params: {
                    ...(shippingLabelsTableSorting && Object.keys(shippingLabelsTableSorting).length > 0 && shippingLabelsTableSorting?.order && {
                        order_by: shippingLabelsTableSorting.field,
                        order_type: shippingLabelsTableSorting.order.replace('end', '')
                    }),
                    ...{...(shippingLabelsTableFilters && Object.keys(shippingLabelsTableFilters).length > 0 && shippingLabelsTableFilters)}
                },
                ...getActionTypes(type)
            });
        }
    }, [location]);

    let columns = getColumns({
        type,
        cols: dashboardColumns({type})[dashboardType],
        isRemovable:false,
    })

    return (
        id || location.pathname.includes('/new') ? <Outlet/>
            :
            <>
                <h2 className='dashboard__content--title'>{title}</h2>
                {
                    <>
                        <AntTable initialData={shippingLabels} total={shippingLabels?.total} loading={loading}
                                  pagination={pagination} columns={columns} type={type}
                                  setFilters={setShippingLabelsFiltering}
                                  setSorting={setShippingLabelsSorting}
                                  setPagination={setShippingLabelsPagination}
                                  getItemsDynamicData={getActionTypes(type)}
                        />
                    </>
                }
            </>
    );
};

export default ShippingLabels;
