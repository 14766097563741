import {cleanPayload, callApi, getApiUrl, getParams, payloadNullToQuotes, getApiPath} from "../../helpers/helpers";
import {message} from "antd";
import {SET_IS_FILTERED} from "../reducers/tableFiltersReducer";
import {getActionTypes} from "../../helpers/getActionTypes";

export const apiActions = {
    addItem: ({
                  values,
                  type = 'products',
                  addItemActionType = 'ADD_PRODUCT',
                  setLoadingActionType = 'SET_PRODUCTS_LOADING',
                  contentText = 'Produkten har uppdaterats',
                  isMessage = true,
                  product = {},
              }) => dispatch => {
        dispatch({type: setLoadingActionType, payload: true})
        const multipart = type === 'images' ? {'Content-Type': 'multipart/form-data'} : {};

        return callApi({
            url: `${getApiUrl(type)}${getApiPath(type)}/`,
            method: 'post',
            values: type === 'images' ? values : cleanPayload(values),
            multipart,
            successCallback: (res) => {
                if (isMessage) {
                    message.success({content: contentText, duration: 2});
                }
                dispatch({type: addItemActionType, payload: res.data})
                dispatch({type: setLoadingActionType, payload: false})
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    getItem: ({
                  id,
                  type = 'products',
                  setItemActionType = 'SET_PRODUCT',
                  setLoadingActionType = 'SET_PRODUCTS_LOADING',
              }) => dispatch => {
        dispatch({type: setLoadingActionType, payload: true})
        dispatch({type: setItemActionType, payload: {}})
        return callApi({
            url:  typeof id !== "undefined" ? `${getApiUrl(type)}${getApiPath(type)}/${id}/` : `${getApiUrl(type)}${getApiPath(type)}/`,
            method: 'get',
            successCallback: (res) => {
                dispatch({type: setItemActionType, payload: res.data})
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    customApiGet: ({ url, successCallback, errorCallback }) => dispatch => {
        return callApi({
                url:  url,
                method: 'get',
                successCallback: successCallback,
                errorCallback: errorCallback
        })
    },
    getItems: ({
                   params = {},
                   type = 'products',
                   setItemsActionType = 'SET_PRODUCTS',
                   setPaginationActionType = 'SET_PRODUCTS_PAGINATION',
                   setLoadingActionType = 'SET_PRODUCTS_LOADING',
                   filters = {},
                   sorting = {},
                   isFiltered = false,
                   disposable_quantity = null,
               }) => dispatch => {
        const {page} = params;
        dispatch({type: setLoadingActionType, payload: true})
        if (isFiltered && filters) {
            params = {...params, ...filters}
            dispatch({type: SET_IS_FILTERED, payload: false})
        }

        return callApi({
            url: `${getApiUrl(type)}${getApiPath(type)}/${getParams(params)}`,
            method: 'get',
            successCallback: (res) => {
                console.log(res.data)
                dispatch({type: setItemsActionType, payload: res.data})
                dispatch({
                    type: setPaginationActionType,
                    payload: {
                        pageSize: 25,
                        current: page ?? 1
                    }
                })
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    removeItem: ({
                     id,
                     setItemsActionType,
                     setPaginationActionType,
                     type = 'products',
                     itemDeleteActionType = 'DELETE_PRODUCT',
                     setLoadingActionType = 'SET_PRODUCTS_LOADING',
                     contentText = 'You are successfully removed product with id',
                     filters = null,
                     isFiltered = false,
                 }) => dispatch => {

        dispatch({type: setLoadingActionType, payload: true})

        return callApi({
            url: `${getApiUrl(type)}${getApiPath(type)}/${id}/`,
            method: 'delete',
            successCallback: (res) => {
                dispatch({type: itemDeleteActionType, payload: res.data})
                dispatch(apiActions.getItems({
                    type,
                    setItemsActionType,
                    setPaginationActionType,
                    setLoadingActionType,
                    filters,
                    isFiltered
                }));
                message.success({content: contentText, duration: 2});
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    updateItem: ({
                     values,
                     id,
                     type = 'products',
                     updateActionType = 'UPDATE_PRODUCT',
                     setLoadingActionType = 'SET_PRODUCTS_LOADING',
                     contentText = 'Produkten har uppdaterats',
                     shouldUpdateItems = false,
                     currentPage = 1,
                     allowNull = false,
                     isMessage = true,
                     needRefreshProducts = false,
                     params = { page: currentPage },
                 }) => (dispatch, getState) => {
        dispatch({type: setLoadingActionType, payload: true})
        console.log('Updating ' + type)
        return callApi({
            url:  typeof id !== "undefined" ? `${getApiUrl(type)}${getApiPath(type)}/${id}/` : `${getApiUrl(type)}${getApiPath(type)}/`,
            method: 'put',
            values: Object.keys(values).length === 0 ? {} : (allowNull ? payloadNullToQuotes(values) : cleanPayload(values)),
            successCallback: (res) => {
                if (isMessage) {
                    message.success({content: `${contentText}`, duration: 2});
                }
                dispatch({type: updateActionType, payload: res.data});
                dispatch({type: setLoadingActionType, payload: false})

                if (shouldUpdateItems) {
                    dispatch(apiActions.getItems({
                        ...getActionTypes(type),
                        params: cleanPayload(params)
                    }));
                }

                if (needRefreshProducts) {
                    dispatch(apiActions.getItems({
                            ...getActionTypes('products'),
                            params
                        }));
                }
            },
            setLoading: () => {
                dispatch({type: setLoadingActionType, payload: false})
            }
        })
    },
    setItemNull: (SET_ITEM, payload = {}) => {
        return {
            type: SET_ITEM,
            payload
        }
    },
    setItemsNull: (SET_ITEMS) => {
        return {
            type: SET_ITEMS,
            payload: {}
        }
    }
}
