const initialState = {
    site: {},
    loading: false,
}

export const SET_SITE = 'SET_SITE';
export const SET_SITES = 'SET_SITES';
export const SET_SITES_LOADING = 'SET_SITES_LOADING';
export const SET_SITES_PAGINATION = 'SET_PAGINATION';
export const UPDATE_SITE = 'UPDATE_SITE';
export const DELETE_SITE = 'DELETE_SITE';

const sitesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SITES_LOADING:
            return {...state, loading: action.payload};
        case SET_SITES_PAGINATION:
            return {...state, pagination: action.payload};
        case SET_SITES:
            return {...state, sites: action.payload, loading: false};
        case SET_SITE:
            return {...state, site: {...action.payload}, loading: false};
        case UPDATE_SITE:
            return {...state, site: {...action.payload}, loading: false};
        case DELETE_SITE:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default sitesReducer;
