import React, {useState} from 'react';
import {useSelector} from "react-redux";
import useActions from "../../hooks/useActions";
import PrintBlock from "./PrintBlock/PrintBlock";
import axios from "axios";
import {getApiUrl} from "../../helpers/helpers";
import {message, Modal} from "antd";

const PrintOperations = ({actionsTypes}) => {
    const { selected } = useSelector(state => state?.printOperations);
    const {setSelectedRows, setPrintOperationsAvailable, setSelectedRowKeys, setPrintFormRefFound} = useActions();
    const [pdfUrl, setPdfUrl] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const printFinishHandler = () => {
        if (selected.length > 0) {
            const values = selected.map(sel => {
                return sel.id
            });
            renderPdfs(values);
            setSelectedRows([])
            setPrintOperationsAvailable(false)
            setSelectedRowKeys([])
            setPrintFormRefFound(false)
        }
    }

    const renderPdfs = (shipmentIds) => {
        const idString = shipmentIds.join(',');
        axios.get(`${getApiUrl('shipping_labels')}shipping_labels/pdf/?shipping_label_ids=` + encodeURIComponent(idString),  {
            responseType: "arraybuffer",
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            }
        ).then(res => {
            const blob = new Blob([res.data], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
            setModalVisible(true);
        }).catch(err => {
            message.error(err?.data?.message)
        })
    }

    const handleModalCancel = () => {
        setModalVisible(false);
        setPdfUrl('');
    };

    return (
        <>
            <Modal
                    visible={modalVisible}
                    onCancel={handleModalCancel}
                    footer={null}
                    width="80%"
                  >
                <embed src={pdfUrl} type="application/pdf" width="100%" height="500px" />
            </Modal>
            <PrintBlock finishHandler={printFinishHandler}/>
        </>
    );
};

export default PrintOperations;
