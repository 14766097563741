import React from 'react';
import {
    ContainerFilled,
    FileTextOutlined,
    CarOutlined,
    ShopOutlined, SwitcherOutlined,
    UnorderedListOutlined, AlertOutlined,
} from "@ant-design/icons";

const menu = [
    {
        name: 'sources',
        title: 'Källor',
        parent: true,
        icon: <ContainerFilled />,
        children: [
            {
                name: 'sites',
                link: 'sites',
                title: 'Webbshop'
            },
            {
                name: 'synchronization_jobs',
                link: 'synchronization_jobs',
                title: 'Synkronisationsjobb'
            },
            {
                name: 'woocommerce',
                link: 'woocommerce_settings',
                title: 'WooCommerce'
            },
            {
                name: 'all_products',
                link: 'products',
                title: 'Produkter'
            },
            {
                name: 'all_orders',
                title: 'Ordrar',
                link: 'orders',
                icon: <FileTextOutlined/>
            },
            {
                name: 'sync',
                title: 'Synk',
                link: 'sync',
                icon: <FileTextOutlined/>
            }
        ]
    },
    {
        name: 'notifications',
        link: 'notifications',
        title: 'Notiser',
        icon: <AlertOutlined />
    },
    {
        name: 'shipping',
        title: 'Frakt',
        parent: true,
        icon: <CarOutlined />,
        children: [
            {
                name: 'shipping_services',
                link: 'shipping_services',
                title: 'Frakttjänster'
            },
            {
                name: 'shipments',
                link: 'shipments',
                title: 'Försändelser'
            },
            {
                name: 'shipping_labels',
                link: 'shipping_labels',
                title: 'Fraktetiketter'
            }
        ]
    },
    {
        name: 'settings',
        title: 'Inställningar',
        parent: true,
        icon: <ShopOutlined />,
        children: [
            {
                name: 'printer_settings',
                title: 'Printer',
                link: 'printer_settings',
                icon: <UnorderedListOutlined />,
            },
            {
                name: 'default_customs_settings',
                title: 'Tull',
                link: 'default_customs_settings',
                icon: <UnorderedListOutlined />,
            },
            {
                name: 'shipping_service_mappings',
                link: 'shipping_service_mappings',
                title: 'Frakttjänst Mappning'
            },
            {
                name: 'postnord_account',
                link: 'postnord_account',
                title: 'Postnord konto'
            },
            {
                name: 'dhl_account',
                link: 'dhl_account',
                title: 'DHL konto'
            },
            {
                name: 'dbschenker_account',
                link: 'dbschenker_account',
                title: 'DB Schenker konto'
            },
            {
                name: 'budbee_account',
                link: 'budbee_account',
                title: 'Budbee konto'
            },
            {
                name: 'best_account',
                link: 'best_account',
                title: 'Best konto'
            },
            {
                name: 'ongoing_account',
                link: 'ongoing_account',
                title: 'Ongoing konto'
            },
            {
                name: 'senders',
                link: 'senders',
                title: 'Avsändare'
            },
            {
                name: 'user_carriers',
                link: 'user_carriers',
                title: 'Transportörer'
            }
        ]
    },
    /*{
        name: 'pick_lists',
        title: 'Plocklista',
        link: 'pick_lists',
        icon: <UnorderedListOutlined />,
    }*/
]

export default menu;
