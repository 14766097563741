import React from 'react';
import {Button, Row} from "antd";
import useActions from "../../../hooks/useActions";

const PickListButtons = ({picklists, setActiveButtons, activeButtons, ...props}) => {
    const {setIsPrintAllButtonPressed, setPickListOrderPopup} = useActions()

    const activeButtonClickHandler = (type) => {
        setActiveButtons(prev => {
            return {...prev, isViewListActive: false, isViewTotalActive: false, [type]: true};
        })
    }

    const onButtonPrintClickHandler = () => {
        setIsPrintAllButtonPressed(true)
        setPickListOrderPopup({
            visible: true,
            orderId: null
        })
    }

    return (
        <Row {...props}>
            <Button onClick={() => activeButtonClickHandler('isViewListActive')} {...{...(activeButtons.isViewListActive && {type: 'primary'})}} className='picklist-button'>Visa packlista</Button>
            <Button onClick={() => activeButtonClickHandler('isViewTotalActive')} {...{...(activeButtons.isViewTotalActive && {type: 'primary'})}} className='picklist-button'>Visa totaler</Button>
            <Button onClick={onButtonPrintClickHandler} className='picklist-button'>Skriv ut packsedlar</Button>
        </Row>
    );
};

export default PickListButtons;
