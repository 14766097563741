import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, Row} from "antd";
import {getCountryByCode, renderTitleFromType} from "../../../helpers/helpers";
import {EditOutlined} from "@ant-design/icons";
import OrderCustomerShippingForm from "./OrderCustomerDetailsForm/OrderCustomerDetailsForm";

const OrderCustomerDetails = ({order}) => {
    const [customerData, setCustomerData] = useState({})
    const [modalVisible, setModalVisible] = useState(false);
    const [isShippingDetails, setIsShippingDetails] = useState(true);
    const orderDetailsTranslations = {
        '_address_1': 'Adressrad 1',
        '_address_2': 'Adressrad 2',
        '_city': 'Ort',
        '_country': 'Land',
        '_email': 'E-post',
        '_first_name': 'Förnamn',
        '_last_name': 'Efternamn',
        '_phone': 'Telefon',
        '_state': 'Län',
        '_zipcode': 'Postnummer'
    }
    const reworkCustomerData = () => {
        if (order?.customer) {
            const shippingAndBillingData = {
                billing: {},
                shipping: {}
            };
            Object.keys(order?.customer).forEach(key => {
                if (key.includes('billing')) {
                    shippingAndBillingData['billing'] = {
                        ...shippingAndBillingData['billing'],
                        [key.replace('billing', '')]: order?.customer[key]
                    }
                }

                if (key.includes('shipping')) {
                    shippingAndBillingData['shipping'] = {
                        ...shippingAndBillingData['shipping'],
                        [key.replace('shipping', '')]: order?.customer[key]
                    }
                }
            })

            return shippingAndBillingData;
        }

        return [];
    }

    useEffect(() => {
        setCustomerData(reworkCustomerData())
    }, []);

    const renderList = (type) => {
        return (
            <ul>
                {
                    customerData[type] && Object.keys(customerData[type].length > 0) &&
                    Object.keys(customerData[type]).map(data => {
                        return (
                            customerData[type][data] &&
                            <li key={data}>
                                <span style={{display: 'inline-block', marginRight: '5px', color: '#8e8e8e'}}>{orderDetailsTranslations[data]}:</span>
                                <span style={{ color: '#8e8e8e'}}>{ data === '_country' ? getCountryByCode({code: customerData[type][data]}) : customerData[type][data]}</span>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    const editHandler = (isShipping = true) => {
        setIsShippingDetails(isShipping)
        setModalVisible(true)
    }

    return (
        <div style={{marginBottom: '20px'}}>
            <Row>
                <Col style={{marginRight: '20px'}}>
                    <h3
                        // style={{display: 'flex', alignItems: 'center', height: '32px'}}
                    >Faktureringsadress
                    <Button onClick={() => editHandler(false)} type='link'>
                        <EditOutlined />
                    </Button></h3>
                    {renderList('billing')}
                </Col>
                <Col>
                    <h3>Leveransadress
                        <Button onClick={() => editHandler(true)} type='link'>
                            <EditOutlined />
                        </Button></h3>
                    {renderList('shipping')}
                </Col>
            </Row>
            <Modal visible={modalVisible} onCancel={() => setModalVisible(false)} footer={false}>
                <OrderCustomerShippingForm orderDetailsTranslations={orderDetailsTranslations} type={isShippingDetails ? 'shipping' : 'billing'} id={order.id} setModalVisible={setModalVisible} details={customerData[isShippingDetails ? 'shipping' : 'billing']}/>
            </Modal>
        </div>
    );
};

export default OrderCustomerDetails;
