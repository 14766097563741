export const getColumns = (order) => {
    return [
        {
            title: 'Produkt',
            dataIndex: 'name',
            render: (_, item) => {
                return item.name
            },
            width: '60%'
        },
        {
            title: 'Pris',
            dataIndex: 'price',
            render: (_, item) => {
                return `${item.price ?? (item.total / item.quantity)} ${order.currency}`
            },
        },
        {
            title: 'Antal',
            dataIndex: 'quantity',
            editable: true,
            type: 'number'
        },
        {
            title: 'Totalt',
            dataIndex: 'total',
            render: (_, item) => {
                return `${item.total} ${order.currency}`
            },
            editable: true,
            type: 'number'
        },
        {
            title: 'Totalt moms',
            dataIndex: 'total_tax',
            editable: true,
            render: (_, item) => {
                return `${item.total_tax} ${order.currency}`
            },
            type: 'number'
        }
    ]
}
