import React, {useState, useEffect} from 'react';
import {Button, Form, Input, message, Row, Select, Spin} from 'antd';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useActions from "../../hooks/useActions";
import {getActionTypes} from "../../helpers/getActionTypes";
import axios from "axios";
import {getApiUrl} from "../../helpers/helpers";
import { useRef } from 'react';

import {
    SET_SITE, UPDATE_SITE
} from "../../state/reducers/sitesReducer";


const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const validateMessages = {
    required: '${label} krävs!',
    types: {
        email: '${label} är inte en giltig e-post!',
        number: '${label} är inte ett giltigt nummer!',
    },
    number: {
        range: '${label} måste ligga mellan ${min} och ${max}',
    },
};

const EditSiteForm = ({id}) => {
    const {updateItem} = useActions()
    const [site, SetSite] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const navigate = useNavigate();
    const valuesChangeHandler = () => {
        setIsChanged(true);
    };

    useEffect(() => {
        getSite();
    }, []);

    const getSite = () => {
        axios.get(`${getApiUrl('sites')}sites/`,  {
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            }
        ).then(res => {
            const site = res.data;
            SetSite(site);
        }).catch(err => {
            message.error(err?.data?.message)
        })
    }

    const finishHandler = (values) => {
        updateItem({
            values,
            id,
            ...getActionTypes('sites'),
            contentText: `Du har uppdaterat webbplatsen ${site?.name}`
        });
        setIsChanged(false);
    };

    const tokenInputRef = useRef(null);

    const copyToken = () => {
        if (tokenInputRef.current) {
            tokenInputRef.current.select();
            document.execCommand('copy');
            message.success('Kopierad')
        }
    };

    const rotateToken = () => {
        axios.post(`${getApiUrl('sites')}sites/refresh_token/` + site.id + `/`, {}, {
            headers: {'AccessToken': localStorage.getItem('accessToken')}
            }
        ).then(res => {
            const siteData = res.data;
            if (tokenInputRef.current) {
                tokenInputRef.current.value = siteData.token; // TODO idk why it's not working
            }
            message.success('Uppdaterad')
        }).catch(err => {
            message.error(err?.data?.message)
        })
    };

    const cancelHandler = () => {
        navigate('/sites');
    };
    console.log(site);

    const renderEditSite = () => (
        site &&
        <Form {...layout}
              name="edit-site"
              layout="vertical"
              onFinish={finishHandler}
              onValuesChange={valuesChangeHandler}
              validateMessages={validateMessages}>
            <h2>Webbshop</h2>
            <Form.Item
                name='id'
                label="ID"
                hidden={true}
                initialValue={site.id}
                rules={[{required: false,},]}
            >
                <Input bordered={false} readOnly={true} />
            </Form.Item>
            <Form.Item
                name='name'
                label="Namn"
                initialValue={site.name}
                rules={[{required: true,},]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name='url'
                label="URL"
                initialValue={site.url}
                rules={[{required: true,},]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name='token'
                label="API token"
                initialValue={site.token}
                rules={[{required: false,},]}
            >
                <Input ref={tokenInputRef} readOnly/>
            </Form.Item>
            <Button type="primary" onClick={copyToken} style={{ marginRight: '10px' }}>
                Kopiera
            </Button>
            <Button type="primary" onClick={rotateToken}>
                Rotera
            </Button>
            <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '10px' , marginTop: '10px' }}></div>
            <Form.Item wrapperCol={{...layout.wrapperCol, offset: layout.labelCol.span}}>
                <Row justify="space-between">
                    {
                        isChanged ?
                            <Button type="primary" htmlType="submit">
                                Spara
                            </Button> :
                            <Button type="primary" ghost onClick={cancelHandler}>
                                Gå tillbaka
                            </Button>
                    }
                    {
                        isChanged ?
                            <Button type="default" danger onClick={cancelHandler}>
                                Avbryt
                            </Button> :
                            null
                    }
                </Row>
            </Form.Item>
        </Form>
    )
    return (
        renderEditSite()
    );
};

export default EditSiteForm;
